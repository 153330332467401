import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { modifyEmail } from '../../../services/useCases';
import { showCallout } from '../../../store/callout.atoms';
import { loggedUserCredentials } from '../../auth/store/auth.store';

/* CONSTANTS
========================================================= */

const useModifyEmail = (data) => {
  
	/* DEPENDENCIES
========================================================= */

	const setCalloutMessage = useSetRecoilState(showCallout);
	const navigate = useNavigate();
  const setUserCredentials = useSetRecoilState(loggedUserCredentials);

	const handleModifyEmail = async () => {
		try {
			const result = await modifyEmail(data);

			// If the API gives an error, redirect to login and show a message.
			if (
				result.modifyEmail.__typename === 'GenericError' &&
				result.modifyEmail.code === "ERROR_USER_NOT_AUTHENTICATED"
			) {
				setCalloutMessage({
					show: true,
					type: 3,
					title: 'Your session has expired.',
					body: 'Please, login again'
				});
				navigate("/login", { replace: true });
			} else {
        setUserCredentials(null);
        setCalloutMessage({
          show: true,
          type: 1,
          title: "Email successfully changed.",
          body: "Login with your new email.",
        });
        navigate("/login", { replace: true });
				return result;
			}
			// If it doesn't work, show an error message.
		} catch (error) {
			setCalloutMessage({
				show: true,
				type: 3,
				title: 'Something went wrong.',
				body: 'An error ocurred when trying to get your portfolio information. Please, refresh this page and login again.'
			});
		}
	};

	return useMutation(handleModifyEmail);
};

export default useModifyEmail;