import Countdown, { zeroPad } from "react-countdown";

const InvoiceTimeout = ({ expireDate, invoiceStatus }) => {
  const countdownRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <p className="text-red">Invoice expired.</p>;
    } else {
      return (
        <p className="text-white">
          {minutes}:{zeroPad(seconds)}
        </p>
      );
    }
  };

  return (
    <div className="w-full h-auto bg-black flex justify-between items-center py-2 px-4">
      <p className="text-white">
        {invoiceStatus.isProcessed && !invoiceStatus.isError
          ? "Invoice successfully paid"
          : "Waiting for payment..."}
      </p>
      {!invoiceStatus.isProcessed && (
        <Countdown
          date={expireDate}
          renderer={countdownRenderer}
        />
      )}
    </div>
  );
};

export default InvoiceTimeout;
