import { handleCurrencyLetter } from "../../../helpers/common.helpers";
import { currencyConstants } from "../helpers/homeLayout.helpers";

const BalanceIndicator = ({ currency, revenue }) => {

  return (
    <h3 className="text-xl md:text-3xl font-bold text-darkEucalyptus">
      {handleCurrencyLetter(currency)}{" "}
      {revenue &&
        revenue.isSuccess &&
        revenue.data &&
        revenue.data.getTPayEvolution.items &&
        currency === currencyConstants.btc &&
        Math.trunc(
          revenue.data.getTPayEvolution.items[
            revenue.data.getTPayEvolution.items.length - 1
          ].accumulatedAmount * Math.pow(10, 8)
        ) / Math.pow(10, 8)}
      {revenue &&
      revenue.isSuccess &&
      revenue.data &&
      revenue.data.getTPayEvolution.items
        ? Math.trunc(
            revenue.data.getTPayEvolution.items[
              revenue.data.getTPayEvolution.items.length - 1
            ].accumulatedAmount * Math.pow(10, 2)
          ) / Math.pow(10, 2)
        : 0}
    </h3>
  );
};

export default BalanceIndicator;
