/* CONSTANTS
========================================================= */
const INVALID_LENGTH = 'INVALID_LENGTH';
const INVALID_ADDRESS = 'INVALID_ADDRESS';
const INVALID_FORMAT = 'Invalid number format';

/* PUBLIC METHODS
========================================================= */

// Offuscate middle digits in phone number provided.
const phoneNumberTrimmer = (phoneNumber) => {
  try {
    if (phoneNumber.length !== 9) throw INVALID_LENGTH;

    return phoneNumber.slice(0, 3).concat('***', phoneNumber.slice(6, 9));

  } catch (error) {
    return;
  }
};

// Offuscate middle digits in email provided.
const emailTrimmer = (email) => {
  try {
    if (!email.includes('@')) {
      return 'Type a valid email address'
    };

    const position = email.indexOf('@');

    return email.slice(0, (position / email.slice(0, position).length)).concat('***', email.slice(position, email.length));

  } catch (error) {
    return;
  }
};

// Format numbers according to navigator language
const formatAmount = (amount) => {
  try {
    return new Intl.NumberFormat(null, { style: 'decimal' }).format(amount);
  } catch (error) {
    return amount.toFixed(2);
  }
};

export { phoneNumberTrimmer, emailTrimmer, formatAmount };