import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { meQuery } from "../services/useCases";
import { showCallout } from "../store/callout.atoms";

/* CONSTANTS
========================================================= */

const useMe = (authData) => {
  /* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);
  const navigate = useNavigate();

  const handleUseMe = async () => {
    try {
      if (!authData) return;

      const result = await meQuery(authData?.token);

      // If the API gives an error, redirect to login and show a message.
      if (
        result.me.__typename &&
        result.me.__typename === "GenericError" &&
        result.me.code === "ERROR_USER_NOT_AUTHENTICATED"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Unable to get your user data",
          body: "Please, login again to access Turinpay.",
        });

        navigate("/login", { replace: true });

        // If not, refresh global state with fresh data.
      } else {
        return result;
      }
      // If it doesn't work, send to login and show an error message.
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to retrieve your data. Please, sign in again.",
      });
    }
  };

  return useQuery(["userData", authData?.token], () => handleUseMe(), {
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity
  });
};

export default useMe;
