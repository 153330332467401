import { useState } from "react";
import { useRecoilValue } from "recoil";
import { queryClient } from "../../config/react-query.config";
import { getEnvironment } from "../../helpers/common.helpers";
import useMe from "../../hooks/useMe";
import { isDeveloperMode } from "../../store/global.atoms";
import ChartPanel from "../HomeLayout/components/ChartPanel";
import {
  currencyConstants,
  handleGranularity,
  timeIntervalConstants,
} from "../HomeLayout/helpers/homeLayout.helpers";
import useGetPayouts from "../HomeLayout/hooks/useGetPayouts";
import PayoutsList from "./components/PayoutsList";
import useGetUserBalance from "./hooks/useGetUserBalance";
import useGetWalletBalance from "./hooks/useGetWalletBalance";

const Wallet = () => {
  // Destructure time interval constants
  const { year } = timeIntervalConstants;
  const { usd } = currencyConstants;

  const [period, setPeriod] = useState(year);
  const [offset, setOffset] = useState(0);
  const [currency, setCurrency] = useState(usd);

  const developerMode = useRecoilValue(isDeveloperMode);

  // Dependencies
  const userData = queryClient.getQueryData("loggedUserCredentials");

  // Get wallet balance to populate chart
  const walletBalance = useGetWalletBalance({
    token: userData.signinWithSecretByAuthCode.token,
    variables: {
      input: {
        period: period,
        executionEnvironment: getEnvironment(developerMode),
        minutesGranularity: handleGranularity(period),
        fromFirstSale: false,
        onlyPayouts: true,
        currency: currency,
      },
    },
  });

  // Get aggregated user balance to control payouts
  const userBalance = useGetUserBalance({
    token: userData.signinWithSecretByAuthCode.token,
    variables: {
      input: {
        currency: currency,
        executionEnvironment: getEnvironment(developerMode),
      },
    },
  });

  const me = useMe(userData.signinWithSecretByAuthCode);

  // Get payouts performed data
  const payoutsHistory = useGetPayouts({
    token: userData.signinWithSecretByAuthCode.token,
    offset,
    period,
    currency,
    environment: getEnvironment(developerMode),
  });

  const handleChangePeriod = (selectedPeriod) => {
    // Reset offset counter
    setOffset(0);

    setPeriod(selectedPeriod);

    payoutsHistory.refetch();
    walletBalance.refetch();
  };

  const handleChangeCurrency = (selectedCurrency) => {
    // Reset offset counter
    setOffset(0);

    setCurrency(selectedCurrency);

    payoutsHistory.refetch();
    walletBalance.refetch();
  };

  // Load more transactions
  const handleLoadMore = () => {
    payoutsHistory.fetchNextPage();
  };

  return (
    <section className="mx-2 mt-3 lg:col-span-9 xl:col-span-10 lg:mx-10 bg-gray-50">
      <h1 className="sr-only">Dashboard Panel</h1>
      <ChartPanel
        revenue={walletBalance}
        period={period}
        changePeriod={handleChangePeriod}
        currency={currency}
        changeCurrency={handleChangeCurrency}
        aggregatedBalance={userBalance}
      />
      <PayoutsList
        payoutsHistory={payoutsHistory}
        userData={me}
        currency={currency}
        walletBalance={walletBalance}
        userBalance={userBalance}
        handleLoadMore={handleLoadMore}
      />
    </section>
  );
};

export default Wallet;
