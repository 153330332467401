import { useRecaptcha } from "react-recaptcha-hook";

const sitekey = process.env.REACT_APP_RECAPTCHA_WEBSIDE_KEY;

const useTurinLabsReCaptcha = () => {
  const execute = useRecaptcha({ sitekey, hideDefaultBadge: true });

  return {
    getToken: async () => {
      try {
        const token = await execute("getGoogleReCaptcha");
        return token;
      } catch (error) {
        console.log("ERROR getGoogleReCaptcha", error);
        return null;
      }
    },
  };
};

export default useTurinLabsReCaptcha;
