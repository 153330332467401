import PublicPaymentsProfileForm from "./components/PublicPaymentsProfileForm";

const TurinpayMeSettings = () => {
  return (
    <section className="mx-2 lg:col-span-9 xl:col-span-10 lg:mx-10 bg-gray-50">
      <div className="max-w-screen-md">
        <PublicPaymentsProfileForm />
      </div>
    </section>
  );
};

export default TurinpayMeSettings;
