import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DateTime } from "luxon";

export default function IntentDetailModal({
  selectedIntent,
  showIntentDetailModal,
  setShowIntentDetailModal,
}) {
  const parseDate = (date) => {
    return DateTime.fromISO(date).toLocaleString({
      weekday: "short",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <Transition.Root
      show={showIntentDetailModal === true ? true : false}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => setShowIntentDetailModal(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-3xl leading-6 font-bold text-darkEucalyptus"
                >
                  {selectedIntent &&
                    Math.trunc(selectedIntent.price * Math.pow(10, 2)) /
                      Math.pow(10, 2)}
                  {" "}{selectedIntent && selectedIntent.currency}
                </Dialog.Title>
                <div className="my-6">
                  <p className="text-xl font-bold">
                    {selectedIntent && selectedIntent.description}
                  </p>
                </div>
                <div className="w-full flex flex-col justify-start items-center">
                  <div className="w-full flex-col justify-start items-start my-2">
                    <p className="w-full text-left font-medium text-lg">
                      Created at:
                    </p>
                    <p className="w-full text-left">
                      <time
                        dateTime={selectedIntent && selectedIntent.createdAt}
                      >
                        {parseDate(selectedIntent && selectedIntent.createdAt)}
                      </time>
                    </p>
                  </div>
                  <div className="w-full flex-col justify-start items-start my-2">
                    <p className="w-full text-left font-medium text-lg">
                      Intent ID:
                    </p>
                    <p className="w-full text-left">
                      {selectedIntent &&
                        `${selectedIntent.id.slice(
                          0,
                          10
                        )}...${selectedIntent.id.slice(
                          selectedIntent.id.length - 10,
                          selectedIntent.id.length
                        )}`}
                    </p>
                  </div>
                  <div className="w-full flex-col justify-start items-start my-2">
                    <p className="w-full text-left font-medium text-lg">
                      Status:
                    </p>
                    <div className="w-full">
                      {
                        selectedIntent && selectedIntent.active 
                          ? <p className="text-green-600 font-bold text-left">ACTIVE</p> 
                          : <p className="text-red-600 font-bold text-left">INACTIVE</p>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-eucalyptus text-base font-bold text-white hover:bg-darkEucalyptus focus:outline-none focus:ring-2-lightEucalyptus focus:ring-offset-2 sm:text-sm"
                  onClick={() => setShowIntentDetailModal(false)}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
