// Calculate exchange rate to show in checkout page
// Purposely coupled to current implementation. Receives pairRates array straight from API.
export const getExchangeRateUSD = (pairRates) => {
  if (!pairRates) return;

  const satsPerUSD = pairRates[1].rate * pairRates[5].rate;

  return satsPerUSD.toFixed(0);
};

export const getExchangeRateEUR = (pairRates) => {
  if (!pairRates) return;

  const satsPerEUR = pairRates[0].rate * pairRates[5].rate;

  // Return sats in EUR
  return satsPerEUR.toFixed(0);
};

export const satsToUSD = (satsAmount, satsPerUSD) => {
  return satsAmount / satsPerUSD
};

export const satsToEUR = (satsAmount, satsPerEUR) => {
  return satsAmount / satsPerEUR
};