import { useState } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import { getEnvironment } from "../../helpers/common.helpers";
import useMe from "../../hooks/useMe";
import { isDeveloperMode } from "../../store/global.atoms";
import IntentsList from "./components/IntentsList";
import useGetIntents from "./hooks/useGetIntents";

const Intents = () => {
  const [offset, setOffset] = useState(0);

  const developerMode = useRecoilValue(isDeveloperMode);
  const queryClient = useQueryClient();

  // Dependencies
  const userData = queryClient.getQueryData("loggedUserCredentials");

  const { me } = queryClient.getQueryData(["userData", userData.signinWithSecretByAuthCode.token]);

  const intentsHistory = useGetIntents(
    // JWT
    userData.signinWithSecretByAuthCode.token,
    // API key, according to selected environment
    developerMode
      ? me.apiKeys.sktest
      : me.apiKeys.sk,
    // Pagination
    offset,
    // Currency
    "EUR"
  );

  // Load more transactions
  const handleLoadMore = () => {
    intentsHistory.fetchNextPage();
  };

  return (
    <section className="mx-2 mt-3 lg:col-span-9 xl:col-span-10 lg:mx-10 bg-gray-50">
      <h1 className="sr-only">Intents list</h1>
      <IntentsList
        intentsHistory={intentsHistory}
        userData={me}
        handleLoadMore={handleLoadMore}
      />
    </section>
  );
};

export default Intents;
