import { BrowserRouter, Route, Routes } from "react-router-dom";

import Fathom from "./Fathom";
import HomeLayout from "./routes/HomeLayout/HomeLayout";

const AuthenticatedApp = () => {
  return (
    <BrowserRouter>
      <Fathom />
      <Routes>
        <Route path="/home" element={<HomeLayout />} />
        <Route path="*" element={<HomeLayout />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AuthenticatedApp;
