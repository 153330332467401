import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";
import { useRecoilValue } from "recoil";

import { loggedUserCredentials } from "./routes/auth/store/auth.store";
import { TurinLabsReCaptcha } from "./components/recaptcha";
import AuthenticatedApp from "./AuthenticatedApp";
import FatalCrash from "./components/FatalCrash";
import UnauthenticatedApp from "./UnauthenticatedApp";

const App = () => {
  const isLoggedIn = useRecoilValue(loggedUserCredentials);

  if (!isLoggedIn) {
    return (
      <ErrorBoundary FallbackComponent={FatalCrash}>
        <UnauthenticatedApp />
        <Toaster position="top-right" reverseOrder={false} />
        <TurinLabsReCaptcha />
      </ErrorBoundary>
    );
  } else {
    return (
      <ErrorBoundary FallbackComponent={FatalCrash}>
        <AuthenticatedApp />
        <Toaster position="top-right" reverseOrder={false} />
        <TurinLabsReCaptcha />
      </ErrorBoundary>
    );
  }
};

export default App;
