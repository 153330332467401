import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getPayoutsHistory } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";

/* CONSTANTS
========================================================= */

const useGetPayouts = ({ token, period, currency, environment }) => {
  /* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);
  const navigate = useNavigate();

  const handleGetPayouts = async ({ pageParam = 0 }) => {
    try {
      // Build query data object
      const queryData = {
        token: token,
        variables: {
          input: {
            offset: pageParam,
            limit: 10,
            orderBy: "date",
            orderDirection: "DESC",
            executionEnvironment: environment,
            onlyPayouts: true,
            period: period,
            currency: currency,
          },
        },
      };

      const result = await getPayoutsHistory(queryData);

      // If the API gives an error, redirect to login and show a message.
      if (
        result.getTPayHistory.__typename &&
        result.getTPayHistory.__typename === "GenericError" &&
        result.getTPayHistory.code === "ERROR_USER_NOT_AUTHENTICATED"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Your session has expired.",
          body: "Please, login again.",
        });

        navigate("/login", { replace: true });

        // If not, refresh global state with fresh data.
      } else {
        return result;
      }
      // If it doesn't work, send to login and show an error message.
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to retrieve your data. Please, try again. If the error persist, contact us.",
      });
    }
  };

  return useInfiniteQuery(
    ["payouts", token, period, currency, environment],
    handleGetPayouts,
    {
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) {
          return undefined;
          // Check if numbers of elements left is lower than the resulting offset from loading another page of txs
        } else if (
          lastPage.getTPayHistory?.metadata?.numElements >
            lastPage.getTPayHistory?.metadata?.offset &&
          lastPage.getTPayHistory?.metadata?.numElements <
            lastPage.getTPayHistory?.metadata?.offset + 10
        ) {
          return (
            lastPage.getTPayHistory?.metadata?.numElements -
            lastPage.getTPayHistory?.metadata?.offset
          );
        } else {
          return pages.length * 10;
        }
      },
    }
  );
};

export default useGetPayouts;
