import CurrencyInput from "react-currency-input-field";

import { getNumberSeparators } from "../../../utils/numbers";

const MAX_DESCRIPTION_LENGTH = 100;

export const PaymentStep1 = ({ amount, description, handleChange }) => {
  const { decimalSeparator } = getNumberSeparators();

  const handleAmountChange = (amount) => {
    if (!amount) {
      handleChange({ amount: null, description });
    } else {
      let clearAmount = amount.replace(/,/g, ".");
      if (clearAmount.slice(-1) === ".") {
        clearAmount += "0";
      }
      handleChange({ amount: Number(clearAmount), description });
    }
  };

  const handleDescriptionChange = (description) => {
    handleChange({ amount, description });
  };
  return (
    <form className="mt-10 w-full max-w-sm flex flex-col gap-2">
      <div>
        <label
          htmlFor="amount"
          className="font-bold text-sm text-gray-900 mb-2"
        >
          Amount to pay (in EUR)
        </label>
        <CurrencyInput
          id="amount"
          name="amount"
          className="block w-full px-3 py-2 text-xl placeholder-gray-400 pl-[14px] border border-gray-300 rounded-lg shadow-sm appearance-none focus:outline-none focus:ring-lightEucalyptus focus:border-darkEucalyptus"
          placeholder="Enter amount"
          suffix="€"
          defaultValue={amount}
          decimalsLimit={2}
          maxLength={10}
          decimalSeparator={decimalSeparator}
          allowNegativeValue={false}
          onValueChange={(value) => handleAmountChange(value)}
        />
      </div>

      <div>
        <label
          htmlFor="description"
          className="font-bold text-sm text-gray-900 mb-2"
        >
          Description
        </label>
        <textarea
          id="description"
          defaultValue={description}
          onChange={(response) =>
            handleDescriptionChange(response.target.value)
          }
          placeholder="Enter description..."
          inputMode="text"
          className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus placeholder-gray-400 pl-[14px]"
          rows={2}
          maxLength={MAX_DESCRIPTION_LENGTH}
          required={true}
        />
        <p className="text-xs opacity-50 mt-1">
          {(description ?? "").length}/{MAX_DESCRIPTION_LENGTH}
        </p>
      </div>
    </form>
  );
};
