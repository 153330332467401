import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";
import { getRevenue } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";

/* CONSTANTS
========================================================= */

const useGetWalletBalance = (data) => {
  /* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);

  const handleGetWalletBalance = async () => {
    try {
      if (!data) return;

      const result = await getRevenue(data);

      return result;
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to retrieve your data. Please, try again. If the error persist, contact us.",
      });
    }
  };

  return useQuery(
    [
      "getWalletBalance",
      data.token,
      data.variables.input.currency,
      data.variables.input.period,
      data.variables.input.executionEnvironment
    ],
    () => handleGetWalletBalance(),
    {
      enabled: true,
      refetchOnWindowFocus: true,
      retry: true,
    }
  );
};

export default useGetWalletBalance;
