import { useMutation, useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";

import { GENERIC_ERROR } from "../../../services/gqlQueries";
import { showCallout } from "../../../store/callout.atoms";
import { updateKyc, requestKyc } from "../../../services/useCases";

/* CONSTANTS
========================================================= */

const useUpdateMyKyc = (data) => {
  /* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);

  const queryClient = useQueryClient();

  const handleUpdateMyKYC = async () => {
    try {
      const updateResponse = await updateKyc(data);
      const requestResponse = await requestKyc({
        token: data.token,
        variables: {
          input: {
            _id: updateResponse.updateKYC._id,
          },
        },
      });

      return requestResponse;
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to update your KYC. Please, try again. If the error persist, contact us.",
      });
    }
  };

  return useMutation(handleUpdateMyKYC, {
    onSuccess: (data) => {
      if (data.requestKYC.__typename === GENERIC_ERROR) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: data.requestKYC.message,
          body: data.requestKYC.description,
        });
      } else {
        setCalloutMessage({
          show: true,
          type: 1,
          title: "Update done KYC",
          body: "Your KYC has been updated successfully.",
        });
        queryClient.invalidateQueries("myKyc");
      }
    },
    onError: () => {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong",
        body: "There was an error when trying to update your KYC. Please, try again.",
      });
    },
  });
};

export default useUpdateMyKyc;
