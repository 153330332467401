import { useQueryClient } from "react-query";
import { useEffect, useState } from "react";

import { KYC_STATUS } from "./KYCForm/config";
import { KYCAccepted, KYCRejected, KYCRequested } from "./KYCStatus";
import { KYCForm } from "./KYCForm";
import useGetMyKYC from "./hooks/useGetMyKyc";
import useUpdateMyKyc from "./hooks/useUpdateMyKyc";

const KYCSettings = () => {
  const queryClient = useQueryClient();
  const userCredentials = queryClient.getQueryData("refreshToken");
  const token = userCredentials?.refreshToken.token;

  // Get public payments info
  const kyc = useGetMyKYC(token);

  const [kycUpdated, setKycUpdated] = useState(kyc);
  const [kycStatus, setKycStatus] = useState(null);

  useEffect(() => {
    if (!kycStatus && kyc) {
      setKycStatus(kyc?.data?.status);
    }
  }, [kyc, kycStatus]);

  const updateMyKyc = useUpdateMyKyc({
    token,
    variables: {
      input: kycUpdated,
    },
  });

  const handleRefresh = () => {
    setKycStatus(null);
    queryClient.invalidateQueries("myKyc");
  };

  const handleSubmitKYC = (kyc) => {
    setKycUpdated(kyc);
    setKycStatus(KYC_STATUS.REQUESTED);
    updateMyKyc.mutate();
  };

  const viewByStatus = {
    [KYC_STATUS.DRAFT]: (
      <div className="max-w-screen-md">
        <KYCForm kyc={kyc?.data} onSubmit={handleSubmitKYC} />
      </div>
    ),
    [KYC_STATUS.REQUESTED]: <KYCRequested onRefresh={handleRefresh} />,
    [KYC_STATUS.ACCEPTED]: <KYCAccepted />,
    [KYC_STATUS.REJECTED]: (
      <KYCRejected
        reason={kyc?.data?.lastRejectionReason}
        onReview={() => setKycStatus(KYC_STATUS.DRAFT)}
      />
    ),
    loading: <p>Loading...</p>,
  };

  return (
    <section className="mx-2 lg:col-span-9 xl:col-span-10 lg:mx-10">
      {viewByStatus[kycStatus] ?? viewByStatus.loading}
    </section>
  );
};

export default KYCSettings;
