import turinpayImagotype from "../../../assets/turinpay_imagotype_final.svg";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

const CheckoutError = ({ data }) => {
  const { description, message } = data;

  return (
    <main className="flex items-center justify-center w-screen h-screen py-8">
      <section>
        {/* Card */}

        <div className="h-auto pb-10 bg-white shadow-md w-96 rounded-xl">
          {/* Header */}

          <div className="flex flex-col items-center justify-center w-full">
            <img
              src={turinpayImagotype}
              alt="Turinpay logotype"
              className="my-4"
            />
          </div>

          {/* Body */}

          <div className="flex flex-col items-center justify-center w-full h-auto">
            <div className="flex items-center justify-center w-64 h-auto mt-6">
              <ExclamationCircleIcon className="w-32 h-auto text-red-700" />
            </div>

            <div className="flex flex-col items-center justify-center w-full px-10">
              <h3 className="text-xl font-bold">Something went wrong</h3>
              <div className="flex flex-col items-start justify-start w-full h-auto mt-6">
                <p className="text-xs font-bold">WHAT HAPPENED?</p>
                <p className="mb-6">{message}</p>
              </div>
              <div className="flex flex-col items-start justify-start w-full h-auto">
                <p className="text-xs font-bold">DETAILS</p>
                <p className="mb-6">{description}</p>
              </div>
              <div className="flex flex-col items-start justify-start w-full h-auto">
                <p className="text-xs font-bold">WHAT CAN I DO NOW?</p>
                <p className="mb-6">
                  You can close this window and try again to perform the
                  payment. If the error persist, contact the e-commerce support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CheckoutError;
