import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { default as ReactTooltip } from "react-tooltip";
import { TooltipEffect, TooltipPlace, TooltipType } from "./types";

const Tooltip = ({
  children,
  place = TooltipPlace.DEFAULT,
  type = TooltipType.DEFAULT,
  effect = TooltipEffect.DEFAULT,
}) => (
  <>
    <a data-tip={children}>
      <ExclamationCircleIcon className="w-5 h-5" />
    </a>
    <ReactTooltip place={place} type={type} effect={effect} />
  </>
);

export default Tooltip;
