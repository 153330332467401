const PayoutLoading = () => {
  return (
    <section className="lg:col-span-9 xl:col-span-10 my-10 flex flex-col justify-start items-center">
      <div className="flex flex-col justify-start items-center w-full max-w-3xl">
        <div className="w-full flex flex-col justify-start items-center mb-10">
          <h3 className="w-full text-center font-bold text-darkEucalyptus text-3xl">
            Loading...

          </h3>
          <p className="text-gray-600">
            Wait just a few seconds...
          </p>
        </div>
        <div className="w-96 border-2 p-6 rounded-lg bg-white flex flex-col justify-start items-center">
          <div className="w-32 h-32 rounded-full bg-slate-300 my-8" />
          <div className="w-48 h-8 rounded-md bg-slate-300 my-8" />
          <div className="w-48 h-4 rounded-sm bg-slate-300 my-8" />
        </div>
      </div>
    </section>
  );
};

export default PayoutLoading;
