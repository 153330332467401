import { useState } from "react";
import { useRecoilValue } from "recoil";
import { queryClient } from "../../../config/react-query.config";
import { getEnvironment } from "../../../helpers/common.helpers";
import useMe from "../../../hooks/useMe";
import { isDeveloperMode } from "../../../store/global.atoms";
import {
  currencyConstants,
  handleGranularity,
  timeIntervalConstants,
} from "../helpers/homeLayout.helpers";
import useGetRevenue from "../hooks/useGetRevenue";
import useGetTransactionsHistory from "../hooks/useGetTransactions";
import ChartPanel from "./ChartPanel";
import TxList from "./TxList";

const DashboardPanel = () => {
  // User environment
  const developerMode = useRecoilValue(isDeveloperMode);

  // Destructure time interval constants
  const { year } = timeIntervalConstants;

  // Destructure currency constants. TODO: Get default one from user settings
  const { usd } = currencyConstants;

  const [period, setPeriod] = useState(year);
  const [currency, setCurrency] = useState(usd);
  const [offset, setOffset] = useState(0);

  // Dependencies
  const userData = queryClient.getQueryData("loggedUserCredentials");

  // Get revenue to populate chart
  const revenue = useGetRevenue({
    token: userData.signinWithSecretByAuthCode.token,
    variables: {
      input: {
        period: period,
        executionEnvironment: getEnvironment(developerMode),
        minutesGranularity: handleGranularity(period),
        fromFirstSale: false,
        onlyDeposits: true,
        currency: currency,
      },
    },
  });

  const me = useMe(userData.signinWithSecretByAuthCode);

  // Environment selected
  const selectedEnvironment = getEnvironment(developerMode);

  const transactionsHistory = useGetTransactionsHistory(
    userData.signinWithSecretByAuthCode.token,
    offset,
    period,
    currency,
    null,
    selectedEnvironment
  );

  const handleChangePeriod = (timeInterval) => {
    // Reset offset counter
    setOffset(0);

    setPeriod(timeInterval);
  };

  const handleChangeCurrency = (selectedCurrency) => {
    // Reset offset counter
    setOffset(0);

    setCurrency(selectedCurrency);
  };

  // Load more transactions
  const handleLoadMore = () => {
    transactionsHistory.fetchNextPage();
  };

  return (
    <section className="mx-2 mt-3 lg:col-span-9 xl:col-span-10 lg:mx-10 bg-gray-50">
      <h1 className="sr-only">Dashboard Panel</h1>
      <ChartPanel
        revenue={revenue}
        period={period}
        changePeriod={handleChangePeriod}
        currency={currency}
        changeCurrency={handleChangeCurrency}
      />
      <TxList
        txHistory={transactionsHistory}
        userData={me}
        currency={currency}
        handleLoadMore={handleLoadMore}
      />
    </section>
  );
};

export default DashboardPanel;
