import { Badge } from "react-recaptcha-hook";

const TurinLabsReCaptcha = () => {
  return (
    <div className="fixed bottom-0 right-0 hidden">
      <Badge />
    </div>
  );
};

export default TurinLabsReCaptcha;
