import { AuthForm } from '../auth.types';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import { showCallout } from '../../../store/callout.atoms';
import { useRecoilValue } from 'recoil';
import NavBar from '../../../components/ui/Navbar';
import Callout from '../../../components/ui/Callout';
import ConfirmSignupForm from './ConfirmSignupForm';
import ConfirmLoginForm from './ConfirmLoginForm';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

const AuthProvider = ({ location }) => {

/* STATE
========================================================= */   

const shouldShowCallout = useRecoilValue(showCallout);

/* FUNCTIONS
========================================================= */  

  // Render the proper form according to location
  const renderForm = () => {
    switch (location) {
      case AuthForm.LOGIN:           return <LoginForm />
      case AuthForm.SIGNUP:          return <SignupForm />
      case AuthForm.CONFIRM_LOGIN:   return <ConfirmLoginForm />
      case AuthForm.CONFIRM_SIGNUP:  return <ConfirmSignupForm />
      case AuthForm.FORGOT_PASSWORD: return <ForgotPassword />
      case AuthForm.RESET_PASSWORD:  return <ResetPassword />
      default:                       return <LoginForm />
    };
  };

/* RENDERING...
========================================================= */   

  return ( 
    <div className="w-screen h-screen bg-gray-50">
      <header className='fixed flex flex-row justify-between w-screen p-6'>
        <NavBar location={location} />
      </header>
      <main className='flex items-center justify-center min-h-screen flex-column'>
        {shouldShowCallout.show && <Callout />}
        {renderForm()}
      </main>
    </div>
   );
}
 
export default AuthProvider;