import { DevTool } from "@hookform/devtools";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";

import { EVENT_CODE } from "../../../config/analytics.config";
import { loggedUserData } from "../store/auth.store";
import { mutateData } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";
import {
  SIGNUP,
  REGISTER,
  GENERIC_ERROR,
  DOMAIN,
} from "../../../services/gqlQueries";
import { trackEvent } from "../../../utils/analytics";
import { useTurinLabsReCaptcha } from "../../../components/recaptcha";
import LoadingSpinner from "../../../assets/LoadingSpinner";

/* COMPONENT
========================================================= */

const SignupForm = () => {
  /* STATE 
  ========================================================= */
  const { getToken } = useTurinLabsReCaptcha();

  const setNewUserCredentials = useSetRecoilState(loggedUserData);
  const setCalloutError = useSetRecoilState(showCallout);
  const navigate = useNavigate();

  /* PRIVATE METHODS
========================================================= */

  const handleSignup = useMutation(mutateData, {
    onSuccess: (data) => {
      if (data.sendAuthCodeWithSecret.__typename === GENERIC_ERROR) {
        setCalloutError({
          show: true,
          type: 3,
          title: data.sendAuthCodeWithSecret.message,
          body: data.sendAuthCodeWithSecret.description,
        });
      } else {
        navigate("/confirm-signup");
      }
    },
    onError: (error) => {
      setCalloutError({
        show: true,
        type: 3,
        title: "Something went wrong",
        body: "There was an error when trying to signing you up. Please, try again.",
      });
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const onSubmit = async (data) => {
    trackEvent(EVENT_CODE.CREATE_ACCOUNT);

    const variables = {
      input: {
        email: data.email,
        secret: data.password,
        type: REGISTER,
        domainApp: DOMAIN,
      },
    };

    const googleReCaptchaToken = await getToken();

    handleSignup.mutate({
      query: SIGNUP,
      variables: variables,
      googleReCaptchaToken,
    });
    setNewUserCredentials(data);
  };

  /* RENDERING...
========================================================= */

  return (
    <>
      <div className="w-full max-w-md mx-auto">
        <div className="py-8 bg-white shadow rounded-xl">
          <div className="max-w-md mb-6">
            <h3 className="px-10 pb-6 font-bold text-left text-gray-900 text-3x1">
              Create a Turinpay account
            </h3>
            <hr />
          </div>
          <form className="px-10 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  inputMode="email"
                  autoComplete="new-email"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm appearance-none focus:outline-none focus:ring-eucalyptus focus:border-eucalyptus"
                  placeholder="satoshi@nakamoto.com"
                  data-testid="signup-email"
                  {...register("email", { required: true })}
                />
              </div>
              {errors.email?.type === "required" && (
                <p className="mt-2 text-sm font-bold text-red-700">
                  Please, type an email
                </p>
              )}
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  inputMode="password"
                  autoComplete="new-password"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm appearance-none focus:outline-none focus:ring-lightEucalyptus focus:border-green-700"
                  placeholder="******"
                  data-testid="signup-password"
                  {...register("password", {
                    required: true,
                    // pattern: PASSWORD_REGEX
                  })}
                />
              </div>
              {errors.email?.type === "required" && (
                <p className="mt-2 text-sm font-bold text-red-700">
                  Please, type a new password.
                </p>
              )}
              {errors.password?.type === "pattern" && (
                <p className="my-2 text-sm font-bold text-red-700">
                  Password doesn't meet conditions.
                </p>
              )}
              <p className="text-sm font-medium text-gray-700">
                8 characters minimum, 1 lowercase and 1 uppercase
              </p>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="tos"
                  type="checkbox"
                  className="w-4 h-4 text-indigo-600 border-gray-300 rounded-lg focus:ring-indigo-500"
                  data-testid="signup-tos-checkbox"
                  {...register("tos", { required: true })}
                />
                <p className="ml-2 text-sm font-medium">
                  I've read and accept the{" "}
                  <a
                    href={process.env.REACT_APP_TERMS_OF_SERVICE}
                    className="text-eucalyptus hover:text-darkEucalyptus"
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    href={process.env.REACT_APP_PRIVACY_POLICY}
                    className="text-eucalyptus hover:text-darkEucalyptus"
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </div>

            {errors.tos?.type === "required" && (
              <p className="text-sm font-bold text-red-700">
                You have to accept the ToS and PP.
              </p>
            )}

            <div>
              <button
                type="submit"
                className="flex justify-center px-4 py-2 mx-auto font-medium text-white shadow-sm rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus disabled:opacity-75"
                data-testid="create-account-button"
                disabled={handleSignup.isLoading}
              >
                {handleSignup.isLoading && <LoadingSpinner />}
                Create account
              </button>
            </div>
          </form>
        </div>

        <div className="flex flex-row justify-center mt-6">
          <p className="text-sm font-medium">
            Already registered?{" "}
            <Link
              to="/login"
              className="text-eucalyptus hover:text-darkEucalyptus"
            >
              Sign in here
            </Link>
            .
          </p>
        </div>
      </div>
      <DevTool control={control} />
    </>
  );
};

export default SignupForm;
