import clsx from "clsx";

/* eslint-disable no-useless-concat */
export const PrimaryButton = ({
  copy,
  onClick,
  className = null,
  ref = null,
  disabled = false,
}) => {
  if (disabled) {
    return (
      <button
        type="submit"
        className={clsx(
          "flex justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm font-medium text-white bg-lightEucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-eucalyptus disabled:opacity-50",
          className
        )}
        onClick={() => onClick()}
        ref={ref}
        disabled={disabled}
      >
        {copy}
      </button>
    );
  } else {
    return (
      <button
        type="submit"
        className={clsx(
          "flex justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm font-medium text-white bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-eucalyptus disabled:opacity-50",
          className
        )}
        onClick={() => onClick()}
        ref={ref}
      >
        {copy}
      </button>
    );
  }
};
