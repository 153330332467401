import { useQuery } from "react-query";
import { getPairRates } from "../../../services/useCases";
import {
  getExchangeRateEUR,
  getExchangeRateUSD,
} from "../helpers/checkout.helpers";

const AmountsPresenter = ({ satsAmount, currency }) => {
  // Get pair rates. Fetch them every minute.
  const pairRates = useQuery("pairRates", getPairRates, {
    refetchInterval: 60000,
  });

  const satsToAmount = () => {
    if (currency === "USD") {
      const satsPerUsd = getExchangeRateUSD(
        pairRates.data?.getTPayPairRates?.items
      );

      return `${(satsAmount / satsPerUsd).toLocaleString()} USD`;
    } else {
      const satsPerEur = getExchangeRateEUR(
        pairRates.data?.getTPayPairRates?.items
      );

      return `${(satsAmount / satsPerEur).toLocaleString()} EUR`;
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-start my-6 px-4">
      <p className="text-gray-800 font-bold text-xl">
        {satsAmount.toLocaleString()} sats / {satsToAmount()}
      </p>
      <p className="text-gray-800 italic text-sm">
        {currency === "USD"
          ? `1 USD = ${getExchangeRateUSD(
              pairRates.data?.getTPayPairRates?.items
            )} sats`
          : `1 EUR = ${getExchangeRateEUR(
              pairRates.data?.getTPayPairRates?.items
            )} sats`}
      </p>
    </div>
  );
};

export default AmountsPresenter;
