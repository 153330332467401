import { getBackendEndpoint } from "../helpers/environment.helper";
import { request, gql } from "graphql-request";
import { snakeCase, deburr } from "lodash";

const turinpayEndpoint = getBackendEndpoint();
const s3BucketName =
  process.env.REACT_APP_PUBLIC_BUCKET || "turinlabs-library-integration";

const GETSIGNEDURL_QUERY = gql`
  query GETSIGNED_URL_QUERY($input: S3SignedInput!) {
    getSignedUrl(input: $input) {
      __typename
      ... on Url {
        url
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

const GETPRESIGNEDPOST_QUERY = gql`
  query getPresignedPost($input: S3PolicyInput!) {
    getPresignedPost(input: $input) {
      __typename
      ... on S3Policy {
        fields {
          ContentType
          Policy
          XAmzAlgorithm
          XAmzCredential
          XAmzDate
          XAmzSignature
          bucket
          key
        }
        url
      }
      ... on GenericError {
        code
        message
        description
      }
    }
  }
`;

const requestSignedUrl = async ({ endpoint, variables }) => {
  const { getSignedUrl } = await request(
    endpoint,
    GETSIGNEDURL_QUERY,
    variables
  );
  return getSignedUrl;
};

const requestPresignedPost = async ({ endpoint, variables }) => {
  const { getPresignedPost } = await request(
    endpoint,
    GETPRESIGNEDPOST_QUERY,
    variables
  );
  return getPresignedPost;
};

const postFileToS3 = (presignedPostData, file) => {
  console.log("Presigned: ", presignedPostData);

  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });
    // Actual file has to be appended last.
    formData.append("file", file);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", presignedPostData.url, true);
    xhr.onerror = reject;
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 204) {
        return resolve({
          keyS3: presignedPostData.fields.key,
          url: `${presignedPostData.url}/${presignedPostData.fields.key}`,
        });
      }
    };
    xhr.send(formData);
  });
};

const loadPresigned = async (endpoint, file, folder, config, userId) => {
  const fileExt = file.name.split(".").pop();
  const fileNameWithoutExt = file.name.split(".").slice(0, -1).join(".");
  const fileName = `${Date.now()}_${snakeCase(
    deburr(fileNameWithoutExt)
  )}.${fileExt}`;

  const input = {
    key: fileName,
    contentType: file.type,
    bucket: config.bucket,
    route: folder || `turinpay/users/${userId}`,
    minBytes: config.minBytes,
    maxBytes: config.maxBytes,
  };

  const getPresignedPost = await requestPresignedPost({
    endpoint,
    variables: { input },
  });

  const fields = Object.keys(getPresignedPost.fields).reduce((acu, key) => {
    if (key.startsWith("__")) {
      return acu;
    }
    const newKey = key.replace(/[A-Z]/g, (m) => "-" + m).replace(/^-/, "");
    acu[newKey] = getPresignedPost.fields[key];
    return acu;
  }, {});

  const results = {
    fields,
    url: getPresignedPost.url,
  };
  return results;
};

export const s3Upload = async ({
  endpoint = turinpayEndpoint,
  file,
  folder,
  s3Config = {
    key: "",
    bucket: s3BucketName,
    minBytes: 1000, // 1Kb
    maxBytes: 25000000, // 25Mb
  },
  userId,
}) => {
  console.log("s3Upload", endpoint, s3BucketName);
  // Step 1 - get pre-signed POST data.
  const presignedPostData = await loadPresigned(
    endpoint,
    file,
    folder,
    s3Config,
    userId
  );

  // Step 2 - upload the file to S3.
  return postFileToS3(presignedPostData, file);
};

export const signUrl = async ({ endpoint, bucket, key, expires }) => {
  console.log("> signUrl", endpoint, bucket, key, expires);
  return requestSignedUrl({
    endpoint,
    variables: { input: { bucket, key, expires } },
  });
};
