export const getNumberSeparators = () => {
  // default
  let res = {
    decimalSeparator: ".",
    thousandSeparator: "",
  };

  // convert a number formatted according to locale
  let str = parseFloat(1234.56).toLocaleString();

  // if the resulting number does not contain previous number
  // (i.e. in some Arabic formats), return defaults
  if (!str.match("1")) return res;

  // get decimal and thousand separators
  res.decimalSeparator = str.replace(/.*4(.*)5.*/, "$1");
  res.thousandSeparator = str.replace(/.*1(.*)2.*/, "$1");

  // return results
  return res;
};
