import { CheckCircleIcon } from "@heroicons/react/outline";
import { Card } from "../../../components/card";

const KYCAccepted = () => {
  return (
    <Card>
      <div className="flex flex-col gap-8 py-8">
        <div className="flex gap-8 items-center">
          <div className="flex-none shrink-0">
            <CheckCircleIcon className="w-12 h-auto text-eucalyptus" />
          </div>
          <div className="grow">
            <h1 className="text-3xl text-darkEucalyptus font-bold">
              Thanks for registering your KYC!
            </h1>
            <p className="text-lg mt-1">KYC validated successfully</p>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default KYCAccepted;
