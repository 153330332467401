import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getRevenue } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";

/* CONSTANTS
========================================================= */

const useGetRevenue = (data) => {
  /* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);
  const navigate = useNavigate();

  const handleGetRevenue = async () => {
    try {
      const result = await getRevenue(data);

      // If the API gives an error, redirect to login and show a message. Needs refinement to control different type of errors.
      if (
        result.getTPayEvolution.__typename === "GenericError" &&
        result.getTPayEvolution.code === "ERROR_USER_NOT_AUTHENTICATED"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Unable to get your user data",
          body: "Please, login again to access Turinpay.",
        });

        navigate("/login", { replace: true });
        return;
      }

      return result;
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to retrieve your data. Please, try again. If the error persist, contact us.",
      });
    }
  };

  return useQuery(
    [
      "getRevenue",
      data.variables.input.period,
      data.variables.input.currency,
      data.variables.input.executionEnvironment,
    ],
    () => handleGetRevenue(),
    {
      enabled: true,
      refetchOnWindowFocus: true,
      retry: true,
    }
  );
};

export default useGetRevenue;
