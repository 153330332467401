import { CameraIcon } from "@heroicons/react/outline";
import { QRCodeSVG } from "qrcode.react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

import turinpayImagotype from "../../assets/turinpay_imagotype_final.svg";

const TurinpayMeQrPrint = () => {
  const { slug } = useParams();
  const url = process.env.REACT_APP_TURINPAY_ME_URL;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <main className="bg-gray-100 relative">
      <div className="flex flex-col justify-center items-center gap-4 fixed bottom-40 w-full">
        <button
          type="button"
          onClick={handlePrint}
          className="bg-darkEucalyptus text-white text-xl px-4 py-2 flex items-center gap-2 rounded shadow"
        >
          <CameraIcon className="w-6" />
          <span>Print this out!</span>
        </button>
      </div>
      <div
        className="w-full h-screen flex items-center justify-center"
        ref={componentRef}
      >
        <div className="flex flex-col items-center text-center gap-16 bg-white w-full max-w-md m-4 px-4 py-20">
          <img src={turinpayImagotype} alt="Turinpay" className="w-32" />

          <div className="flex flex-col items-center gap-4">
            <QRCodeSVG
              value={`https://${url}/${slug}`}
              size={256}
              level={"H"}
              imageSettings={{
                src: "https://d3os3rq25u3h91.cloudfront.net/tp/images/turinpay_isotype.png",
                x: undefined,
                y: undefined,
                height: 64,
                width: 64,
                excavate: true,
              }}
            />

            <p className="text-2xl uppercase">
              Scan <strong>QR</strong> code
            </p>
          </div>

          <p className="text-gray-400">
            {url}/{slug}
          </p>
        </div>
      </div>
    </main>
  );
};

export default TurinpayMeQrPrint;
