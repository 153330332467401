import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import LoadingSpinner from "../../../assets/LoadingSpinner";
import { useTurinLabsReCaptcha } from "../../../components/recaptcha";
import { CONFIRM_SIGNIN, RESEND_AUTH_CODE } from "../../../services/gqlQueries";
import { confirmLoginQuery, mutateData } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";
import { emailTrimmer } from "../../../utils/helpers";
import { loggedUserCredentials, signinData } from "../store/auth.store";

/* COMPONENT
========================================================= */

const ConfirmLoginForm = () => {
  // Access query client
  const queryClient = useQueryClient();

  // Form hook
  const { register, handleSubmit, getValues } = useForm();

  // Callout handler
  const setCalloutMessage = useSetRecoilState(showCallout);

  // Data stored from previous step (login form)
  const [existingUserCredentials, setExistingUserCredentials] =
    useRecoilState(signinData);

  // Atom to store auth payload
  const setLoggedUserCredentials = useSetRecoilState(loggedUserCredentials);

  // Navigate hook
  const navigate = useNavigate();

  useEffect(() => {
    if (!existingUserCredentials) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitLogin = () => {
    const credentials = {
      query: CONFIRM_SIGNIN,
      variables: {
        input: {
          email: existingUserCredentials.email,
          authCodeEmail: getValues("emailCode"),
          domainApp: "TurinPay",
        },
      },
    };
    return confirmLoginQuery(credentials);
  };

  const confirmLoginUser = useQuery(
    "loggedUserCredentials",
    () => handleSubmitLogin(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      manual: true,
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  const onSubmit = async () => {
    try {
      // Manually trigger confirmLoginUser query on click
      const result = await confirmLoginUser.refetch();

      if (
        result.data.signinWithSecretByAuthCode.__typename === "GenericError"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: result.data.signinWithSecretByAuthCode.message,
          body: result.data.signinWithSecretByAuthCode.description,
        });
      } else {
        // Store authPayload in memory.
        setLoggedUserCredentials(result.data.signinWithSecretByAuthCode);

        // Set JWT and refresh token cache
        queryClient.setQueryData(
          "JWT",
          result.data.signinWithSecretByAuthCode.token
        );
        queryClient.setQueryData(
          "RT",
          result.data.signinWithSecretByAuthCode.refreshToken
        );

        // Redirect to home.
        navigate("/home");

        // Reset user credentials atom.
        setExistingUserCredentials(null);
      }
    } catch (error) {
      setCalloutMessage({
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to log you in. Please, try again.",
      });
      navigate("/login");
    }
  };

  // Resend auth codes
  const { getToken } = useTurinLabsReCaptcha();

  const resendEmailQueryData = {
    query: RESEND_AUTH_CODE,
    variables: {
      input: {
        email: existingUserCredentials.email,
        type: "LOGIN",
        domainApp: "TurinPay",
      },
    },
    googleReCaptchaToken: null,
  };

  const resendEmailCodeQuery = useQuery(
    ["emailAuthCode", resendEmailQueryData],
    async () => {
      resendEmailQueryData.googleReCaptchaToken = await getToken();
      return mutateData(resendEmailQueryData);
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      manual: true,
      retry: false,
      onError: (error) => {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Something went wrong",
          body: "An error ocurring when trying to resend you a new code. Please, try again.",
        });
      },
      onSuccess: (data) => {
        if (data.reSendAuthCode.__typename === "GenericError") {
          setCalloutMessage({
            show: true,
            type: 3,
            title: "Something went wrong",
            body: "An error ocurring when trying to resend you a new code. Please, try again.",
          });
        } else {
          setCalloutMessage({
            show: true,
            type: 1,
            title: "Code successfully sent",
            body: "A new code has been sent to the email/phone you provided. Check it out and enter it below.",
          });
        }
      },
    }
  );

  const handleResendCode = () => {
    resendEmailCodeQuery.refetch();
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="py-8 bg-white shadow rounded-xl">
        <div className="max-w-md mb-6">
          <h3 className="px-10 pb-2 font-bold text-left text-gray-900 text-3x1">
            Security Verification
          </h3>
          <p className="px-10 pb-6 text-sm font-medium text-left text-gray-500">
            Enter the code sent to the email you provided.
          </p>
          <hr />
        </div>
        <form className="px-10 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Code
            </label>
            <div className="flex flex-row mt-1 space-between">
              <input
                id="email"
                name="emailCode"
                type="text"
                inputMode="numeric"
                required
                className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm appearance-none focus:outline-none focus:ring-lightEucalyptus focus:border-darkEucalyptus"
                placeholder="******"
                maxLength={6}
                data-testid="email-code-login"
                {...register("emailCode", { required: true })}
              />
              <button
                className="w-3/6 px-2 ml-2 text-sm font-medium bg-transparent border-2 rounded-lg border-eucalyptus text-eucalyptus focus:outline-none hover:bg-eucalyptus hover:border-transparent hover:text-white"
                type="button"
                onClick={() => handleResendCode()}
                data-testid="resend-email-code"
              >
                Resend code
              </button>
            </div>
            <p className="mt-1 text-sm font-medium text-left text-gray-500">
              Code sent to{" "}
              {emailTrimmer(
                existingUserCredentials && existingUserCredentials.email
              )}
            </p>
          </div>

          <div>
            <button
              type="submit"
              className="flex justify-center px-4 py-2 mx-auto font-medium text-white border border-transparent shadow-sm rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus disabled:opacity-75"
              data-testid="confirm-login-button"
              disabled={confirmLoginUser.isLoading}
            >
              {confirmLoginUser.isLoading && <LoadingSpinner />}
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmLoginForm;
