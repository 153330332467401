import turinpayImagotype from "../../../assets/turinpay_imagotype_final.svg";
import { QRCodeSVG } from "qrcode.react";
import { PrimaryButton } from "../../../components/ui/PrimaryButton";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getPairRates, mutateData } from "../../../services/useCases";
import { CREATE_INVOICE_DATA_UNAUTH } from "../../../services/gqlQueries";
import CheckoutLoading from "./CheckoutLoading";
import AmountsPresenter from "./AmountsPresenter";
import InvoiceTimeout from "./InvoiceTimeout";
import { EventSourcePolyfill } from "event-source-polyfill";
import { BadgeCheckIcon } from "@heroicons/react/outline";
import CheckoutError from "./CheckoutError";
import { getSSEEndpoint } from "../../../helpers/environment.helper";
import { useTurinLabsReCaptcha } from "../../../components/recaptcha";

const Checkout = () => {
  const { getToken } = useTurinLabsReCaptcha();

  // Get paymentIntent id from params
  const { id } = useParams();

  const SSE_URL = getSSEEndpoint();

  // eslint-disable-next-line no-unused-vars
  const [currency, setCurrency] = useState("USD");
  const [invoiceStatus, setInvoiceStatus] = useState({
    isProcessed: false,
    isError: false,
  });

  const handleCopyToClipboard = (invoiceId) => {
    navigator.clipboard.writeText(invoiceId);
  };

  // Build mutation object
  const mutationData = {
    input: {
      paymentIntentId: id,
    },
  };

  // Get pair rates. Fetch them every minute.
  const pairRates = useQuery("pairRates", getPairRates, {
    refetchInterval: 60000,
  });

  // Create invoice from payment intent data
  const getInvoice = useMutation(() =>
    mutateData({
      token: null,
      auth: null,
      query: CREATE_INVOICE_DATA_UNAUTH,
      variables: mutationData,
    })
  );

  useEffect(() => {
    getInvoice.mutate(mutationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getInvoice.isSuccess) {
      // Open sse tunnel
      const sse = new EventSourcePolyfill(
        `${SSE_URL}${getInvoice.data.createTPayLightningInvoice._id}`
      );

      sse.onmessage = (event) => {
        if (
          event.data.startsWith("{") &&
          JSON.parse(event.data).status === "PAID"
        ) {
          // Control locally that invoice is paid.
          setInvoiceStatus({ isProcessed: true, isError: false });

          // Send message to parent
          window.parent &&
            window.parent.postMessage(
              {
                invoiceId: getInvoice.data.createTPayLightningInvoice._id,
                paymentIntentId: id,
              },
              "*"
            );
        } else {
          return;
        }
      };
    }
  }, [getInvoice, id, SSE_URL]);

  if (getInvoice.isLoading || !getInvoice.data || pairRates.isLoading)
    return <CheckoutLoading />;
  else if (
    getInvoice.isSuccess &&
    getInvoice.data &&
    getInvoice.data.createTPayLightningInvoice.__typename === "GenericError"
  ) {
    return <CheckoutError data={getInvoice.data.createTPayLightningInvoice} />;
  } else if (
    getInvoice.isSuccess &&
    getInvoice.data &&
    getInvoice.data.createTPayLightningInvoice.__typename !== "GenericError"
  ) {
    return (
      <main className="flex items-center justify-center w-screen h-screen bg-slate-200">
        <section className="w-screen mx-2 sm:w-96 sm:mx-0">
          {/* Card */}

          <div className="h-auto w-full pb-10 bg-white shadow-md sm:w-96 sm:h-auto rounded-xl">
            {/* Header */}

            <div className="flex flex-col items-center justify-center w-full">
              <img
                src={turinpayImagotype}
                alt="Turinpay logotype"
                className="my-4"
              />
              <InvoiceTimeout
                expireDate={
                  getInvoice.data.createTPayLightningInvoice.expiresOn
                }
                invoiceStatus={invoiceStatus}
              />
            </div>

            {/* Body */}

            <div className="flex flex-col items-center justify-center w-full h-auto">
              <div className="flex flex-col items-center justify-center w-full border border-b-gray-300">
                <AmountsPresenter
                  satsAmount={getInvoice.data.createTPayLightningInvoice.amount}
                  currency={currency}
                />
              </div>

              <div className="flex items-center justify-center w-64 h-auto mt-6">
                {invoiceStatus.isProcessed && !invoiceStatus.isError ? (
                  <BadgeCheckIcon className="w-48 h-auto text-darkEucalyptus" />
                ) : (
                  <QRCodeSVG
                    value={getInvoice.data.createTPayLightningInvoice.request}
                    size={256}
                    level={"H"}
                    imageSettings={{
                      src: "https://d3os3rq25u3h91.cloudfront.net/tp/images/turinpay_isotype.png",
                      x: undefined,
                      y: undefined,
                      height: 64,
                      width: 64,
                      excavate: true,
                    }}
                  />
                )}
              </div>
              {!invoiceStatus.isProcessed && !invoiceStatus.isError && (
                <>
                  <div className="flex flex-col items-center justify-start w-64 h-auto my-6">
                    <label
                      htmlFor="invoice"
                      className="w-full mb-2 text-sm font-bold text-left"
                    >
                      INVOICE
                    </label>

                    {/* Hide invoice string if invoice has been paid */}

                    {!invoiceStatus.isProcessed && !invoiceStatus.isError && (
                      <div
                        className="w-full h-auto p-2 border border-gray-200 rounded-xl"
                        id="invoice"
                      >
                        {getInvoice.data &&
                          getInvoice.data.createTPayLightningInvoice.request.slice(
                            0,
                            10
                          )}
                        ...
                        {getInvoice.data &&
                          getInvoice.data.createTPayLightningInvoice.request.slice(
                            getInvoice.data &&
                              getInvoice.data.createTPayLightningInvoice.request
                                .length - 10
                          )}
                      </div>
                    )}
                  </div>
                  <PrimaryButton
                    copy="COPY INVOICE CODE"
                    onClick={() =>
                      handleCopyToClipboard(
                        getInvoice.data.createTPayLightningInvoice.request
                      )
                    }
                  />
                </>
              )}
            </div>
          </div>
        </section>
      </main>
    );
  }
};

export default Checkout;
