import { useInfiniteQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getPaymentIntents } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";

/* CONSTANTS
========================================================= */

const useGetIntents = (
  token,
  apiKey,
  offset,
  currency
) => {

  /* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);
  const navigate = useNavigate();

  const handleGetIntentsHistory = async ({ pageParam = 0 }) => {
    try {
      // Build query data object
      const queryData = {
        token: token,
        apiKey: apiKey,
        variables: {
          input: {
            offset: pageParam,
            limit: 10,
            orderBy: "date",
            orderDirection: "DESC",
            currency: currency,
          },
        },
      };

      const result = await getPaymentIntents(queryData);

      // If the API gives an error, redirect to login and show a message.
      if (
        result.getTPayPaymentIntents.__typename === "GenericError" &&
        result.getTPayPaymentIntents.code === "ERROR_USER_NOT_AUTHENTICATED"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Your session has expired.",
          body: "Please, login again.",
        });

        navigate("https://app.turinpay.com", { replace: true });
        return;

        // If not, refresh global state with fresh data.
      } else {
        return result;
      }
      // If it doesn't work, send to login and show an error message.
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to retrieve your data. Please, try again. If the error persist, contact us.",
      });
    }
  };

  return useInfiniteQuery(
    ["intentsHistory", offset, token, apiKey, currency],
    handleGetIntentsHistory,
    {
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) {
          return undefined;
          // Check if numbers of elements left is lower than the resulting offset from loading another page of txs
        } else if (
          lastPage.getTPayPaymentIntents.metadata.numElements >
            lastPage.getTPayPaymentIntents.metadata.offset &&
          lastPage.getTPayPaymentIntents.metadata.numElements <
            lastPage.getTPayPaymentIntents.metadata.offset + 10
        ) {
          return (
            lastPage.getTPayPaymentIntents.metadata.numElements -
            lastPage.getTPayPaymentIntents.metadata.offset
          );
        } else {
          return pages.length * 10;
        }
      },
    }
  );
};

export default useGetIntents;
