const FormInputDate = ({ value, onChange = () => {}, ...props }) => (
  <input
    type="date"
    defaultValue={value}
    onChange={(response) => onChange(response.target.value)}
    className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
    {...props}
  />
);

export default FormInputDate;
