import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { useState } from "react";

import { DOMAIN } from "../../../services/gqlQueries";
import { initPasswordRecovery } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";
import { useTurinLabsReCaptcha } from "../../../components/recaptcha";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const setCalloutMessage = useSetRecoilState(showCallout);
  const navigate = useNavigate();
  const { getToken } = useTurinLabsReCaptcha();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const inputData = {
        input: {
          email: getValues("email"),
          domainApp: DOMAIN,
        },
      };
      const googleReCaptchaToken = await getToken();

      await initPasswordRecovery({
        variables: inputData,
        googleReCaptchaToken,
      });
      navigate("/reset-password");
    } catch (error) {
      // Inform of error and redirect to login
      navigate("/login");
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to initiate your password recovery. Please, try again. If the error persist, contact us.",
      });
    }
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="py-8 bg-white shadow rounded-xl">
        <div className="max-w-md mb-6">
          <h3 className="px-10 pb-6 font-bold text-left text-gray-900 text-3x1">
            Recover password
          </h3>
          <p className="px-10 mb-6 text-gray-700">
            Enter the email associated to your Turinpay account. If there's one,
            you'll receive a 6-digit code you'll be prompted to input.
          </p>
          <hr />
        </div>
        <form className="px-10 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                inputMode="email"
                autoComplete="email"
                className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm appearance-none focus:outline-none focus:border-eucalyptus"
                placeholder="satoshi@nakamoto.com"
                data-testid="login-input"
                {...register("email", { required: true })}
              />
              {errors.email?.type === "required" && (
                <p className="mt-2 text-sm font-bold text-red-700">
                  Please, type your email
                </p>
              )}
            </div>
          </div>

          <div>
            <button
              type="submit"
              id="submit-button"
              className="flex justify-center px-4 py-2 mx-auto font-medium text-white border border-transparent shadow-sm rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus"
              disabled={isLoading}
            >
              Next
            </button>
          </div>
        </form>
      </div>

      <div className="flex flex-row justify-center mt-6">
        <p className="text-sm font-medium">
          Do you remember it already?
          <Link
            to="/login"
            className="text-eucalyptus"
            data-testid="login-link"
          >
            {" "}
            Back to login
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
