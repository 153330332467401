export const SettingsSkeleton = ({ fieldsNumber = 1 }) => {
  const items = new Array(fieldsNumber).fill(0);

  return (
    <div className="flex flex-col space-y-5 mt-5">
      {
        items.map((_, index) => (
          <div key={index} className="flex flex-col justify-start items-start">
            <div className="bg-gray-400 h-4 w-20 mb-2 rounded animate-pulse" />
            <div className="bg-gray-400 h-8 w-64 rounded-md animate-pulse" />
          </div>
        ))
      }
    </div>
  );
};