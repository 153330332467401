const TxListLoading = () => {
  return (
    <>
      <section className="lg:col-span-9 xl:col-span-10 mt-10 h-full">
        <div className="w-full h-auto flex flex-col justify-start items-center">
          <ul className="divide-y divide-gray-200 w-full">
            <li className="animate-pulse" role="button">
              <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex">
                      <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex">
                        <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                </div>
              </div>
            </li>
            <li className="animate-pulse" role="button">
              <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex">
                      <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex">
                        <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                </div>
              </div>
            </li>
            <li className="animate-pulse" role="button">
              <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex">
                      <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex">
                        <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                </div>
              </div>
            </li>
            <li className="animate-pulse" role="button">
              <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex">
                      <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex">
                        <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                </div>
              </div>
            </li>
            <li className="animate-pulse" role="button">
              <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex">
                      <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex">
                        <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="h-4 w-24 bg-slate-300 rounded-xl"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default TxListLoading;
