import { ExternalLinkIcon } from "@heroicons/react/solid";

const PublicPaymentsLink = ({ slug }) => (
  <a
    href={`/me/${slug}`}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center gap-2 text-sm font-bold mt-1 text-eucalyptus hover:underline"
  >
    <span>Open my public payment profile</span>
    <ExternalLinkIcon className="h-4 w-4" />
  </a>
);

export default PublicPaymentsLink;
