import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { getSettingsQuery } from '../../../services/useCases';
import { showCallout } from '../../../store/callout.atoms';

/* CONSTANTS
========================================================= */

const useGetSettings = (jwtToken) => {
  
	/* DEPENDENCIES
========================================================= */

	const setCalloutMessage = useSetRecoilState(showCallout);
	const navigate = useNavigate();

	const handleUseGetSettings = async () => {
		try {
			const result = await getSettingsQuery(jwtToken);

			// If the API gives an error, redirect to login and show a message.
			if (
				result.getTPaySettings.__typename === 'GenericError' &&
				result.getTPaySettings.code === "ERROR_USER_NOT_AUTHENTICATED"
			) {
				setCalloutMessage({
					show: true,
					type: 3,
					title: 'Your session has expired.',
					body: 'Please, login again'
				});
				navigate("/login", { replace: true });
			} else {
				return result;
			}
			// If it doesn't work, show an error message.
		} catch (error) {
			setCalloutMessage({
				show: true,
				type: 3,
				title: 'Something went wrong.',
				body: 'An error ocurred when trying to get your portfolio information. Please, refresh this page and login again.'
			});
		}
	};

	return useQuery(['userSettings', jwtToken], () => handleUseGetSettings(), { refetchOnWindowFocus: false });
};

export default useGetSettings;