/* eslint-disable react-hooks/exhaustive-deps */
import { BadgeCheckIcon } from "@heroicons/react/outline";
import { EventSourcePolyfill } from "event-source-polyfill";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import dayJs from "dayjs";

import { CREATE_INVOICE_DATA_UNAUTH } from "../../../services/gqlQueries";
import { createPI, mutateData } from "../../../services/useCases";
import { getSSEEndpoint } from "../../../helpers/environment.helper";
import { uuidv4 } from "../../../utils/uuid";
import AmountsPresenter from "../../checkout/components/AmountsPresenter";
import CheckoutError from "../../checkout/components/CheckoutError";
import CheckoutLoading from "../../checkout/components/CheckoutLoading";
import InvoiceTimeout from "../../checkout/components/InvoiceTimeout";
import turinpayImagotype from "../../../assets/turinpay_imagotype_final.svg";

export const PaymentStep2 = ({
  amount,
  description,
  slug,
  useSandboxExecutionEnvironment,
  googleReCaptchaToken,
}) => {
  const [invoiceStatus, setInvoiceStatus] = useState({
    isProcessed: false,
    isError: false,
  });

  const isInvoicePaid = invoiceStatus.isProcessed && !invoiceStatus.isError;

  const SSE_URL = getSSEEndpoint();

  const handleCopyToClipboard = (invoiceId) => {
    navigator.clipboard.writeText(invoiceId);
  };

  const handleCreatePi = async (slug, amount) => {
    try {
      const inputData = {
        input: {
          slug,
          executionEnvironment: useSandboxExecutionEnvironment
            ? "SANDBOX_ENVIRONMENT"
            : "PRODUCTION_ENVIRONMENT",
          orderId: uuidv4(),
          currency: "EUR",
          price: Number(amount),
          description,
          initDate: dayJs(),
          endDate: dayJs().add(1, "hour"),
          onlyLN: false,
        },
      };

      const result = await createPI({
        variables: inputData,
        googleReCaptchaToken,
      });

      return result;
    } catch (error) {
      console.log("ERROR", error);
      return;
    }
  };

  // Create payment intent
  const createPi = useQuery(
    ["paymentIntent", slug, amount],
    () => handleCreatePi(slug, amount),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: async (response) => {
        if (
          response.createTPayPaymentIntentBySlug.__typename &&
          response.createTPayPaymentIntentBySlug.__typename === "GenericError"
        ) {
          throw new Error("Invalid captcha");
        } else {
          return getInvoice.mutate({
            token: null,
            auth: null,
            query: CREATE_INVOICE_DATA_UNAUTH,
            variables: {
              input: {
                paymentIntentId: response.createTPayPaymentIntentBySlug.id,
              },
            },
          });
        }
      },
    }
  );

  // Create invoice from payment intent data
  const getInvoice = useMutation((variables) => mutateData(variables));

  useEffect(() => {
    if (getInvoice.isSuccess) {
      // Open sse tunnel
      const sse = new EventSourcePolyfill(
        `${SSE_URL}${getInvoice.data.createTPayLightningInvoice._id}`
      );

      sse.onmessage = (event) => {
        if (
          event.data.startsWith("{") &&
          JSON.parse(event.data).status === "PAID"
        ) {
          // Control locally that invoice is paid.
          setInvoiceStatus({ isProcessed: true, isError: false });

          // Send message to parent
          window.parent &&
            window.parent.postMessage(
              {
                invoiceId: getInvoice.data?.createTPayLightningInvoice?._id,
                paymentIntentId:
                  createPi.data?.createTPayPaymentIntentBySlug?.id,
              },
              "*"
            );
        } else {
          return;
        }
      };
    }
  }, [getInvoice, createPi.data?.createTPayPaymentIntentBySlug?.id, SSE_URL]);

  if (
    getInvoice.isLoading ||
    createPi.isLoading ||
    (!getInvoice.data && !createPi.data)
  ) {
    return <CheckoutLoading />;
  } else if (
    createPi.isSuccess &&
    createPi.data?.createTPayPaymentIntentBySlug?.__typename === "GenericError"
  ) {
    return (
      <CheckoutError data={createPi.data?.createTPayPaymentIntentBySlug} />
    );
  } else if (
    getInvoice.isSuccess &&
    getInvoice.data?.createTPayLightningInvoice?.__typename === "GenericError"
  ) {
    return <CheckoutError data={getInvoice.data.createTPayLightningInvoice} />;
  } else if (
    getInvoice.isSuccess &&
    getInvoice.data &&
    getInvoice.data.createTPayLightningInvoice.__typename !== "GenericError"
  ) {
    return (
      <section className="flex items-center justify-center w-full h-full mt-20">
        <div className="w-screen mx-2 sm:w-96 sm:mx-0">
          {/* Card */}

          <div className="h-auto w-full pb-10 bg-white shadow-md sm:w-96 sm:h-auto rounded-xl">
            {/* Header */}

            <div className="flex flex-col items-center justify-center w-full">
              <img
                src={turinpayImagotype}
                alt="Turinpay logotype"
                className="my-4"
              />
              <InvoiceTimeout
                expireDate={
                  getInvoice.data?.createTPayLightningInvoice?.expiresOn
                }
                invoiceStatus={invoiceStatus}
              />
            </div>

            {/* Body */}

            <div className="flex flex-col items-center justify-center w-full h-auto">
              <div className="flex flex-col items-center justify-center w-full border border-b-gray-300">
                <div className="w-full flex flex-col items-center justify-start px-4">
                  <AmountsPresenter
                    satsAmount={
                      getInvoice.data?.createTPayLightningInvoice?.amount
                    }
                    currency="EUR"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center w-64 h-auto mt-6">
                {isInvoicePaid ? (
                  <div className="flex flex-col justify-start items-center space-y-2">
                    <BadgeCheckIcon className="w-48 h-auto text-darkEucalyptus" />
                    <p className="font-bold text-xl">
                      Invoice successfully paid
                    </p>
                  </div>
                ) : (
                  <QRCodeSVG
                    value={getInvoice.data?.createTPayLightningInvoice?.request}
                    size={256}
                    level={"H"}
                    imageSettings={{
                      src: "https://d3os3rq25u3h91.cloudfront.net/tp/images/turinpay_isotype.png",
                      x: undefined,
                      y: undefined,
                      height: 64,
                      width: 64,
                      excavate: true,
                    }}
                  />
                )}
              </div>
              {!isInvoicePaid && (
                <>
                  <div className="flex flex-col items-center justify-start w-64 h-auto my-6">
                    <label
                      htmlFor="invoice"
                      className="w-full mb-2 text-sm font-bold text-left"
                    >
                      INVOICE
                    </label>

                    {/* Hide invoice string if invoice has been paid */}

                    <div
                      className="w-full h-auto p-2 border border-gray-200 rounded-xl"
                      id="invoice"
                    >
                      {getInvoice.data &&
                        getInvoice.data.createTPayLightningInvoice.request.slice(
                          0,
                          10
                        )}
                      ...
                      {getInvoice.data &&
                        getInvoice.data.createTPayLightningInvoice.request.slice(
                          getInvoice.data &&
                            getInvoice.data.createTPayLightningInvoice.request
                              .length - 10
                        )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    id="login-button"
                    className="w-fit flex items-center justify-center px-4 py-2 mx-auto font-medium text-white border border-transparent shadow rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus disabled:opacity-75 mt-8"
                    onClick={() =>
                      handleCopyToClipboard(
                        getInvoice.data?.createTPayLightningInvoice.request
                      )
                    }
                  >
                    COPY TO CLIPBOARD
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
};
