import * as yup from "yup";
import { isPropertyMedia } from "../../config/validations";

import { KEYS } from "./config";

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB
const SUPPORTED_FORMATS = [
  "image/gif",
  "image/jpg",
  "image/jpeg",
  "image/svg+xml",
  "image/png",
];

export const SCHEMA = yup.object().shape({
  [KEYS.PAYMENTS.ENABLED]: yup.string().required("Required field").nullable(),
  [KEYS.PAYMENTS.SLUG]: yup.string().required("Required field").nullable(),
  [KEYS.PAYMENTS.NAME]: yup.string().required("Required field").nullable(),
  [KEYS.PAYMENTS.DESCRIPTION]: yup
    .string()
    .required("Required field")
    .nullable(),
  [KEYS.PAYMENTS.PROFILE_PICTURE]: yup
    .mixed()
    .test(
      "fileSize",
      "File too large",
      (value) =>
        !value ||
        isPropertyMedia(value) ||
        (value && value.size <= MAX_FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Not valid",
      (value) =>
        !value ||
        isPropertyMedia(value) ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  [KEYS.PAYMENTS.COVER_PICTURE]: yup
    .mixed()
    .test(
      "fileSize",
      "File too large",
      (value) =>
        !value ||
        isPropertyMedia(value) ||
        (value && value.size <= MAX_FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Not valid",
      (value) =>
        !value ||
        isPropertyMedia(value) ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    ),
});
