// Constant to get queries according to several time intervals
export const timeIntervalConstants = {
  hour: "HOUR",
  day: "DAY",
  week: "WEEK",
  month: "MONTH",
  year: "YEAR",
  all: "ALL",
};

export const currencyConstants = {
  usd: "USD",
  eur: "EUR",
  btc: "BTC",
  sats: "SAT",
};

// App navigation locations
export const locations = {
  home: "HOME",
  wallet: "WALLET",
  settings: "SETTINGS",
  intents: "INTENTS",
  turinpayMe: "TURINPAY_ME",
  kyc: "KYC",
};

// Manage granularity expected by the API to return information points
// to populate charts and TX lists.
export const handleGranularity = (period) => {
  if (period === timeIntervalConstants.hour) return 10;
  if (period === timeIntervalConstants.day) return 60;
  if (period === timeIntervalConstants.week) return 60 * 24;
  if (period === timeIntervalConstants.month) return 60 * 24;
  if (period === timeIntervalConstants.year) return 60 * 24 * 7;
  if (period === timeIntervalConstants.all) return 60 * 24 * 7;
};
