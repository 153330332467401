import { ExclamationCircleIcon, PencilIcon } from "@heroicons/react/outline";
import { Button } from "../../../components/button";
import { Card } from "../../../components/card";

const KYCRejected = ({
  reason = "Reason not indicated",
  onReview = () => {},
}) => {
  return (
    <Card>
      <div className="flex flex-col lg:flex-row items-center gap-8 py-8">
        <div className="flex gap-8 items-center grow">
          <div className="flex-none shrink-0">
            <ExclamationCircleIcon className="w-12 h-auto text-red-700" />
          </div>
          <div className="grow">
            <h1 className="text-3xl text-darkEucalyptus font-bold">
              Rejected KYC
            </h1>
            <p className="text-lg mt-1 opacity-70">
              Your KYC has been rejected. Below is the reason why registration
              was denied
            </p>
            <p className="text-xl font-bold pt-4 ">
              <span className="font-normal">Reason: </span>
              {reason}
            </p>
          </div>
        </div>
        <div className="flex flex-row items-start justify-center pb-6">
          <Button onClick={onReview} className="flex items-center gap-2">
            <PencilIcon className="w-5" />
            <span>Review</span>
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default KYCRejected;
