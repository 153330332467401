import { currencyConstants } from "../helpers/homeLayout.helpers";

const CurrencySelector = ({ handleClick, currency }) => {

  return (
    <select 
      className="block py-2 pl-3 mt-1 mr-4 text-xs md:text-sm font-bold border-2 rounded-lg cursor-pointer w-fit lg:w-32 border-eucalyptus focus:outline-none focus:ring-eucalyptus focus:border-eucalyptus sm:text-sm text-eucalyptus hover:bg-eucalyptus hover:text-white"
      value={currency}
      onChange={(e) => handleClick(e.target.value)}
    >
      <option key="USD" value={currencyConstants.usd}>USD</option>
      <option key="EUR" value={currencyConstants.eur}>EUR</option>
      <option key="BTC" value={currencyConstants.btc}>BTC</option>
      <option key="SAT" value={currencyConstants.sats}>SAT</option>
    </select>
  );
};

export default CurrencySelector;
