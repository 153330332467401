export const KybPrompt = ({ onComplete = () => {} }) => {
  return (
    <div className="block w-full bg-darkEucalyptus">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:text-center sm:px-16">
          <p className="font-medium text-white">
            <span className="md:hidden">
              Fill the KYC form to unlock your production API key
            </span>
            <span className="hidden md:inline">
              {" "}
              Welcome to Turinpay! Fill the KYC form to unlock your production
              API key.
            </span>
            <span className="block sm:ml-2 sm:inline-block">
              <span
                className="text-white font-bold underline cursor-pointer hover:opacity-90"
                onClick={onComplete}
              >
                Submit form <span aria-hidden="true">&rarr;</span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
