import { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import LoadingSpinner from "../../../assets/LoadingSpinner";
import { PrimaryButton } from "../../../components/ui/PrimaryButton";
import { navigation } from "../../../store/global.atoms";
import PayoutElement from "../../HomeLayout/components/PayoutElement";
import TxListEmpty from "../../HomeLayout/components/TxListEmpty";
import TxListLoading from "../../HomeLayout/components/TxListLoading";
import { locations } from "../../HomeLayout/helpers/homeLayout.helpers";
import PayoutDetailModal from "./PayoutDetailModal";
import PayoutsListEmpty from "./PayoutsListEmpty";
import PayoutsWizard from "./PayoutsWizard";

const PayoutsList = ({
  payoutsHistory,
  userData,
  currency,
  walletBalance,
  handleLoadMore,
}) => {
  const [selectedTx, setSelectedTx] = useState(null);
  const [showTxDetailModal, setShowTxDetailModal] = useState(false);
  const [showPayoutsWizard, setShowPayoutsWizard] = useState(false);

  // Create ref for UL tx list
  const payoutsUl = useRef();

  // Get location to handle messages in TXList section
  const whereUserIs = useRecoilValue(navigation);

  const handleTxClick = (transaction) => {
    setSelectedTx(transaction);
    setShowTxDetailModal(true);
  };

  if (showPayoutsWizard)
    return (
      <PayoutsWizard
        setShowPayoutsWizard={setShowPayoutsWizard}
        currency={currency}
        walletBalance={walletBalance}
        payoutsHistory={payoutsHistory}
      />
    );

  // If tx list is loading
  if (payoutsHistory.isLoading) return <TxListLoading />;

  if (
    payoutsHistory.isError ||
    !payoutsHistory.data.pages[0] ||
    payoutsHistory.data.pages[0].getTPayHistory.items.length === 0
  ) {
    return (
      <section className="mt-10 lg:col-span-9 xl:col-span-10">
        {whereUserIs === locations.wallet ? (
          <div className="flex items-center justify-between w-full h-auto mb-4">
            <h3 className="text-2xl font-bold text-darkEucalyptus">Payouts</h3>
            <PrimaryButton
              copy="WITHDRAW FUNDS"
              onClick={() => setShowPayoutsWizard(true)}
            />
          </div>
        ) : (
          <div className="flex items-center justify-between w-full h-auto mb-4">
            <h3 className="text-2xl font-bold text-darkEucalyptus">Sales</h3>
          </div>
        )}
        <TxListEmpty userData={userData} />
      </section>
    );
  }

  // If petition is loading...
  else if (payoutsHistory.isLoading) {
    return <TxListLoading />;
  }

  // If user is seeing her wallet and have no payouts
  else if (
    whereUserIs === locations.wallet &&
    payoutsHistory.isSuccess &&
    payoutsHistory.data.pages[0].getTPayHistory.items.length === 0
  ) {
    return <PayoutsListEmpty />;
  } else {
    return (
      <>
        <section className="mt-10 lg:col-span-9 xl:col-span-10">
          {whereUserIs === locations.wallet ? (
            <div className="flex items-center justify-between w-full h-auto mb-4">
              <h3 className="text-2xl font-bold text-darkEucalyptus">
                Payouts
              </h3>
              <PrimaryButton
                copy="WITHDRAW FUNDS"
                onClick={() => setShowPayoutsWizard(!showPayoutsWizard)}
              />
            </div>
          ) : (
            <div className="flex items-center justify-between w-full h-auto mb-4">
              <h3 className="text-2xl font-bold text-darkEucalyptus">Sales</h3>
            </div>
          )}
          <div className="px-10 py-5 bg-white shadow rounded-xl">
            <ul ref={payoutsUl} className="divide-y divide-gray-200">
              {payoutsHistory.data.pages.map((page) =>
                page.getTPayHistory.items.map((transaction, index) => (
                  <li key={index}>
                    <PayoutElement
                      transaction={transaction}
                      handleClick={handleTxClick}
                      currency={currency}
                    />
                  </li>
                ))
              )}
            </ul>
          </div>
          {payoutsUl.current &&
            payoutsUl.current.childElementCount <
              payoutsHistory.data.pages[0].getTPayHistory.metadata
                .numElements && (
              <button
                className="w-full h-12 my-6 font-bold text-white rounded-lg bg-darkEucalyptus disabled:opacity-75"
                onClick={() => handleLoadMore()}
                disabled={payoutsHistory.isLoading}
              >
                {payoutsHistory.isLoading && <LoadingSpinner />}
                LOAD MORE
              </button>
            )}
        </section>
        <PayoutDetailModal
          selectedTx={selectedTx}
          showTxDetailModal={showTxDetailModal}
          setShowTxDetailModal={setShowTxDetailModal}
          currency={currency}
        />
      </>
    );
  }
};

export default PayoutsList;
