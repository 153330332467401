import { Fragment, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { useRecoilState } from 'recoil';
import { CheckCircleIcon, ExclamationIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import { XIcon } from '@heroicons/react/solid';
import { showCallout } from '../../store/callout.atoms';

const Callout = () => {
  const [calloutData, setCalloutData] = useRecoilState(showCallout);
  const [show, setShow] = useState(calloutData.show)

  // If callout property 'autoClose' is set to true, run this.
  useEffect(() => {
    if (calloutData.autoClose === false) return;
    const closeCallout = () => { setShow(false); setCalloutData({ ...calloutData, show: false }) }
    const timer = setTimeout(() => closeCallout(), 3000);


    return () => clearTimeout(timer);
  }, [calloutData.autoClose, setCalloutData, calloutData]);

  const handleShowCallout = () => {
    setShow(false);
    setCalloutData({...calloutData, show: false});
  };

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-center z-20"
      >
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden" data-testid='callout'>
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {
                    calloutData.type === 1 
                      ?  <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                      : (calloutData.type === 2)
                        ? <ExclamationIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
                        : <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                  }
                  
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">{calloutData.title}</p>
                  <p className="mt-1 text-sm text-gray-500">{calloutData.body}</p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      handleShowCallout()
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  )
}
 
export default Callout;