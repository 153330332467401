import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getPublicPayments } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";

/* CONSTANTS
========================================================= */

const useGetPublicPayments = (jwtToken) => {
  
  /* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);
  const navigate = useNavigate();

  const handleGetPublicPayments = async () => {
    try {
      const result = await getPublicPayments(jwtToken);

      // If the API gives an error, redirect to login and show a message.
      if (
        result.getTPayPublicPaymentProfile.__typename === "GenericError" &&
        result.getTPayPublicPaymentProfile.code ===
          "ERROR_USER_NOT_AUTHENTICATED"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Your session has expired.",
          body: "Please, login again",
        });
        navigate("/login", { replace: true });
      } else {
        return result;
      }
      // If it doesn't work, show an error message.
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to get your portfolio information. Please, refresh this page and login again.",
      });
    }
  };

  return useQuery(["publicPayments", jwtToken], () =>
    handleGetPublicPayments(),
    {
      refetchOnWindowFocus: false
    }
  );
};

export default useGetPublicPayments;
