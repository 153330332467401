const PayoutsListEmpty = () => {
  return (
    <section className="lg:col-span-9 xl:col-span-10 mt-10">
      <div className="w-full h-auto flex flex-col justify-start items-center">
        <div className="w-full max-w-2xl h-auto flex flex-col justify-start items-center">
          <h1 className="w-full h-auto text-left text-2xl text-darkEucalyptus font-bold">
            You have no payouts for the selected period
          </h1>
          <p className="w-full font-bold text-left mt-10">
            To withdraw funds from your wallet, click the 'Payout' button and follow the steps.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PayoutsListEmpty;
