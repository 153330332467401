import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { queryClient } from "../../../config/react-query.config";
import { getEnvironment } from "../../../helpers/common.helpers";
import { isDeveloperMode } from "../../../store/global.atoms";
import useConfirmPayout from "../hooks/useConfirmPayout";
import useGetUserBalance from "../hooks/useGetUserBalance";
import useInitPayout from "../hooks/useInitPayout";
import useValidateInvoice from "../hooks/useValidateInvoice";
import WizardStep1 from "./WizardSteps/WizardStep1";
import WizardStep2 from "./WizardSteps/WizardStep2";
import WizardStep3 from "./WizardSteps/WizardStep3";

const PayoutsWizard = ({
  setShowPayoutsWizard,
  currency,
  walletBalance,
  payoutsHistory,
}) => {
  // 1) Paste invoice, 2) Confirm withdrawal, 3) Success, 4) Failure
  const [step, setStep] = useState(1);
  const [isError, setIsError] = useState({ show: false, message: "" });
  const [invoiceString, setInvoiceString] = useState("");
  const [confirmationCode, setConfirmationCode] = useState(undefined);

  const developerMode = useRecoilValue(isDeveloperMode);

  // User data
  const userData = queryClient.getQueryData("loggedUserCredentials");

  // Build data object to get user wallet balance
  const userWalletVariables = {
    token: userData.signinWithSecretByAuthCode.token,
    variables: {
      input: {
        executionEnvironment: getEnvironment(developerMode),
        currency: currency,
      },
    },
  };

  // Build data object to get user wallet balance
  const invoiceVariables = {
    token: userData.signinWithSecretByAuthCode.token,
    variables: {
      input: {
        invoice: invoiceString,
      },
    },
  };

  // Build init payout variables
  const initPayoutVariables = {
    token: userData.signinWithSecretByAuthCode.token,
    variables: {
      input: {
        invoice: invoiceString,
        executionEnvironment: getEnvironment(developerMode),
      },
    },
  };

  // Get user balance
  const userWalletBalance = useGetUserBalance(userWalletVariables);

  // Validate invoice
  const invoiceData = useValidateInvoice(invoiceVariables);

  // Init payout
  const initPayout = useInitPayout(initPayoutVariables);

  const confirmPayoutVariables = {
    token: userData.signinWithSecretByAuthCode.token,
    variables: {
      input: {
        invoice: invoiceString,
        slippage: 0.5,
        tpayInvoicePaymentData:
          invoiceData.isSuccess &&
          invoiceData.data.checkTPayLnInvoice.tpayInvoicePaymentData,
        code: confirmationCode,
        executionEnvironment: getEnvironment(developerMode),
      },
    },
  };

  // Confirm payout mutation
  const confirmPayout = useConfirmPayout(confirmPayoutVariables);

  const handleChange = (event) => {
    event.preventDefault();
    setConfirmationCode(event.target.value);
  };

  // Refresh user balance when a payout is properly confirmed
  useEffect(() => {
    if (
      step === 3 &&
      confirmPayout.isSuccess &&
      confirmPayout.data.payTPayInvoice.result === true
    ) {
      userWalletBalance.refetch();
      walletBalance.refetch();
      payoutsHistory.refetch();
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  // Pass wizard data to all steps
  const wizardData = {
    step,
    setStep,
    isError,
    setIsError,
    invoiceString,
    setInvoiceString,
    setShowPayoutsWizard,
    invoiceData,
    confirmPayout,
    confirmationCode,
    setConfirmationCode,
    initPayout,
    userWalletBalance,
    walletBalance,
    payoutsHistory,
    handleChange,
  };

  // Controlar el invoice string cuando se hace click en next
  const renderStep = () => {
    if (step === 1) return <WizardStep1 wizardData={wizardData} />;
    if (step === 2 && !isError.show)
      return <WizardStep2 wizardData={wizardData} />;
    if (step === 3 && !isError.show)
      return <WizardStep3 wizardData={wizardData} />;
  };

  return renderStep();
};

export default PayoutsWizard;
