import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { initPayout } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";

/* CONSTANTS
========================================================= */

const useInitPayout = (data) => {
  /* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);
	const navigate = useNavigate();

  const handleInitPayout = async () => {
    try {
      if (!data) return;

      const result = await initPayout(data);

      // If the API gives an error, redirect to login and show a message.
      if (
        result.initTPayPaymentLNInvoice.__typename &&
        result.initTPayPaymentLNInvoice.__typename === "GenericError" &&
				result.initTPayPaymentLNInvoice.code === "ERROR_USER_NOT_AUTHENTICATED"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Your session has expired.",
          body: "Please, login again."
        });

        navigate("/login", { replace: true });

        // If not, refresh global state with fresh data.
      } else {
        return result;
      }

      return result;
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to retrieve your data. Please, try again. If the error persist, contact us.",
      });
    }
  };

  return useMutation(handleInitPayout);
};

export default useInitPayout;
