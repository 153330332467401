/* eslint-disable no-undef */
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { EVENT_CODE } from "../../../config/analytics.config";
import { isDeveloperMode } from "../../../store/global.atoms";
import {
  LOGIN,
  SIGNUP,
  GENERIC_ERROR,
  DOMAIN,
} from "../../../services/gqlQueries";
import { loginQuery } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";
import { signinData } from "../store/auth.store";
import { trackEvent } from "../../../utils/analytics";
import { useTurinLabsReCaptcha } from "../../../components/recaptcha";
import LoadingSpinner from "../../../assets/LoadingSpinner";

// const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_WEBSIDE_KEY;

/* COMPONENT
========================================================= */

const LoginForm = () => {
  const queryClient = useQueryClient();

  const { getToken } = useTurinLabsReCaptcha();

  /* STATE
========================================================= */
  const setNewUserCredentials = useSetRecoilState(signinData);
  const setCalloutError = useSetRecoilState(showCallout);
  const setDeveloperMode = useSetRecoilState(isDeveloperMode);

  /* PRIVATE METHODS
========================================================= */

  const navigate = useNavigate();

  const handleLogin = useMutation(loginQuery, {
    onSuccess: (data) => {
      if (data.sendAuthCodeWithSecret.__typename === GENERIC_ERROR) {
        setCalloutError({
          show: true,
          type: 3,
          title: data.sendAuthCodeWithSecret.message,
          body: data.sendAuthCodeWithSecret.description,
        });
      } else {
        navigate("/confirm-login");
      }
    },
    onError: () =>
      setCalloutError({
        show: true,
        type: 3,
        title: "Something went wrong",
        body: "There was an error when trying to signing you up. Please, try again.",
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    trackEvent(EVENT_CODE.LOGIN);

    const inputData = {
      input: {
        email: data.email,
        secret: data.password,
        type: LOGIN,
        domainApp: DOMAIN,
      },
    };
    const googleReCaptchaToken = await getToken();

    // Call mutate on react-query mutation.
    handleLogin.mutate({
      query: SIGNUP,
      variables: inputData,
      googleReCaptchaToken,
    });
    setNewUserCredentials(data);
  };

  useEffect(() => {
    // Clean cache
    queryClient.clear();

    // Clean global state
    setDeveloperMode(false);
  }, [queryClient, setDeveloperMode]);

  /* RENDERING
  ========================================================= */

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="py-8 bg-white shadow rounded-xl">
        <div className="max-w-md mb-6">
          <h3 className="px-10 pb-6 font-bold text-left text-gray-900 text-3x1">
            Log in to your turinpay account
          </h3>
          <hr />
        </div>
        <form className="px-10 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                inputMode="email"
                autoComplete="email"
                className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm appearance-none focus:outline-none focus:ring-lightEucalyptus focus:border-darkEucalyptus"
                placeholder="satoshi@nakamoto.com"
                data-testid="login-input"
                {...register("email", { required: true })}
              />
              {errors.email?.type === "required" && (
                <p className="mt-2 text-sm font-bold text-red-700">
                  Please, type your email
                </p>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                inputMode="password"
                autoComplete="current-password"
                className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm appearance-none focus:outline-none focus:ring-lightEucalyptus focus:border-darkEucalyptus"
                placeholder="******"
                {...register("password", {
                  required: true,
                  // pattern: PASSWORD_REGEX
                })}
              />
              {errors.password?.type === "required" && (
                <p className="mt-2 text-sm font-bold text-red-700">
                  Please, type your password
                </p>
              )}
              {errors.password?.type === "pattern" && (
                <p className="mt-2 text-sm font-bold text-red-700">
                  Password has to have 8 characters, 1 uppercase letter, 1
                  lowercase letter and 1 number.
                </p>
              )}
              <Link to="/forgot-password">
                <p className="block mt-4 text-sm font-medium text-darkEucalyptus">
                  I forgot my password
                </p>
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              id="login-button"
              className="flex items-center justify-center px-4 py-2 mx-auto font-medium text-white border border-transparent shadow rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus disabled:opacity-75"
              disabled={handleLogin.isLoading}
            >
              {handleLogin.isLoading && <LoadingSpinner />}
              Login
            </button>
          </div>
        </form>
      </div>

      <div className="flex flex-row justify-center mt-6">
        <p className="text-sm font-medium">
          Don't have an account?
          <Link
            to="/signup"
            className="text-eucalyptus"
            data-testid="signup-link"
          >
            {" "}
            Sign up here
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default LoginForm;
