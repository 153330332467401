import { DateTime } from "luxon";
import { currencyConstants, timeIntervalConstants } from "../routes/HomeLayout/helpers/homeLayout.helpers";

// Parse date according to spec
export const parseDate = (date) => {
  return DateTime.fromISO(date).toLocaleString({
    weekday: "short",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

// Return currency symbol according to selected currency
export const handleCurrencyLetter = (currency) => {
  if (currency === currencyConstants.usd) return "$";
  if (currency === currencyConstants.eur) return "€";
  if (currency === currencyConstants.btc) return "B";
  if (currency === currencyConstants.sats) return "S";
};

export const handlePeriodLetter = (period) => {
  if (period === timeIntervalConstants.hour) return "H";
  if (period === timeIntervalConstants.day) return "D";
  if (period === timeIntervalConstants.week) return "W";
  if (period === timeIntervalConstants.month) return "M";
  if (period === timeIntervalConstants.year) return "Y";
  if (period === timeIntervalConstants.all) return "A";
};

// Get environment to attack according to user desire
export const getEnvironment = (isDeveloperMode) => {
  if (isDeveloperMode) {
    return process.env.REACT_APP_SANDBOX;
  } else {
    return process.env.REACT_APP_PRODUCTION;
  }
};
