import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Chart from "../../../components/chart/Chart";
import { handleCurrencyLetter } from "../../../helpers/common.helpers";
import { navigation } from "../../../store/global.atoms";
import { locations } from "../helpers/homeLayout.helpers";
import BalanceIndicator from "./BalanceIndicator";
import CurrencySelector from "./CurrencySelector";
import PeriodSelector from "./PeriodSelector";

const ChartPanel = ({
  revenue,
  period,
  changePeriod,
  currency,
  changeCurrency,
  aggregatedBalance,
}) => {
  const [elementsInChart, setElementsInChart] = useState(0);
  const whereUserIs = useRecoilValue(navigation);

  useEffect(() => {
    // Check if there are no elements to show in chart
    const checkIfEmpty = () => {
      if (
        !revenue.isSuccess ||
        revenue.data?.getTPayEvolution?.items === undefined
      )
        return;

      if (
        revenue.isSuccess &&
        revenue.data.getTPayEvolution.items.length !== 0
      ) {
        const itemsLength = revenue.data.getTPayEvolution.items.length;
        const accumulatedSales =
          revenue.data.getTPayEvolution.items[itemsLength - 1].accumulatedSales;

        return accumulatedSales;
      }
    };

    const accumulatedSales = checkIfEmpty();

    setElementsInChart(accumulatedSales);
  }, [revenue]);

  if (revenue.isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-96">
        <p className="text-4xl font-bold text-slate-300 animate-pulse">
          Loading
        </p>
      </div>
    );
  } else if (!revenue || !revenue.data) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p className="text-4xl font-bold text-darkEucalyptus">No data yet</p>
      </div>
    );
  } else {
    return (
      <div className="w-full py-6 bg-white rounded-lg shadow h-96 sm:p-6">
        <div className="flex items-center justify-between w-full h-auto px-10">
          {revenue && revenue.isLoading ? (
            <div className="w-24 h-6 bg-slate-300 rounded-xl animate-pulse"></div>
          ) : revenue && revenue.isSuccess && whereUserIs === locations.home ? (
            <BalanceIndicator currency={currency} revenue={revenue} />
          ) : (
            <h3 className="text-xl md:text-3xl font-bold text-darkEucalyptus">
              {handleCurrencyLetter(currency)}{" "}
              {aggregatedBalance.isSuccess &&
                aggregatedBalance.data &&
                aggregatedBalance.data.getTPayBalance.balance}
            </h3>
          )}
          <div className="flex items-center justify-start w-auto">
            <CurrencySelector
              currency={currency}
              handleClick={changeCurrency}
            />
            <PeriodSelector handleClick={changePeriod} period={period} />
          </div>
        </div>
        {revenue.isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <p className="text-4xl font-bold text-slate-300 animate-pulse">
              Loading
            </p>
          </div>
        ) : revenue &&
          revenue.isSuccess &&
          revenue.data &&
          revenue.data.getTPayEvolution &&
          revenue.data.getTPayEvolution.items ? (
          <div className="mr-10 w-full h-full flex justify-center items-center pb-10">
            <Chart
              revenueData={revenue.data.getTPayEvolution.items}
              elementsInChart={elementsInChart}
              currency={currency}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <p className="text-4xl font-bold text-darkEucalyptus">
              No data yet
            </p>
          </div>
        )}
      </div>
    );
  }
};

export default ChartPanel;
