import { PrimaryButton } from "../../../components/ui/PrimaryButton";

const TxListEmpty = ({ userData }) => {
  return (
    <section className="mt-10 lg:col-span-9 xl:col-span-10">
      <div className="flex flex-col items-center justify-start w-full h-auto">
        <div className="flex flex-col items-center justify-start w-full h-auto max-w-2xl">
          <h1 className="w-full h-auto text-2xl font-bold text-left text-darkEucalyptus">
            Integrate turinpay with your API Key to start collecting data:
          </h1>
          <p className="w-full mt-10 font-bold text-left">
            Production API key:
          </p>
          <div className="w-full h-auto p-4 mt-2 bg-gray-200 rounded-lg">
            <p className="w-full font-mono font-bold text-left text-gray-600">
              {userData.isSuccess &&
                userData.data?.me &&
                userData.data.me.apiKeys.sk}
            </p>
          </div>
          <p className="w-full mt-5 font-bold text-left">
            Development API key:
          </p>
          <div className="w-full h-auto p-4 mt-2 bg-gray-200 rounded-lg">
            <p className="w-full font-mono font-bold text-left text-gray-600">
              {userData.isSuccess &&
                userData.data?.me &&
                userData.data.me.apiKeys.sktest}
            </p>
          </div>
          <PrimaryButton
            copy="SEE API DOCUMENTATION"
            className="w-full mt-4"
            onClick={() => window.open(process.env.REACT_APP_DOCUMENTATION_URL)}
          />
          <PrimaryButton
            copy="CONTACT US"
            className="w-full mt-4"
            onClick={() => window.open(`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`)}
          />
        </div>
      </div>
    </section>
  );
};

export default TxListEmpty;
