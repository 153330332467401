export const REG_EXP = {
  cif: /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/,
  nifNie: /^((\d{8})([a-zA-Z]))|([xyzXYZ]\d{7,8}[a-zA-Z])$/,
  phonePrefix: /^\+(?:[\d]{1,3})$/,
  phoneNumber: /^((\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  zipcode: /^\d{5}(?:[-\s]\d{4})?$/,
}

const MIN_ADULT_AGE = 18
export const isAdult = (date, minAge = MIN_ADULT_AGE) => {
  if (!date) return
  const today = new Date()
  let diff = (today.getTime() - new Date(date).getTime()) / 1000
  diff /= 60 * 60 * 24
  return Math.abs(diff / 365.25) >= minAge
}

export const isPropertyMedia = (data) =>
  !!data && ((data.length && data[0].hasOwnProperty("keyS3")) || data.hasOwnProperty("keyS3"))

export const MAX_FILE_SIZE = 20 * 1024 * 1024 // 20MB
export const SUPPORTED_FORMATS = [
  "application/pdf",
  "image/gif",
  "image/jpg",
  "image/jpeg",
  "image/svg+xml",
  "image/png",
]
