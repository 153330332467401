import { atom } from 'recoil';

export const signinData = atom({
  key: "signinData",
  default: null
});

export const loggedUserCredentials = atom({
  key: "loggedUserCredentials",
  default: null
});

export const loggedUserData = atom({
  key: "loggedUserData",
  default: null
});