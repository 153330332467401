import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";
import { getPaymentsProfile } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";

const useGetPaymentProfileBySlug = (variables) => {

  const setCalloutMessage = useSetRecoilState(showCallout);


/* DEPENDENCIES
========================================================= */

  const handleGetPaymentProfile = async (variables) => {
    try {

      const result = await getPaymentsProfile(variables);

      // If the API gives an error show a message.
      if (
        result.getTPayPublicPaymentProfileBySlug.__typename &&
        result.getTPayPublicPaymentProfileBySlug.__typename === "GenericError"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Something went wrong.",
          body: "There was an error while trying to perform this operation. Please, try again.",
        });
        return;
      } else {
        return result;
      }

      // If it doesn't work, send to login and show an error message.
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "There was an error while trying to perform this operation. Please, try again.",
      });
    }
  };

  return useQuery(["getPaymentProfileBySlug", variables.input.slug], () => handleGetPaymentProfile(variables), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export default useGetPaymentProfileBySlug;