import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useState } from "react";

import { currencyConstants } from "../HomeLayout/helpers/homeLayout.helpers";
import { DOMAIN } from "../../services/gqlQueries";
import { ErrorMessage } from "@hookform/error-message";
import { getEnvironment } from "../../helpers/common.helpers";
import { isDeveloperMode, navigation } from "../../store/global.atoms";
import { loggedUserCredentials } from "../auth/store/auth.store";
import { SettingsSkeleton } from "./components/SettingsSkeleton";
import PublicPaymentsProfileForm from "./components/PublicPaymentsProfileForm";
import ResetPassword from "../auth/components/ResetPassword";
import useConfirmDeletion from "./hooks/useConfirmDeletion";
import useGetSettings from "./hooks/useGetSettings";
import useGetUserBalance from "../wallet/hooks/useGetUserBalance";
import useInitAccountDeletion from "./hooks/useInitAccountDeletion";
import useModifyEmail from "./hooks/useModifyEmail";
import useModifyPassword from "./hooks/useModifyPassword";
import useModifySettings from "./hooks/useModifySettings";

const DELETE_ACCOUNT = "DELETE";

const Settings = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  // Setter for logged user data
  const setUserCredentials = useSetRecoilState(loggedUserCredentials);
  // Reset navigation
  const setNavigation = useSetRecoilState(navigation);
  // Developer mode
  const developerMode = useRecoilValue(isDeveloperMode);
  // Get credentials from cache
  const userCredentials = queryClient.getQueryData("refreshToken");
  // Get token
  const token = userCredentials?.refreshToken.token;
  // Get user settings
  const userSettings = useGetSettings(token);

  // Forms
  const [settings, setSettings] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    deleteAccountCode: "",
    publicPaymentsEnabled: false,
    publicPaymentsSlug: "",
    publicPaymentsName: "",
    publicPaymentsDescription: "",
  });
  const [validationError, setValidationError] = useState({
    isError: false,
    errorMessage: "",
    field: "",
  });

  // Form to handle profile settings
  const {
    register: registerProfile,
    handleSubmit: handleSubmitProfile,
    reset: resetProfile,
    getValues: getProfileValues,
  } = useForm();

  // Form to handle email settings
  const {
    register: registerEmail,
    formState: { errors: errorsEmail },
    handleSubmit: handleSubmitEmail,
    reset: resetEmail,
    getValues: getEmailValues,
  } = useForm();

  // Form to handle password
  const {
    register: registerPassword,
    formState: { errors: errorsPassword },
    handleSubmit: handleSubmitPassword,
    getValues: getPasswordValues,
  } = useForm();

  // Handle logout
  const handleLogout = () => {
    setUserCredentials(null);
    setNavigation("HOME");
    navigate("/login", { replace: true });
  };

  // Handle several updates
  const modifySettings = useModifySettings({
    token: token,
    variables: {
      input: {
        companyName: getProfileValues("companyName"),
        defaultCurrency: getProfileValues("defaultCurrency"),
      },
    },
  });

  const modifyEmail = useModifyEmail({
    token: token,
    variables: {
      input: {
        oldEmail: getEmailValues("currentEmail"),
        newEmail: getEmailValues("confirmNewEmail"),
        domainApp: DOMAIN,
      },
    },
  });

  // Init delete account
  const initAccountDelete = useInitAccountDeletion(token);

  // Check if user has funds in its wallet
  const userBalance = useGetUserBalance({
    token: token,
    variables: {
      input: {
        currency: "USD",
        executionEnvironment: getEnvironment(developerMode),
      },
    },
  });

  const handleInitAccountDeletion = () => {
    if (!userBalance.isSuccess) return;

    // Warn user in case her wallet still have funds
    if (
      userBalance.isSuccess &&
      userBalance.data.getTPayBalance.balance !== 0
    ) {
      setValidationError({
        isError: true,
        errorMessage: "Your wallet has to be empty to delete your account",
        field: "DELETE",
      });
      return;
    }

    // Init account deletion
    initAccountDelete.mutate();
  };

  const confirmDeletion = useConfirmDeletion({
    token: token,
    variables: {
      input: { code: settings.deleteAccountCode },
    },
  });

  const handleConfirmDeletion = () => {
    confirmDeletion.mutate();
  };

  const modifyPassword = useModifyPassword({
    token: token,
    variables: {
      input: {
        oldSecret: getPasswordValues("currentPassword"),
        newSecret: getPasswordValues("newPassword"),
        domainApp: DOMAIN,
      },
    },
  });

  return (
    <section className="mx-2 lg:col-span-9 xl:col-span-10 lg:mx-10 bg-gray-50">
      <h1 className="sr-only">Settings</h1>
      <div className="divide-y lg:max-w-2xl">
        <div className="mb-10">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            General
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Information related to your company and preferred currency.
          </p>
          {!userSettings?.data ? (
            <SettingsSkeleton fieldsNumber={2} />
          ) : (
            <form
              onSubmit={handleSubmitProfile(() =>
                modifySettings.mutate({
                  onSuccess: () => resetProfile(),
                })
              )}
              className="my-5 sm:col-span-4"
            >
              <label
                htmlFor="company-name"
                className="block text-sm font-medium text-gray-700"
              >
                Company name
              </label>
              <div className="flex flex-col items-start justify-center w-full mt-1">
                <input
                  placeholder={`Current: ${userSettings?.data?.getTPaySettings?.companyName}`}
                  {...registerProfile("companyName", {
                    value: userSettings?.data?.getTPaySettings?.companyName,
                  })}
                  className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
                />
              </div>
              <div className="mt-5 sm:col-span-4">
                <label
                  htmlFor="default-currency"
                  className="block text-sm font-medium text-gray-700"
                >
                  Default currency
                </label>
                <div className="mt-1">
                  <select
                    id="default-currency"
                    name="defaultCurrency"
                    {...registerProfile("defaultCurrency", {
                      value:
                        userSettings?.data?.getTPaySettings?.defaultCurrency,
                    })}
                    defaultValue={
                      userSettings?.data?.getTPaySettings?.defaultCurrency ||
                      currencyConstants.eur
                    }
                    className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
                  >
                    <option value={currencyConstants.eur}>€EUR</option>
                    <option value={currencyConstants.usd}>$USD</option>
                  </select>
                </div>
              </div>
              <button
                type="submit"
                id="save-company-name"
                disabled={modifySettings.isLoading}
                className="flex justify-center px-4 py-2 mt-6 font-medium text-white border border-transparent shadow-sm rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus disabled:bg-lightEucalyptus"
              >
                Save general settings
              </button>
            </form>
          )}
        </div>
        <div className="py-10">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Account
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Account related information.
          </p>
          <form
            onSubmit={handleSubmitEmail(() =>
              modifyEmail.mutate({
                onSuccess: () => resetEmail(),
              })
            )}
          >
            <div className="mt-5 sm:col-span-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Current email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="currentEmail"
                  {...registerEmail("currentEmail")}
                  type="email"
                  placeholder="admin@turinlabs.com"
                  inputMode="email"
                  autoComplete="email"
                  className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-5 sm:col-span-4">
              <label
                htmlFor="new-email"
                className="block text-sm font-medium text-gray-700"
              >
                Set new email
              </label>
              <div className="flex flex-col items-start justify-center w-full mt-1">
                <input
                  id="new-email"
                  name="newEmail"
                  {...registerEmail("newEmail")}
                  type="email"
                  placeholder="hello@turinlabs.com"
                  inputMode="email"
                  className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
                />
                <label
                  htmlFor="confirm-new-email"
                  className="block mt-5 text-sm font-medium text-gray-700"
                >
                  Confirm new email
                </label>
                <input
                  id="confirm-new-email"
                  name="confirmNewEmail"
                  {...registerEmail("confirmNewEmail", {
                    validate: (value) => value === getEmailValues("newEmail"),
                    message: "Emails don't match",
                  })}
                  type="email"
                  placeholder="hello@turinlabs.com"
                  inputMode="email"
                  className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
                />
                <ErrorMessage errors={errorsEmail} />
              </div>
              <button
                id="save-email"
                type="submit"
                className="flex justify-center px-4 py-2 mt-6 font-medium text-white border border-transparent shadow-sm rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus disabled:opacity-75"
              >
                Save new email
              </button>
            </div>
          </form>
          <hr className="my-10" />
          <form
            onSubmit={handleSubmitPassword(() =>
              modifyPassword.mutate({
                onSuccess: () => ResetPassword(),
              })
            )}
            className="mt-5 sm:col-span-4"
          >
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="currentPassword"
                {...registerPassword("currentPassword")}
                type="password"
                placeholder="*******"
                inputMode="password"
                autoComplete="password"
                className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full mt-5">
              <label
                htmlFor="new-password"
                className="block text-sm font-medium text-gray-700"
              >
                Set new password
              </label>
              <input
                id="new-password"
                name="newPassword"
                {...registerPassword("newPassword")}
                type="password"
                placeholder="*******"
                autoComplete="new-password"
                inputMode="password"
                className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
              />
              <label
                htmlFor="confirm-new-email"
                className="block mt-5 text-sm font-medium text-gray-700"
              >
                Confirm new password
              </label>
              <input
                id="confirm-new-password"
                name="confirmNewPassword"
                {...registerPassword("confirmNewPassword", {
                  validate: (value) =>
                    value === getPasswordValues("newPassword"),
                  message: "Passwords don't match",
                })}
                type="password"
                placeholder="*******"
                inputMode="password"
                className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
              />
              <button
                id="save-new-password"
                type="submit"
                className="flex justify-center px-4 py-2 mt-6 font-medium text-white border border-transparent shadow-sm rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus"
              >
                Save new password
              </button>
            </div>
          </form>
        </div>

        <div className="pt-10">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Delete account
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Caution: This action cannot be undone. You need to empty your wallet
            first to initiate the account deletion process.
          </p>
        </div>
        <button
          id="delete-account"
          className="flex justify-center px-4 py-2 mt-5 mb-10 font-medium text-white bg-red-600 border border-transparent shadow-sm rounded-xl hover:bg-red-800"
          disabled={!userBalance.isSuccess || initAccountDelete.isLoading}
          onClick={() => handleInitAccountDeletion()}
        >
          Delete account
        </button>
        {validationError.isError &&
          validationError.field === DELETE_ACCOUNT && (
            <p className="mt-2 text-red-600">{validationError.message}</p>
          )}

        {initAccountDelete.isSuccess &&
          initAccountDelete.data &&
          initAccountDelete.data.initTPayAccountDeletion.result && (
            <>
              <input
                id="delete-account-code"
                name="deleteAccountCode"
                value={settings.deleteAccountCode}
                type="number"
                placeholder="I.E. 123456"
                inputMode="number"
                className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:border-darkEucalyptus sm:text-sm focus:ring-offset-2 focus:ring-lightEucalyptus"
              />
              <button
                id="delete-account"
                className="flex justify-center my-10 font-medium text-red-600 bg-transparent border border-transparent hover:text-red-800"
                disabled={!userBalance.isSuccess || initAccountDelete.isLoading}
                onClick={() => handleConfirmDeletion()}
              >
                Confirm account deletion
              </button>
            </>
          )}
        <div className="pt-10">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Log out
          </h3>
          <p className="mt-1 text-sm text-gray-500">Close current session</p>
        </div>
        <button
          id="logout"
          className="flex justify-center px-4 py-2 my-5 text-white border border-transparent shadow-sm fon5-medium mt-t bg-eucalyptus rounded-xl hover:bg-darkEucalyptus"
          onClick={() => handleLogout()}
        >
          Logout
        </button>
      </div>
    </section>
  );
};

export default Settings;
