import { CalendarIcon } from "@heroicons/react/outline";
import { handleCurrencyLetter, parseDate } from "../../../helpers/common.helpers";
import { currencyConstants } from "../helpers/homeLayout.helpers";

const PayoutElement = ({ transaction, handleClick, currency }) => {

  return (
    <div
      key={transaction.transactionId}
      className="cursor-pointer hover:bg-gray-100"
      onClick={() => handleClick(transaction)}
      role="button"
    >
      <div className="flex items-center py-4">
        <div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
          <div className="truncate">
            <div className="flex text-sm">
              <p className="text-lg font-medium truncate text-darkEucalyptus">
                {transaction.description}
              </p>
            </div>
            <div className="flex mt-2">
              <div className="flex items-center text-sm text-gray-500">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <p>
                  <time dateTime={transaction.date}>
                    {parseDate(transaction.date)}
                  </time>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 ml-5">
          <p className="text-lg font-bold text-black">
            {handleCurrencyLetter(currency)}{" "}
            {transaction.currency === currencyConstants.btc && Math.trunc(transaction.amount * Math.pow(10, 8)) / Math.pow(10, 8)}
            {Math.trunc(transaction.amount * Math.pow(10, 2)) / Math.pow(10, 2)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PayoutElement;
