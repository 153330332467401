import { useState } from "react";
import LoadingSpinner from "../../../../assets/LoadingSpinner";

const WizardStep1 = ({ wizardData }) => {
  const {
    invoiceString,
    setInvoiceString,
    setShowPayoutsWizard,
    setStep,
    step,
    invoiceData,
    initPayout,
  } = wizardData;

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setInvoiceString(event.target.value);
  };

  const handleAdvanceStep = async () => {
    // Prevent double click
    if (isLoading) return;

    setIsLoading(true);

    await invoiceData.refetch();
    await initPayout.mutate();

    setIsLoading(false);

    setStep(step + 1);
  };

  return (
    <section className="flex flex-col items-center justify-start my-10 lg:col-span-9 xl:col-span-10">
      <div className="flex flex-col items-center justify-start w-full max-w-3xl">
        <div className="flex flex-col items-center justify-start w-full mb-10">
          <h3 className="w-full text-3xl font-bold text-center text-darkEucalyptus">
            Withdraw funds
          </h3>
          <p className="text-gray-600">
            Create an invoice with the amount you want to withdraw and paste it
            here.
          </p>
        </div>
        <div className="flex flex-col items-center justify-start w-full mb-10 sm:max-w-xl">
          <label
            htmlFor="invoice-string"
            className="w-full mb-2 text-xs font-bold text-left"
          >
            INVOICE
          </label>
          <input
            type="text"
            placeholder="3Bac...72aX"
            name="invoice-string"
            value={invoiceString}
            onChange={handleChange}
            className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-darkEucalyptus focus:border-darkEucalyptus focus:ring-offset-2 sm:text-sm"
          />
        </div>
        <div className="flex items-center justify-center sm:max-w-xl">
          <button
            className="inline-flex justify-center w-full px-4 py-2 text-base font-bold text-white border border-transparent rounded-md shadow-sm bg-lightEucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-lightEucalyptus focus:ring-offset-2 sm:text-sm"
            onClick={() => setShowPayoutsWizard(false)}
          >
            CANCEL
          </button>
          {/* Disable if no invoice */}
          <button
            className="inline-flex justify-center w-full px-4 py-2 ml-4 text-base font-bold text-white border border-transparent rounded-md shadow-sm bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus sm:text-sm"
            onClick={() => handleAdvanceStep()}
          >
            { isLoading && <LoadingSpinner /> }
            NEXT
          </button>
        </div>
      </div>
    </section>
  );
};

export default WizardStep1;
