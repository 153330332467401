import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import LoadingSpinner from "../../../assets/LoadingSpinner";
import { useTurinLabsReCaptcha } from "../../../components/recaptcha";
import {
  CONFIRM_SIGNUP,
  DOMAIN,
  GENERIC_ERROR,
  REGISTER,
  RESEND_AUTH_CODE,
} from "../../../services/gqlQueries";
import { mutateData } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";
import { emailTrimmer } from "../../../utils/helpers";
import { loggedUserCredentials, loggedUserData } from "../store/auth.store";

const ConfirmSignupForm = () => {
  /* STATE 
  ========================================================= */
  const setCalloutMessage = useSetRecoilState(showCallout);
  const setAuthPayload = useSetRecoilState(loggedUserCredentials);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const newUserCredentials = useRecoilValue(loggedUserData);

  const navigate = useNavigate();

  // HandleSubmit Mutation
  const handleSubmitMutation = useMutation(mutateData, {
    onSuccess: (data) => {
      if (data.__typename === GENERIC_ERROR) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: data.message,
          body: data.description,
        });
      } else {
        setCalloutMessage({
          show: true,
          type: 1,
          title: "Account created successfully!",
          body: "You can now login with your credentials.",
        });
        navigate("/login");
        setAuthPayload(null);
      }
    },
  });

  const { getToken } = useTurinLabsReCaptcha();

  const onSubmit = async (formData) => {
    const newUserData = {
      input: {
        email: newUserCredentials.email,
        authCode: { mode: "EMAIL", code: formData.emailCode },
        domainApp: DOMAIN,
      },
    };
    const googleReCaptchaToken = await getToken();
    handleSubmitMutation.mutate({
      query: CONFIRM_SIGNUP,
      variables: newUserData,
      googleReCaptchaToken,
    });
  };

  const resendEmailQueryData = {
    query: RESEND_AUTH_CODE,
    variables: {
      input: {
        email: newUserCredentials.email,
        type: REGISTER,
        domainApp: "TurinPay",
      },
    },
    googleReCaptchaToken: null,
  };

  const resendEmailCodeQuery = useQuery(
    ["emailAuthCode", resendEmailQueryData],
    async () => {
      resendEmailQueryData.googleReCaptchaToken = await getToken();
      return mutateData(resendEmailQueryData);
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      manual: true,
      retry: false,
      onError: (error) => {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Code successfully sent",
          body: "A new code has been sent to the email you provided. Check it out and enter it below.",
        });
      },
      onSuccess: (data) => {
        if (data.reSendAuthCode.__typename === "GenericError") {
          setCalloutMessage({
            show: true,
            type: 3,
            title: "Something went wrong",
            body: "An error ocurring when trying to resend you a new code. Please, try again.",
          });
        } else {
          setCalloutMessage({
            show: true,
            type: 1,
            title: "Code successfully sent",
            body: "A new code has been sent to the email/phone you provided. Check it out and enter it below.",
          });
        }
      },
    }
  );

  const handleResendCode = () => {
    resendEmailCodeQuery.refetch();
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="py-8 bg-white shadow rounded-xl">
        <div className="max-w-md mb-6">
          <h3 className="px-10 pb-2 font-bold text-left text-gray-900 text-3x1">
            Security Verification
          </h3>
          <p className="px-10 pb-6 text-sm font-medium text-left text-gray-500">
            Enter the code sent to the email you provided.
          </p>
          <hr />
        </div>
        <form className="px-10 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Code
            </label>
            <div className="flex flex-row mt-1 space-between">
              <input
                id="email"
                name="emailCode"
                type="text"
                inputMode="numeric"
                className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:eucalyptus"
                placeholder="******"
                maxLength={6}
                data-testid="signup-email-code"
                {...register("emailCode", { required: true })}
              />
              <button
                className="w-3/6 px-2 ml-2 text-sm font-medium bg-transparent border-2 rounded-lg border-eucalyptus text-eucalyptus focus:outline-none hover:bg-eucalyptus hover:border-transparent hover:text-white"
                type="button"
                onClick={() => handleResendCode()}
              >
                Resend code
              </button>
            </div>
            <p className="mt-1 text-sm font-medium text-left text-gray-500">
              Code sent to {emailTrimmer(newUserCredentials.email)}
            </p>
            {errors.emailCode?.type === "required" && (
              <p className="text-sm font-bold text-red-700">
                Please, type the code you received.
              </p>
            )}
          </div>

          <div>
            <button
              type="submit"
              className="flex justify-center px-4 py-2 mx-auto font-medium text-white border border-transparent shadow-sm rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus disabled:opacity-75"
              data-testid="confirm-signup-cta"
              disabled={handleSubmitMutation.isLoading}
            >
              {handleSubmitMutation.isLoading && <LoadingSpinner />}
              Confirm
            </button>
          </div>
        </form>
      </div>

      <div className="flex flex-row justify-center mt-6">
        <p className="text-sm font-medium">
          Already registered?{" "}
          <Link to="/login" className="text-eucalyptus">
            Sign in here.
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default ConfirmSignupForm;
