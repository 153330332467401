import {
  CHANGE_PASSWORD,
  CONFIRM_ACCOUNT_DELETION,
  CONFIRM_PAYOUT,
  CREATE_PI_BY_SLUG,
  GET_EVOLUTION,
  GET_KYC,
  GET_PAIR_RATES,
  GET_PAYMENT_PROFILE_BY_SLUG,
  GET_PRESIGNED_POST,
  GET_PUBLIC_PAYMENT,
  GET_SETTINGS,
  GET_TPAY_INTENTS,
  GET_TX_HISTORY,
  GET_WALLET_BALANCE,
  INIT_ACCOUNT_DELETION,
  INIT_PASSWORD_RECOVERY,
  INIT_PAYOUT,
  ME,
  MODIFY_EMAIL,
  MODIFY_PASSWORD_RECOVERY,
  MODIFY_PASSWORD_SETTINGS,
  MODIFY_SETTINGS,
  REFRESH_TOKEN,
  UPDATE_KYC,
  REQUEST_KYC,
  UPDATE_PUBLIC_PAYMENT,
  UPDATE_SETTINGS,
  VALIDATE_INVOICE,
  GET_TPAY_KYC_IS_PASSED,
} from "./gqlQueries";
import { fetcher } from "./gqlGateway";

/* USE CASES
========================================================= */
// Hook for mutations (test)

export const mutateData = async (data) => {
  const response = await fetcher(
    data.token,
    data.auth,
    data.query,
    data.variables,
    null,
    data.googleReCaptchaToken ?? null
  );
  return response;
};

// Auth/User
export const loginQuery = async (data) => {
  return await fetcher(
    data.token,
    null,
    data.query,
    data.variables,
    null,
    data.googleReCaptchaToken
  );
};

export const confirmLoginQuery = async (data) => {
  return await fetcher(data.token, null, data.query, data.variables);
};

export const refreshTokenQuery = async (data) => {
  return await fetcher(data.token, data.refreshToken, REFRESH_TOKEN, null);
};

export const meQuery = async (token) => {
  return await fetcher(token, null, ME);
};

export const getSettingsQuery = async (token) => {
  return await fetcher(token, null, GET_SETTINGS);
};

export const updateSettings = async (token, variables) => {
  return await fetcher(token, null, UPDATE_SETTINGS, variables);
};

export const getKycUC = async (token) => {
  return await fetcher(token, null, GET_KYC, null);
};

export const updateKyc = async ({ token, variables }) => {
  return await fetcher(token, null, UPDATE_KYC, variables);
};

export const requestKyc = async ({ token, variables }) => {
  return await fetcher(token, null, REQUEST_KYC, variables);
};

export const requestPresignedPost = async (variables) => {
  return await fetcher(null, null, GET_PRESIGNED_POST, variables);
};

export const getUserWalletBalance = async (data) => {
  return await fetcher(data.token, null, GET_WALLET_BALANCE, data.variables);
};

export const changePassword = async (data) => {
  return await fetcher(data.token, null, CHANGE_PASSWORD, data.variables);
};

export const initPasswordRecovery = async (data) => {
  return await fetcher(
    null,
    null,
    INIT_PASSWORD_RECOVERY,
    data.variables,
    null,
    data.googleReCaptchaToken
  );
};

export const modifySecretRecovery = async (variables, googleReCaptchaToken) => {
  return await fetcher(
    null,
    null,
    MODIFY_PASSWORD_RECOVERY,
    variables,
    null,
    googleReCaptchaToken
  );
};

export const modifySettings = async (data) => {
  return await fetcher(data.token, null, MODIFY_SETTINGS, data.variables);
};

export const initAccountDeletion = async (token) => {
  return await fetcher(token, null, INIT_ACCOUNT_DELETION, null);
};

export const confirmAccountDeletion = async (data) => {
  return await fetcher(
    data.token,
    null,
    CONFIRM_ACCOUNT_DELETION,
    data.variables
  );
};

export const modifyEmail = async (data) => {
  return await fetcher(data.token, null, MODIFY_EMAIL, data.variables);
};

export const modifyPassword = async (data) => {
  return await fetcher(
    data.token,
    null,
    MODIFY_PASSWORD_SETTINGS,
    data.variables
  );
};

// Payments
export const getTransactionsHistory = async (data) => {
  return await fetcher(data.token, null, GET_TX_HISTORY, data.variables);
};

export const getPayoutsHistory = async (data) => {
  return await fetcher(data.token, null, GET_TX_HISTORY, data.variables);
};

// Revenue
export const getRevenue = async (data) => {
  return await fetcher(data.token, null, GET_EVOLUTION, data.variables);
};

// Intents
export const getPaymentIntents = async (data) => {
  return await fetcher(
    data.token,
    null,
    GET_TPAY_INTENTS,
    data.variables,
    data.apiKey
  );
};

// Payouts
export const validateInvoice = async (data) => {
  return await fetcher(data.token, null, VALIDATE_INVOICE, data.variables);
};

export const confirmPayout = async (data) => {
  return await fetcher(data.token, null, CONFIRM_PAYOUT, data.variables);
};

export const initPayout = async (data) => {
  return await fetcher(data.token, null, INIT_PAYOUT, data.variables);
};

export const getPairRates = async () => {
  return await fetcher(null, null, GET_PAIR_RATES, null);
};

// Public payments
export const createPI = async (data) => {
  const result = await fetcher(
    null,
    null,
    CREATE_PI_BY_SLUG,
    data.variables,
    null,
    data.googleReCaptchaToken
  );
  return result;
};

export const getPublicPayments = async (token) => {
  return await fetcher(token, null, GET_PUBLIC_PAYMENT, null);
};

export const updatePublicPayments = async (data) => {
  return await fetcher(data.token, null, UPDATE_PUBLIC_PAYMENT, data.variables);
};

export const getPaymentsProfile = async (variables) => {
  return await fetcher(null, null, GET_PAYMENT_PROFILE_BY_SLUG, variables);
};

export const getTPayKYCIsPassed = async (variables) => {
  return await fetcher(null, null, GET_TPAY_KYC_IS_PASSED, variables);
};
