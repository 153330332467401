export const TooltipPlace = {
  TOP: "top",
  RIGHT: "right",
  BOTTOM: "bottom",
  LEFT: "left",
  DEFAULT: "right",
};

export const TooltipType = {
  DARK: "dark",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  INFO: "info",
  LIGHT: "light",
  DEFAULT: "dark",
};

export const TooltipEffect = {
  FLOAT: "float",
  SOLID: "solid",
  DEFAULT: "float",
};
