import { useMutation } from "react-query";
import { useSetRecoilState } from "recoil";
import { confirmPayout } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";

/* CONSTANTS
========================================================= */

const useConfirmPayout = (data) => {
  /* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);

  const handleConfirmPayout = async () => {
    try {
      if (!data) return;

      const result = await confirmPayout(data);

      // If the API gives an error, redirect to login and show a message.
      if (
        result.payTPayInvoice.__typename &&
        result.payTPayInvoice.__typename === "GenericError" &&
        result.payTPayInvoice.code === "ERROR_USER_NOT_AUTHENTICATED"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Unable to get your user settings",
          body: "Please, refresh this page and try again.",
        });
      } else if (
        result.payTPayInvoice.__typename &&
        result.payTPayInvoice.__typename === "GenericError" &&
        result.payTPayInvoice.code === "ERROR_NOT_ENOUGH_FUNDS_IN_YOUR_WALLET"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Unable to perform withdrawal",
          body: "You don't have enough funds in your wallet",
        });
        throw new Error();
      } else {
        return result;
      }
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to retrieve your data. Please, try again. If the error persist, contact us.",
      });
    }
  };

  return useMutation(handleConfirmPayout);
};

export default useConfirmPayout;
