import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

import turinpayImagotype from "../../assets/turinpay_imagotype_final.svg";

const PublicProfileNotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/", { replace: true });
  };

  return (
    <main className="w-full h-screen flex items-center justify-center p-8 bg-gray-100">
      <div className="flex flex-col items-center text-center gap-16 bg-white w-full max-w-md m-4 px-4 py-20">
        <img src={turinpayImagotype} alt="Turinpay" className="w-32" />
        <p className="text-2xl uppercase">
          Public payment <span className="font-bold">not found</span>{" "}
        </p>

        <button
          type="button"
          onClick={handleGoBack}
          className="bg-darkEucalyptus text-white text-xl px-4 py-2 flex items-center gap-2 rounded shadow"
        >
          <ArrowLeftIcon className="w-6" />
          <span>Go back</span>
        </button>
      </div>
    </main>
  );
};

export default PublicProfileNotFound;
