import { timeIntervalConstants } from "../helpers/homeLayout.helpers";

const PeriodSelector = ({ handleClick, period }) => {

  return (
    <select 
      className="block py-2 pl-3 pr-10 mt-1 text-xs md:text-SM font-bold border-2 rounded-lg cursor-pointer lg:w-32 w-fit border-eucalyptus focus:outline-none focus:ring-eucalyptus focus:border-eucalyptus sm:text-sm text-eucalyptus hover:bg-eucalyptus hover:text-white"
      value={period}
      onChange={(e) => handleClick(e.target.value)}
    >
      <option key="HOUR" value={timeIntervalConstants.hour}>HOUR</option>
      <option key="DAY" value={timeIntervalConstants.day}>DAY</option>
      <option key="WEEK" value={timeIntervalConstants.week}>WEEK</option>
      <option key="MONTH" value={timeIntervalConstants.month}>MONTH</option>
      <option key="YEAR" value={timeIntervalConstants.year}>YEAR</option>
      <option key="ALL" value={timeIntervalConstants.all}>ALL</option>
    </select>
  );
};

export default PeriodSelector;
