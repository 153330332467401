import { atom } from 'recoil';

// 1) Home, 2) Wallet, 3) Settings, 4) Payment intents
const navigation = atom({
  key: 'navigation',
  default: 'HOME'
});

// Developer mode
const isDeveloperMode = atom({
  key: "isDeveloperMode",
  default: true
});

export { navigation, isDeveloperMode };