import { GraphQLClient } from "graphql-request";
import { getBackendEndpoint } from "../helpers/environment.helper";

export const fetcher = async (
  token = null,
  auth = null,
  query,
  variables = null,
  apiKey = null,
  googleCaptchaToken = null
) => {
  const response = await gqlClient(
    token,
    auth,
    apiKey,
    googleCaptchaToken
  ).request(query, variables);
  return response;
};

export const gqlClient = (
  token,
  auth,
  apiKey = null,
  googleCaptchaToken = null
) => {
  if (apiKey !== null) {
    const client = new GraphQLClient(getBackendEndpoint(), {
      credentials: "same-origin",
      headers: {
        authorization: `Bearer ${token}`,
        apiKey: `${apiKey}`,
        "x-auth-gc": googleCaptchaToken,
      },
    });
    return client;
  } else if (token !== null && auth !== null) {
    const client = new GraphQLClient(getBackendEndpoint(), {
      credentials: "same-origin",
      headers: {
        authorization: `Bearer ${token}`,
        auth_rt: `${auth}`,
        "x-auth-gc": googleCaptchaToken,
      },
    });
    return client;
  } else if (token !== null) {
    const client = new GraphQLClient(getBackendEndpoint(), {
      credentials: "same-origin",
      headers: {
        authorization: `Bearer ${token}`,
        "x-auth-gc": googleCaptchaToken,
      },
    });
    return client;
  } else {
    const client = new GraphQLClient(getBackendEndpoint(), {
      credentials: "same-origin",
      headers: {
        "x-auth-gc": googleCaptchaToken,
      },
    });
    return client;
  }
};
