export const KEYS = {
  PAYMENTS: {
    ENABLED: "enabled",
    SLUG: "slug",
    NAME: "name",
    DESCRIPTION: "description",
    PROFILE_PICTURE: "profilePicture",
    COVER_PICTURE: "coverPicture",
  },
};

export const getBucketForPublicPayments = () => `public-payments`;
