import { s3Upload } from "./s3";
import { uuidv4 } from "./uuid";

const s3BucketName =
  process.env.REACT_APP_PUBLIC_BUCKET || "turinlabs-library-integration";
const cloudfrontDomain =
  process.env.REACT_APP_PUBLIC_CDN_URI ||
  "https://d1prtpotxpkq03.cloudfront.net";

export const uploadFile = async (
  file: File,
  folder: string | null,
  propertyMedia = {},
  userId: string
) => {
  const responseS3 = await s3Upload({ file, folder, userId });
  const fileName = responseS3.url.split("/").pop();

  const propertyMediaBase = {
    id: uuidv4(),
    name: fileName,
    type: file.type,
    size: file.size,
    keyS3: responseS3.keyS3,
    url: responseS3.url,
  };

  return { ...propertyMediaBase, ...propertyMedia };
};

export const getCloudfrontURL = (url: string) => {
  if (!url || url.indexOf(s3BucketName) < 0) {
    return url;
  }
  const fileName = url.split(s3BucketName).pop();
  return `${cloudfrontDomain}${fileName}`;
};
