import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { validateInvoice } from '../../../services/useCases';
import { showCallout } from '../../../store/callout.atoms';

/* CONSTANTS
========================================================= */

const useValidateInvoice = (data) => {

	/* DEPENDENCIES
========================================================= */

	const setCalloutMessage = useSetRecoilState(showCallout);

	const handleValidateInvoice = async () => {
		try {
			if (!data) return;
			
			const result = await validateInvoice(data);

			return result;
		} catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to retrieve your data. Please, try again. If the error persist, contact us.",
      });
    }
	};

	return useQuery(
		['validateInvoice', data],
		() => handleValidateInvoice(),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: true,
			staleTime: Infinity,
			cacheTime: Infinity
		}
	);
};

export default useValidateInvoice;