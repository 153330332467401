import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "../../../components/button";
import { EVENT_CODE } from "../../../config/analytics.config";
import { getBucketForKYC, KEYS, KYC_TYPE, SELECT_OPTIONS } from "./config";
import {
  FormControl,
  FormError,
  FormInput,
  FormInputDate,
  FormInputFile,
  FormLabel,
  FormRadioGroup,
} from "../../../components/form";
import { isPropertyMedia } from "../../../config/validations";
import { SCHEMA_BUSINESS } from "./schema";
import { trackEvent } from "../../../utils/analytics";
import { uploadFile } from "../../../utils/propertyMedia";
import styles from "./KYCForm.module.css";

export const KYCBusinessForm = ({ kyc, onSubmit: saveKYC = () => {} }) => {
  const [uploadFilesError, setUploadFilesError] = useState(null);

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(SCHEMA_BUSINESS),
    defaultValues: {
      // Business
      [KEYS.COMPANY_NAME]: kyc.company?.name,
      [KEYS.COMPANY_REGISTRY]: kyc.company?.registry,
      [KEYS.COMPANY_LEGAL_FORM]: kyc.company?.legalForm,
      [KEYS.COMPANY_ADDRESS]: kyc.company?.address,
      [KEYS.COMPANY_CITY]: kyc.company?.city,
      [KEYS.COMPANY_ZIP]: kyc.company?.zip,
      [KEYS.COMPANY_JURISDICTION]: kyc.company?.jurisdiction,
      // Representative
      [KEYS.NAME]: kyc.personal?.name,
      [KEYS.SURNAME]: kyc.personal?.surname,
      [KEYS.DOCUMENT_ID]: kyc.personal?.documentId,
      [KEYS.BIRTH]: kyc.personal?.birth
        ? new Date(kyc.personal?.birth).toJSON().substring(0, 10)
        : null,
      [KEYS.GENDER]: kyc.personal?.sex,
      [KEYS.EMAIL]: kyc.personal?.email,
      [KEYS.PHONE_PREFIX]: kyc.personal?.phonePrefix,
      [KEYS.PHONE_NUMBER]: kyc.personal?.phoneNumber,
      [KEYS.ADDRESS]: kyc.personal?.address,
      [KEYS.ZIP]: kyc.personal?.zip,
      [KEYS.CITY]: kyc.personal?.city,
      [KEYS.RESIDENCE_COUNTRY]: kyc.personal?.residenceCountry,
      [KEYS.NATIONALITY]: kyc.personal?.nationality,
      [KEYS.EXPOSED_PERSON]: kyc.personal?.exposedPerson ? "yes" : "no",
      [KEYS.USA_CITIZEN]: kyc.personal?.usaCitizen ? "yes" : "no",
      // Documents
      [KEYS.DOC_FRONT]: isPropertyMedia(kyc.documents?.front)
        ? kyc.documents?.front[0]
        : null,
      [KEYS.DOC_BACK]: isPropertyMedia(kyc.documents?.back)
        ? kyc.documents?.back[0]
        : null,
      [KEYS.DOC_COMERCIAL_REG]: isPropertyMedia(kyc.documents?.comercialReg)
        ? kyc.documents?.comercialReg[0]
        : null,
      [KEYS.DOC_STATUTES]: isPropertyMedia(kyc.documents?.statutes)
        ? kyc.documents?.statutes[0]
        : null,
    },
  });

  const serviceOptions = SELECT_OPTIONS.SERVICES.map(({ key, label }) => ({
    value: key,
    text: label,
  }));

  const genderOptions = SELECT_OPTIONS.GENDERS.map(({ key, label }) => ({
    value: key,
    text: label,
  }));

  const onSubmit = async (data) => {
    trackEvent(EVENT_CODE.KYC_PERSONAL_INFO);
    const folder = getBucketForKYC(kyc._id);
    try {
      setUploadFilesError(null);
      const front = isPropertyMedia(data[KEYS.DOC_FRONT])
        ? data[KEYS.DOC_FRONT]
        : await uploadFile(data[KEYS.DOC_FRONT], folder);
      const back = isPropertyMedia(data[KEYS.DOC_BACK])
        ? data[KEYS.DOC_BACK]
        : await uploadFile(data[KEYS.DOC_BACK], folder);
      const comercialReg = isPropertyMedia(data[KEYS.DOC_COMERCIAL_REG])
        ? data[KEYS.DOC_COMERCIAL_REG]
        : await uploadFile(data[KEYS.DOC_COMERCIAL_REG], folder);
      const statutes = isPropertyMedia(data[KEYS.DOC_STATUTES])
        ? data[KEYS.DOC_STATUTES]
        : await uploadFile(data[KEYS.DOC_STATUTES], folder);

      const kycUpdated = {
        _id: kyc._id,
        type: KYC_TYPE.CORPORATE,
        company: {
          name: data[KEYS.COMPANY_NAME],
          registry: data[KEYS.COMPANY_REGISTRY],
          legalForm: data[KEYS.COMPANY_LEGAL_FORM],
          address: data[KEYS.COMPANY_ADDRESS],
          city: data[KEYS.COMPANY_CITY],
          zip: data[KEYS.COMPANY_ZIP],
          jurisdiction: data[KEYS.COMPANY_JURISDICTION],
        },
        personal: {
          name: data[KEYS.NAME],
          surname: data[KEYS.SURNAME],
          birth: new Date(data[KEYS.BIRTH]).toJSON(),
          sex: data[KEYS.GENDER],
          email: data[KEYS.EMAIL],
          residenceCountry: data[KEYS.RESIDENCE_COUNTRY],
          city: data[KEYS.CITY],
          zip: data[KEYS.ZIP],
          address: data[KEYS.ADDRESS],
          nationality: data[KEYS.NATIONALITY],
          usaCitizen: data[KEYS.USA_CITIZEN] === "yes",
          exposedPerson: data[KEYS.EXPOSED_PERSON] === "yes",
          phonePrefix: data[KEYS.PHONE_PREFIX],
          phoneNumber: data[KEYS.PHONE_NUMBER],
          documentId: data[KEYS.DOCUMENT_ID],
        },
        documents: {
          front: [front],
          back: [back],
          comercialReg: [comercialReg],
          statutes: [statutes],
        },
      };

      saveKYC(kycUpdated);
    } catch (error) {
      setUploadFilesError(error);
    }
  };

  const refreshValue = (name, value) => {
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.step}>
          <h2 className={styles.title}>Company information</h2>
          <p className={styles.subtitle}>
            Complete the basic information of your company, including registered
            office.
          </p>
          <FormControl>
            <FormLabel htmlFor={KEYS.COMPANY_NAME}>Name</FormLabel>
            <FormInput
              value={getValues(KEYS.COMPANY_NAME)}
              onChange={(value) => refreshValue(KEYS.COMPANY_NAME, value)}
              placeholder="ex: Satoshi Inc"
              id={KEYS.COMPANY_NAME}
            />
            {errors[KEYS.COMPANY_NAME] && (
              <FormError error={errors[KEYS.COMPANY_NAME].message} />
            )}
          </FormControl>
          <div className={styles.gridTwoColumns}>
            <FormControl>
              <FormLabel htmlFor={KEYS.COMPANY_REGISTRY}>
                Company Registry
              </FormLabel>
              <FormInput
                value={getValues(KEYS.COMPANY_REGISTRY)}
                onChange={(value) => refreshValue(KEYS.COMPANY_REGISTRY, value)}
                placeholder="ex: A08002862"
                id={KEYS.COMPANY_REGISTRY}
              />
              {errors[KEYS.COMPANY_REGISTRY] && (
                <FormError error={errors[KEYS.COMPANY_REGISTRY].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.COMPANY_LEGAL_FORM}>
                Legal form
              </FormLabel>
              <FormInput
                value={getValues(KEYS.COMPANY_LEGAL_FORM)}
                onChange={(value) =>
                  refreshValue(KEYS.COMPANY_LEGAL_FORM, value)
                }
                placeholder="ex: SA"
                id={KEYS.COMPANY_LEGAL_FORM}
              />
              {errors[KEYS.COMPANY_LEGAL_FORM] && (
                <FormError error={errors[KEYS.COMPANY_LEGAL_FORM].message} />
              )}
            </FormControl>
          </div>
          <FormControl>
            <FormLabel htmlFor={KEYS.COMPANY_ADDRESS}>Address</FormLabel>
            <FormInput
              value={getValues(KEYS.COMPANY_ADDRESS)}
              onChange={(value) => refreshValue(KEYS.COMPANY_ADDRESS, value)}
              placeholder="ex: Gran Vía 184"
              id={KEYS.COMPANY_ADDRESS}
            />
            {errors[KEYS.COMPANY_ADDRESS] && (
              <FormError error={errors[KEYS.COMPANY_ADDRESS].message} />
            )}
          </FormControl>
          <div className={styles.gridTwoColumns}>
            <FormControl>
              <FormLabel htmlFor={KEYS.COMPANY_ZIP}>Zipcode</FormLabel>
              <FormInput
                value={getValues(KEYS.COMPANY_ZIP)}
                onChange={(value) => refreshValue(KEYS.COMPANY_ZIP, value)}
                placeholder="ex: 08005"
                id={KEYS.COMPANY_ZIP}
              />
              {errors[KEYS.COMPANY_ZIP] && (
                <FormError error={errors[KEYS.COMPANY_ZIP].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.COMPANY_CITY}>City</FormLabel>
              <FormInput
                value={getValues(KEYS.COMPANY_CITY)}
                onChange={(value) => refreshValue(KEYS.COMPANY_CITY, value)}
                placeholder="ex: Madrid"
                id={KEYS.COMPANY_CITY}
              />
              {errors[KEYS.COMPANY_CITY] && (
                <FormError error={errors[KEYS.COMPANY_CITY].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.COMPANY_JURISDICTION}>
                Jurisdiction
              </FormLabel>
              <FormInput
                value={getValues(KEYS.COMPANY_JURISDICTION)}
                onChange={(value) =>
                  refreshValue(KEYS.COMPANY_JURISDICTION, value)
                }
                placeholder="ex: Madrid"
                id={KEYS.COMPANY_JURISDICTION}
              />
              {errors[KEYS.COMPANY_JURISDICTION] && (
                <FormError error={errors[KEYS.COMPANY_JURISDICTION].message} />
              )}
            </FormControl>
          </div>
        </div>
        <div className={styles.step}>
          <h2 className={styles.title}>Representative Information</h2>
          <p className={styles.subtitle}>
            The representative is someone with sufficient power to represent the
            company in this operation
          </p>
          <FormControl>
            <FormLabel htmlFor={KEYS.NAME}>Name</FormLabel>
            <FormInput
              value={getValues(KEYS.NAME)}
              onChange={(value) => refreshValue(KEYS.NAME, value)}
              placeholder="ex: Satoshi"
              id={KEYS.NAME}
            />
            {errors[KEYS.NAME] && (
              <FormError error={errors[KEYS.NAME].message} />
            )}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={KEYS.SURNAME}>Surname</FormLabel>
            <FormInput
              value={getValues(KEYS.SURNAME)}
              onChange={(value) => refreshValue(KEYS.SURNAME, value)}
              placeholder="ex: Nakamoto"
              id={KEYS.SURNAME}
            />
            {errors[KEYS.SURNAME] && (
              <FormError error={errors[KEYS.SURNAME].message} />
            )}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={KEYS.GENDER}>Gender</FormLabel>
            <FormRadioGroup
              id={KEYS.GENDER}
              name={KEYS.GENDER}
              options={genderOptions}
              value={getValues(KEYS.GENDER)}
              onChange={(value) => refreshValue(KEYS.GENDER, value)}
            />
            {errors[KEYS.GENDER] && (
              <FormError error={errors[KEYS.GENDER].message} />
            )}
          </FormControl>
          <div className={styles.gridTwoColumns}>
            <FormControl>
              <FormLabel htmlFor={KEYS.DOCUMENT_ID}>
                NIF / CURP / Passport
              </FormLabel>
              <FormInput
                value={getValues(KEYS.DOCUMENT_ID)}
                onChange={(value) => refreshValue(KEYS.DOCUMENT_ID, value)}
                placeholder="ex: 08724041A"
                id={KEYS.DOCUMENT_ID}
              />
              {errors[KEYS.DOCUMENT_ID] && (
                <FormError error={errors[KEYS.DOCUMENT_ID].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.BIRTH}>Birth date</FormLabel>
              <FormInputDate
                value={getValues(KEYS.BIRTH)}
                onChange={(value) => refreshValue(KEYS.BIRTH, value)}
                id={KEYS.BIRTH}
              />
              {errors[KEYS.BIRTH] && (
                <FormError error={errors[KEYS.BIRTH].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.EMAIL}>Email</FormLabel>
              <FormInput
                value={getValues(KEYS.EMAIL)}
                onChange={(value) => refreshValue(KEYS.EMAIL, value)}
                placeholder="ex: mail@example.com"
                id={KEYS.EMAIL}
              />
              {errors[KEYS.EMAIL] && (
                <FormError error={errors[KEYS.EMAIL].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.CONFIRM_EMAIL}>Confirm email</FormLabel>
              <FormInput
                value={getValues(KEYS.CONFIRM_EMAIL)}
                onChange={(value) => refreshValue(KEYS.CONFIRM_EMAIL, value)}
                placeholder="ex: mail@example.com"
                id={KEYS.CONFIRM_EMAIL}
                onPaste={(event) => {
                  event.preventDefault();
                  return false;
                }}
              />
              {errors[KEYS.CONFIRM_EMAIL] && (
                <FormError error={errors[KEYS.CONFIRM_EMAIL].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.PHONE_PREFIX}>Prefix</FormLabel>
              <FormInput
                value={getValues(KEYS.PHONE_PREFIX)}
                onChange={(value) => refreshValue(KEYS.PHONE_PREFIX, value)}
                placeholder="ex: +34"
                id={KEYS.PHONE_PREFIX}
              />
              {errors[KEYS.PHONE_PREFIX] && (
                <FormError error={errors[KEYS.PHONE_PREFIX].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.PHONE_NUMBER}>Phone number</FormLabel>
              <FormInput
                value={getValues(KEYS.PHONE_NUMBER)}
                onChange={(value) => refreshValue(KEYS.PHONE_NUMBER, value)}
                placeholder="ex: 692555555"
                id={KEYS.PHONE_NUMBER}
                type="number"
              />
              {errors[KEYS.PHONE_NUMBER] && (
                <FormError error={errors[KEYS.PHONE_NUMBER].message} />
              )}
            </FormControl>
          </div>
          <FormControl>
            <FormLabel htmlFor={KEYS.ADDRESS}>Address</FormLabel>
            <FormInput
              value={getValues(KEYS.ADDRESS)}
              onChange={(value) => refreshValue(KEYS.ADDRESS, value)}
              placeholder="ex: Gran Vía 184"
              id={KEYS.ADDRESS}
            />
            {errors[KEYS.ADDRESS] && (
              <FormError error={errors[KEYS.ADDRESS].message} />
            )}
          </FormControl>
          <div className={styles.gridTwoColumns}>
            <FormControl>
              <FormLabel htmlFor={KEYS.ZIP}>Zipcode</FormLabel>
              <FormInput
                value={getValues(KEYS.ZIP)}
                onChange={(value) => refreshValue(KEYS.ZIP, value)}
                placeholder="ex: 08005"
                id={KEYS.ZIP}
              />
              {errors[KEYS.ZIP] && (
                <FormError error={errors[KEYS.ZIP].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.CITY}>City</FormLabel>
              <FormInput
                value={getValues(KEYS.CITY)}
                onChange={(value) => refreshValue(KEYS.CITY, value)}
                placeholder="ex: Madrid"
                id={KEYS.CITY}
              />
              {errors[KEYS.CITY] && (
                <FormError error={errors[KEYS.CITY].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.RESIDENCE_COUNTRY}>Country</FormLabel>
              <FormInput
                value={getValues(KEYS.RESIDENCE_COUNTRY)}
                onChange={(value) =>
                  refreshValue(KEYS.RESIDENCE_COUNTRY, value)
                }
                placeholder="ex: Spain"
                id={KEYS.RESIDENCE_COUNTRY}
              />
              {errors[KEYS.RESIDENCE_COUNTRY] && (
                <FormError error={errors[KEYS.RESIDENCE_COUNTRY].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.NATIONALITY}>Nationality</FormLabel>
              <FormInput
                value={getValues(KEYS.NATIONALITY)}
                onChange={(value) => refreshValue(KEYS.NATIONALITY, value)}
                placeholder="ex: German"
                id={KEYS.NATIONALITY}
              />
              {errors[KEYS.NATIONALITY] && (
                <FormError error={errors[KEYS.NATIONALITY].message} />
              )}
            </FormControl>
          </div>
          <FormControl>
            <FormLabel htmlFor={KEYS.USA_CITIZEN}>
              Are you a US citizen?
            </FormLabel>
            <FormRadioGroup
              id={KEYS.USA_CITIZEN}
              name={KEYS.USA_CITIZEN}
              options={serviceOptions}
              value={getValues(KEYS.USA_CITIZEN)}
              onChange={(value) => refreshValue(KEYS.USA_CITIZEN, value)}
            />
            {errors[KEYS.USA_CITIZEN] && (
              <FormError error={errors[KEYS.USA_CITIZEN].message} />
            )}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={KEYS.EXPOSED_PERSON}>
              Are you a politically exposed person?
            </FormLabel>
            <FormRadioGroup
              id={KEYS.EXPOSED_PERSON}
              name={KEYS.EXPOSED_PERSON}
              options={serviceOptions}
              value={getValues(KEYS.EXPOSED_PERSON)}
              onChange={(value) => refreshValue(KEYS.EXPOSED_PERSON, value)}
            />
            {errors[KEYS.EXPOSED_PERSON] && (
              <FormError error={errors[KEYS.EXPOSED_PERSON].message} />
            )}
          </FormControl>
        </div>

        <div className={styles.step}>
          <h2 className={styles.title}>Documents</h2>
          <p className={styles.subtitle}>
            Upload your ID or Passport (front and back) and selfie, so we can
            verify your identity.
          </p>
          <div className={styles.gridThreeColumns}>
            <FormControl>
              <FormLabel htmlFor={KEYS.DOC_FRONT}>Front ID/Passport</FormLabel>
              <FormInputFile
                value={getValues(KEYS.DOC_FRONT)}
                onChange={(file) => refreshValue(KEYS.DOC_FRONT, file ?? null)}
                placeholderImage="/images/id-card-front.svg"
                id={KEYS.DOC_FRONT}
              />
              {errors[KEYS.DOC_FRONT] && (
                <FormError error={errors[KEYS.DOC_FRONT].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.DOC_BACK}>Back ID/Passport</FormLabel>
              <FormInputFile
                value={getValues(KEYS.DOC_BACK)}
                onChange={(file) => refreshValue(KEYS.DOC_BACK, file ?? null)}
                placeholderImage="/images/id-card-back.svg"
                id={KEYS.DOC_BACK}
              />
              {errors[KEYS.DOC_BACK] && (
                <FormError error={errors[KEYS.DOC_BACK].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.DOC_COMERCIAL_REG}>
                Comercial reg
              </FormLabel>
              <FormInputFile
                value={getValues(KEYS.DOC_COMERCIAL_REG)}
                onChange={(file) =>
                  refreshValue(KEYS.DOC_COMERCIAL_REG, file ?? null)
                }
                placeholderImage="/images/file-pdf.svg"
                id={KEYS.DOC_COMERCIAL_REG}
                accept="application/pdf"
              />
              {errors[KEYS.DOC_COMERCIAL_REG] && (
                <FormError error={errors[KEYS.DOC_COMERCIAL_REG].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.DOC_STATUTES}>Statutes</FormLabel>
              <FormInputFile
                value={getValues(KEYS.DOC_STATUTES)}
                onChange={(file) =>
                  refreshValue(KEYS.DOC_STATUTES, file ?? null)
                }
                placeholderImage="/images/file-pdf.svg"
                id={KEYS.DOC_STATUTES}
                accept="application/pdf"
              />
              {errors[KEYS.DOC_STATUTES] && (
                <FormError error={errors[KEYS.DOC_STATUTES].message} />
              )}
            </FormControl>
          </div>
          {uploadFilesError && (
            <FormError error="An error has occurred uploading the documents" />
          )}
        </div>
        <div className={styles.containerButton}>
          <Button buttonType="submit">Save</Button>
        </div>
      </form>
    </div>
  );
};
