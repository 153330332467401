import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";

import { getTPayKYCIsPassed } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";

const useGetKycPassedBySlug = (variables) => {
  const setCalloutMessage = useSetRecoilState(showCallout);

  /* DEPENDENCIES
========================================================= */

  const handleGetKycPassedBySlug = async (variables) => {
    try {
      const response = await getTPayKYCIsPassed(variables);

      // If the API gives an error show a message.
      if (
        response.getTPayKYCIsPassed.__typename &&
        response.getTPayKYCIsPassed.__typename === "GenericError"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Something went wrong.",
          body: "There was an error while trying to perform this operation. Please, try again.",
        });
        return;
      } else {
        return response.getTPayKYCIsPassed?.result;
      }

      // If it doesn't work, send to login and show an error message.
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "There was an error while trying to perform this operation. Please, try again.",
      });
    }
  };

  return useQuery(
    ["getTPayKYCIsPassed", variables.input.slug],
    () => handleGetKycPassedBySlug(variables),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export default useGetKycPassedBySlug;
