import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import {
  confirmAccountDeletion,
} from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";
import { loggedUserCredentials } from "../../auth/store/auth.store";

/* CONSTANTS
========================================================= */

const useConfirmDeletion = (data) => {
  /* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);
  const navigate = useNavigate();
  const setUserCredentials = useSetRecoilState(loggedUserCredentials);

  const handleInitAccountDeletion = async () => {
    try {
      const result = await confirmAccountDeletion(data);

      if (result.deleteTPayAccount.result) {
        setUserCredentials(null);
        navigate("/login", { replace: true });
        setCalloutMessage({
          show: true,
          type: 1,
          title: "Account deleted successfully.",
          body: "Your account has been deleted.",
        });
        return result;
      }

      // If the API gives an error, redirect to login and show a message.
      if (
        result.deleteTPayAccount.__typename === "GenericError" &&
        result.deleteTPayAccount.code === "ERROR_USER_NOT_AUTHENTICATED"
      ) {
        setUserCredentials(null);
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Your session has expired.",
          body: "Please, login again",
        });
        navigate("/login", { replace: true });
        return result;
      }
      // If it doesn't work, show an error message.
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to delete your account. Please, refresh this page and login again.",
      });
      return;
    }
  };

  return useMutation(handleInitAccountDeletion);
};

export default useConfirmDeletion;
