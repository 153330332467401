import PayoutError from "../PayoutError";
import PayoutLoading from "../PayoutLoading";
import PayoutSuccess from "../PayoutSuccess";

const WizardStep3 = ({ wizardData }) => {
  const { confirmPayout } = wizardData;

  if (confirmPayout.isLoading) {
    return <PayoutLoading />;

    // If success
  } else if (
    confirmPayout.isSuccess &&
    confirmPayout.data &&
    confirmPayout.data.payTPayInvoice.result === true
  ) {
    return <PayoutSuccess wizardData={wizardData} />;

    // If error
  } else {
    return <PayoutError wizardData={wizardData} />;
  }
};

export default WizardStep3;
