export const GENDER = {
  MALE: "MALE",
  FEMALE: "FEMALE",
  UNDEFINED: "UNDEFINED",
};

export const KYC_TYPE = {
  PERSON: "PERSON",
  CORPORATE: "CORPORATE",
};

export const KYC_STATUS = {
  DRAFT: "DRAFT",
  REQUESTED: "REQUESTED",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

export const KEYS = {
  // Personal
  NAME: "name",
  SURNAME: "surname",
  DOCUMENT_ID: "documentId",
  BIRTH: "birth",
  GENDER: "sex",
  EMAIL: "email",
  CONFIRM_EMAIL: "confirm-email",
  PHONE_PREFIX: "phonePrefix",
  PHONE_NUMBER: "phoneNumber",
  RESIDENCE_COUNTRY: "residenceCountry",
  CITY: "city",
  ZIP: "zip",
  ADDRESS: "address",
  NATIONALITY: "nationality",
  USA_CITIZEN: "usaCitizen",
  EXPOSED_PERSON: "exposedPerson",
  // Business
  COMPANY_NAME: "companyName",
  COMPANY_REGISTRY: "companyRegistry",
  COMPANY_LEGAL_FORM: "companyLegalForm",
  COMPANY_ADDRESS: "companyAddress",
  COMPANY_CITY: "companyCity",
  COMPANY_ZIP: "companyZip",
  COMPANY_JURISDICTION: "companyJurisdiction",
  // Documents
  DOC_FRONT: "front",
  DOC_BACK: "back",
  DOC_SELFIE: "selfie",
  DOC_COMERCIAL_REG: "comercialReg",
  DOC_STATUTES: "statutes",
};

export const SELECT_OPTIONS = {
  SERVICES: [
    { key: "yes", label: "Yes" },
    { key: "no", label: "No" },
  ],
  FORM_TYPE: [
    { key: KYC_TYPE.PERSON, label: "Person" },
    { key: KYC_TYPE.CORPORATE, label: "Corporate" },
  ],
  GENDERS: [
    { key: GENDER.MALE, label: "Male" },
    { key: GENDER.FEMALE, label: "Female" },
    { key: GENDER.UNDEFINED, label: "Undefined" },
  ],
};

export const DOCUMENTS_TITLE = {
  [KEYS.DOC_FRONT]: "Front",
  [KEYS.DOC_BACK]: "Back",
  [KEYS.DOC_SELFIE]: "Selfie",
  [KEYS.DOC_COMERCIAL_REG]: "ComercialReg",
  [KEYS.DOC_STATUTES]: "Statutes",
};

export const getBucketForKYC = (kycId) => `turinpay/kyc/${kycId}`;
