import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";
import { AuthForm } from "../routes/auth/auth.types";
import {
  loggedUserCredentials,
  loggedUserData,
  signinData,
} from "../routes/auth/store/auth.store";
import NavBar from "./ui/Navbar";

const FatalCrash = () => {
  const setSigninData = useSetRecoilState(signinData);
  const setLoggedUserCredentials = useSetRecoilState(loggedUserCredentials);
  const setLoggedUserData = useSetRecoilState(loggedUserData);

  const queryClient = useQueryClient();

  const handleGoToStart = () => {
    // Delete session data
    setSigninData(null);
    setLoggedUserCredentials(null);
    setLoggedUserData(null);

    // Clear cache
    queryClient.clear();

    // Send to login
    // navigate("/login", { replace: true });
  };

  return (
    <section className="w-screen h-screen bg-gray-50">
      <header className="fixed flex flex-row justify-between w-screen p-6">
        <NavBar location={AuthForm.login} />
      </header>
      <main className="w-full h-full flex justify-center items-center">
        <div className="flex items-start justify-center w-full my-10">
          <div className="p-6 border-2 rounded-lg shadow-lg w-96">
            <div className="flex flex-col items-center justify-start w-full mb-6 sm:max-w-xl">
              {/* Icon */}
              <div className="flex items-center justify-center w-64 h-auto mt-6">
                <ExclamationCircleIcon className="w-32 h-auto text-red-700" />
              </div>

              {/* Message */}
              <div className="flex flex-col items-center justify-center w-full px-10">
                <h3 className="text-xl font-bold">Something went wrong</h3>
                <div className="flex flex-col items-start justify-start w-full h-auto mt-6">
                  <p className="text-xs font-bold">WHAT HAPPENED?</p>
                  <p className="mb-6">
                    Oops! Something went wrong. Please, try again.
                  </p>
                </div>
                <div className="flex flex-col items-start justify-start w-full h-auto">
                  <p className="text-xs font-bold">DETAILS</p>
                  <p className="mb-6">
                    The app just crashed with a critical error, something very
                    uncommon. Your funds are safe.
                  </p>
                </div>
                <div className="flex flex-col items-start justify-start w-full h-auto">
                  <p className="text-xs font-bold">WHAT CAN I DO NOW?</p>
                  <p className="mb-6">
                    You can close this window and try again to perform the
                    payment. If the error persist, contact us by writing an
                    email to help@turinlabs.com.
                  </p>
                </div>
              </div>

              {/* Actions */}

              <div className="flex items-center justify-center sm:max-w-xl">
                <button
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-bold text-white border border-transparent rounded-md shadow-sm bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
                  onClick={() => handleGoToStart()}
                >
                  Back to login
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default FatalCrash;
