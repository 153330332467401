import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { getCloudfrontURL } from "../../utils/propertyMedia";
import { PaymentStep1 } from "./components/PaymentStep1";
import { PaymentStep2 } from "./components/PaymentStep2";
import { useTurinLabsReCaptcha } from "../../components/recaptcha";
import PublicProfileKycNotPassed from "./PublicProfileKycNotPassed";
import PublicProfileNotFound from "./PublicProfileNotFound";
import turinpayImagotype from "../../assets/turinpay_imagotype_final.svg";
import useGetKycPassedBySlug from "./hooks/useGetKycPassedBySlug";
import useGetPaymentProfileBySlug from "./hooks/useGetPaymentProfileBySlug";

const url = process.env.REACT_APP_TURINPAY_ME_URL;

const Payment = () => {
  const [amount, setAmount] = useState(null);
  const [description, setDescription] = useState(null);
  const [step, setStep] = useState(1);
  const [token, setToken] = useState(null);
  const [tokenRequested, setTokenRequested] = useState(false);

  const { getToken } = useTurinLabsReCaptcha();

  // Get slug
  const { slug } = useParams();

  // Get execution environment mode
  const [searchParams] = useSearchParams();
  const useSandboxExecutionEnvironment =
    (searchParams.get("env") ?? "").toLowerCase() === "sandbox";

  const publicProfileVariables = {
    input: {
      slug: slug,
    },
  };

  // Get user profile from slug
  const publicProfile = useGetPaymentProfileBySlug(publicProfileVariables);
  const kycIsPassedBySlug = useGetKycPassedBySlug(publicProfileVariables);

  useEffect(() => {
    const refreshToken = async () => {
      const recaptchaToken = await getToken();
      setToken(recaptchaToken);
    };

    if (!tokenRequested && !publicProfile.isLoading) {
      setTokenRequested(true);
      refreshToken();
    }
  }, [tokenRequested, getToken, publicProfile.isLoading]);

  useEffect(() => {
    if (step === 1) {
      setTokenRequested(false);
    }
  }, [step]);

  const handleFormChange = ({ amount, description }) => {
    setAmount(amount);
    setDescription(description);
  };

  // Control loading states
  if (publicProfile.isLoading || kycIsPassedBySlug.isLoading)
    return <h1>Loading...</h1>;

  if (!publicProfile.data) return <PublicProfileNotFound />;

  const isKycPassed = kycIsPassedBySlug.data === true;

  return (
    <main className="w-full h-screen relative bg-gray-200">
      <div className="w-full h-12 lg:h-15 bg-gray-50 flex justify-start items-center px-5 sticky top-0 z-50">
        <nav
          className="navbar--imagotype flex items-center justify-between gap-4 w-full"
          data-testid="navbar"
        >
          <img
            src={turinpayImagotype}
            alt="Turinpay logotype"
            className="w-28 lg:w-32"
          />

          {useSandboxExecutionEnvironment && (
            <span className="text-xs bg-lightEucalyptus/50 text-darkEucalyptus px-3 py-0.5 rounded-full">
              Sandbox mode
            </span>
          )}
        </nav>
      </div>
      <section className="relative top-0 h-full w-full flex flex-col justify-start">
        {/* Cover */}
        <div className="w-auto h-80 relative">
          <img
            src={getCloudfrontURL(
              publicProfile?.data?.getTPayPublicPaymentProfileBySlug
                ?.coverPicture?.url
            )}
            alt="Profile cover"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full h-full z-10 flex flex-col justify-start items-center bg-white p-4">
          {/* Profile picture */}
          <div className="w-32 md:w-48 h-auto">
            <img
              src={getCloudfrontURL(
                publicProfile?.data?.getTPayPublicPaymentProfileBySlug
                  ?.profilePicture?.url
              )}
              alt="Profile"
              className="-mt-20 rounded-full"
            />
          </div>
          <div className="w-auto h-auto flex flex-col justify-start items-center max-w-2xl mt-6">
            <p className="font-bold text-2xl">
              {publicProfile?.data?.getTPayPublicPaymentProfileBySlug?.name}
            </p>
            <p className="text-gray-500 mt-2 mb-8">
              {url}/{slug}
            </p>
            <p className="text-center text-sm whitespace-pre-line">
              {
                publicProfile?.data?.getTPayPublicPaymentProfileBySlug
                  ?.description
              }
            </p>
          </div>
          {isKycPassed || useSandboxExecutionEnvironment ? (
            <>
              {step === 1 ? (
                <PaymentStep1
                  amount={amount}
                  description={description}
                  handleChange={handleFormChange}
                />
              ) : (
                <PaymentStep2
                  amount={amount}
                  slug={slug}
                  description={description}
                  useSandboxExecutionEnvironment={
                    useSandboxExecutionEnvironment
                  }
                  googleReCaptchaToken={token}
                />
              )}
              <button
                type="submit"
                id="login-button"
                className="w-fit flex items-center justify-center px-4 py-2 mx-auto font-medium text-white border border-transparent shadow rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus disabled:opacity-75 mt-8"
                onClick={step === 1 ? () => setStep(2) : () => setStep(1)}
                disabled={!Number(amount) || !description}
              >
                {step === 1 ? "Next" : "Back"}
              </button>
            </>
          ) : (
            <PublicProfileKycNotPassed />
          )}

          <div className="mt-8">
            <p className="text-sm text-center">
              Don't have a Bitcoin wallet?{" "}
              <a
                href="https://www.turinlabs.com/turinwallet"
                target="_blank"
                alt="TurinWallet"
                className="text-darkEucalyptus font-medium hover:underline"
                rel="noreferrer"
              >
                download TurinWallet here
              </a>
            </p>
          </div>

          <div className="w-full h-full">
            <div className="h-32" />
          </div>
        </div>
      </section>
      <footer className="text-right fixed bottom-0 w-full z-10 px-4 py-2 bg-white border-t border-gray-200">
        <a href="/" alt="" className="text-gray-400 hover:underline">
          powered by TurinPay
        </a>
      </footer>
    </main>
  );
};

export default Payment;
