import { PrinterIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import jwtDecode from "jwt-decode";
import toast from "react-hot-toast";

import { EVENT_CODE } from "../../../config/analytics.config";
import { FormError, FormInputFile } from "../../../components/form";
import { isPropertyMedia } from "../../../config/validations";
import { KEYS } from "../config";
import { SCHEMA } from "../schema";
import { SettingsSkeleton } from "./SettingsSkeleton";
import { trackEvent } from "../../../utils/analytics";
import { updatePublicPayments } from "../../../services/useCases";
import { uploadFile } from "../../../utils/propertyMedia";
import PublicPaymentsLink from "./PublicPaymentsLink";
import useGetPublicPayments from "../hooks/useGetPublicPayments";

const PUBLIC_PAYMENTS_NOT_FOUND = "ERROR_PUBLIC_PAYMENT_PROFILE_NOT_FOUND";
const MAX_DESCRIPTION_LENGTH = 2000;

const PublicPaymentsProfileForm = () => {
  const queryClient = useQueryClient();
  const userCredentials = queryClient.getQueryData("refreshToken");
  const token = userCredentials?.refreshToken.token;

  // Get public payments info
  const publicPayments = useGetPublicPayments(token);

  // Form to handle public payments settings
  const {
    formState: { errors: errorsPayments },
    handleSubmit: handleSubmitPayments,
    getValues: getPaymentsValues,
    setValue: setPaymentsValue,
    reset: resetPaymentsValues,
  } = useForm({
    resolver: yupResolver(SCHEMA),
  });

  useEffect(() => {
    if (!publicPayments.isLoading) {
      resetPaymentsValues(getDefaultValuesFromPublicPayments(publicPayments));
    }
  }, [publicPayments.isLoading]);

  const getDefaultValuesFromPublicPayments = (publicPayments) => {
    const data = publicPayments?.data?.getTPayPublicPaymentProfile ?? {};
    const notFoundError =
      data.__typename === "GenericError" &&
      data.code === PUBLIC_PAYMENTS_NOT_FOUND;
    const defaultValues = {
      [KEYS.PAYMENTS.ENABLED]: notFoundError ? true : data.enabled === true,
      [KEYS.PAYMENTS.SLUG]: data.slug ?? "",
      [KEYS.PAYMENTS.NAME]: data.name ?? "",
      [KEYS.PAYMENTS.DESCRIPTION]: notFoundError ? "" : data.description,
      [KEYS.PAYMENTS.PROFILE_PICTURE]: isPropertyMedia(data.profilePicture)
        ? {
            ...data.profilePicture,
            type: data.profilePicture?.type || "image/*",
          }
        : null,
      [KEYS.PAYMENTS.COVER_PICTURE]: isPropertyMedia(data.coverPicture)
        ? {
            ...data.coverPicture,
            type: data.coverPicture?.type || "image/*",
          }
        : null,
    };
    return defaultValues;
  };

  const refreshPaymentsValue = (name, value) => {
    setPaymentsValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleUpdatePayments = async () => {
    try {
      const encodedJwt = queryClient.getQueryData("refreshToken");
      const decodedJwt = jwtDecode(encodedJwt.refreshToken.token);

      const savedProfilePicture = getPaymentsValues(
        KEYS.PAYMENTS.PROFILE_PICTURE
      );
      const profilePicture =
        !savedProfilePicture || isPropertyMedia(savedProfilePicture)
          ? savedProfilePicture
          : await uploadFile(savedProfilePicture, null, {}, decodedJwt._id);

      const savedCoverPicture = getPaymentsValues(KEYS.PAYMENTS.COVER_PICTURE);
      const coverPicture =
        !savedCoverPicture || isPropertyMedia(savedCoverPicture)
          ? savedCoverPicture
          : await uploadFile(savedCoverPicture, null, {}, decodedJwt._id);

      const data = {
        token: token,
        variables: {
          input: {
            enabled: getPaymentsValues(KEYS.PAYMENTS.ENABLED),
            slug: getPaymentsValues(KEYS.PAYMENTS.SLUG),
            name: getPaymentsValues(KEYS.PAYMENTS.NAME),
            description: getPaymentsValues(KEYS.PAYMENTS.DESCRIPTION),
            profilePicture,
            coverPicture,
          },
        },
      };

      // Update db
      const response = await updatePublicPayments(data);
      toast.success("Turinpay.me update succesfully!");

      return response;
    } catch (error) {
      console.log(error);
      toast.error("An error occurred updating Turinpay.me. Try again");
    }
  };

  const modifyPublicProfile = useMutation(handleUpdatePayments, {
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["publicPayments", token] }),
  });

  const publicPaymentSlug =
    publicPayments?.data?.getTPayPublicPaymentProfile?.slug;

  return (
    <form
      className="py-10"
      onSubmit={handleSubmitPayments(() => {
        trackEvent(EVENT_CODE.PROFILE_CHANGES);
        modifyPublicProfile.mutate();
      })}
    >
      <div className="flex items-start justify-between gap-4">
        <div className="grow">
          <p>Set up your public payment profile.</p>

          {publicPaymentSlug && <PublicPaymentsLink slug={publicPaymentSlug} />}
        </div>

        {publicPaymentSlug && (
          <a
            target="_blank"
            href={`/me/${publicPaymentSlug}/print`}
            rel="noreferrer"
            className="flex items-center gap-2 text-darkEucalyptus bg-gray-100 border border-gray-300 text-sm font-medium px-3 py-1 rounded"
          >
            <PrinterIcon className="w-6" />
            <span>Print public QR</span>
          </a>
        )}
      </div>

      <div className="mt-5 sm:col-span-4">
        {!publicPayments.data ? (
          <SettingsSkeleton fieldsNumber={6} />
        ) : (
          <>
            <label
              htmlFor={KEYS.PAYMENTS.ENABLED}
              className="block text-sm font-medium text-gray-700"
            >
              Enabled
            </label>
            <div className="mt-1">
              <select
                id={KEYS.PAYMENTS.ENABLED}
                defaultValue={getPaymentsValues(KEYS.PAYMENTS.ENABLED)}
                onChange={(response) =>
                  refreshPaymentsValue(
                    KEYS.PAYMENTS.ENABLED,
                    response.target.value === "true"
                  )
                }
                className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
              <FormError
                error={errorsPayments[KEYS.PAYMENTS.ENABLED]?.message}
              />
            </div>
            <div className="mt-1">
              <label
                htmlFor={KEYS.PAYMENTS.SLUG}
                className="block mt-5 text-sm font-medium text-gray-700"
              >
                Handle
                <span className="text-xs opacity-50 ml-2">
                  (public payments URL identifier)
                </span>
              </label>
              <input
                id={KEYS.PAYMENTS.SLUG}
                defaultValue={getPaymentsValues(KEYS.PAYMENTS.SLUG)}
                onChange={(response) =>
                  refreshPaymentsValue(
                    KEYS.PAYMENTS.SLUG,
                    response.target.value
                  )
                }
                type="text"
                placeholder="ie: satoshinakamoto"
                inputMode="text"
                className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
              />
              <FormError error={errorsPayments[KEYS.PAYMENTS.SLUG]?.message} />
            </div>
            <div className="mt-1">
              <label
                htmlFor={KEYS.PAYMENTS.NAME}
                className="block mt-5 text-sm font-medium text-gray-700"
              >
                Name to display
              </label>
              <input
                id={KEYS.PAYMENTS.NAME}
                defaultValue={getPaymentsValues(KEYS.PAYMENTS.NAME)}
                onChange={(response) =>
                  refreshPaymentsValue(
                    KEYS.PAYMENTS.NAME,
                    response.target.value
                  )
                }
                type="text"
                placeholder="ie: Satoshi Nakamoto"
                inputMode="text"
                className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
              />
              <FormError error={errorsPayments[KEYS.PAYMENTS.NAME]?.message} />
            </div>
            <div className="mt-1">
              <label
                htmlFor={KEYS.PAYMENTS.DESCRIPTION}
                className="block mt-5 text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <textarea
                id={KEYS.PAYMENTS.DESCRIPTION}
                defaultValue={getPaymentsValues(KEYS.PAYMENTS.DESCRIPTION)}
                onChange={(response) =>
                  refreshPaymentsValue(
                    KEYS.PAYMENTS.DESCRIPTION,
                    response.target.value
                  )
                }
                placeholder="ie: Turinlabs creates financial and DeFi products to bring liquidity to Bitcoin and the crypto space, in order to launch products that help people to live better."
                inputMode="text"
                className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
                rows={5}
                maxLength={MAX_DESCRIPTION_LENGTH}
              />
              <p className="text-xs opacity-50 mt-1">
                {(getPaymentsValues(KEYS.PAYMENTS.DESCRIPTION) ?? "").length}/
                {MAX_DESCRIPTION_LENGTH}
              </p>
              <FormError
                error={errorsPayments[KEYS.PAYMENTS.DESCRIPTION]?.message}
              />
            </div>
            <div className="mt-1">
              <label className="block mt-5 text-sm font-medium text-gray-700">
                Profile picture
              </label>
              <p className="text-xs opacity-50">
                Recommended size: 500 x 500 pixels
              </p>
              <FormInputFile
                value={getPaymentsValues(KEYS.PAYMENTS.PROFILE_PICTURE)}
                onChange={(value) =>
                  refreshPaymentsValue(
                    KEYS.PAYMENTS.PROFILE_PICTURE,
                    value ?? null
                  )
                }
              />
              <FormError
                error={errorsPayments[KEYS.PAYMENTS.PROFILE_PICTURE]?.message}
              />
            </div>
            <div className="mt-1">
              <label className="block mt-5 text-sm font-medium text-gray-700">
                Cover picture
              </label>
              <p className="text-xs opacity-50">
                Recommended size: 1500 x 500 pixels
              </p>
              <FormInputFile
                value={getPaymentsValues(KEYS.PAYMENTS.COVER_PICTURE)}
                onChange={(value) =>
                  refreshPaymentsValue(
                    KEYS.PAYMENTS.COVER_PICTURE,
                    value ?? null
                  )
                }
              />
              <FormError
                error={errorsPayments[KEYS.PAYMENTS.COVER_PICTURE]?.message}
              />
            </div>
          </>
        )}
      </div>
      <button
        type="submit"
        className="flex justify-center px-4 py-2 mt-6 font-medium text-white border border-transparent shadow-sm rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus"
        disabled={modifyPublicProfile.isLoading}
      >
        Save changes
      </button>
    </form>
  );
};

export default PublicPaymentsProfileForm;
