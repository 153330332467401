import { trackGoal } from "fathom-client";

export const trackEvent = (code) => {
  try {
    console.log(">>> trackEvent", code);
    trackGoal(code, 0);
  } catch (error) {
    console.error(">>> trackEventError", error);
  }
};
