import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { queryClient } from "../../config/react-query.config";
import useMe from "../../hooks/useMe";
import { AuthForm } from "../../routes/auth/auth.types";
import { loggedUserCredentials } from "../../routes/auth/store/auth.store";
import useGetSettings from "../../routes/settings/hooks/useGetSettings";
import { navigation } from "../../store/global.atoms";
import turinpayImagotype from "../../assets/turinpay_imagotype_final.svg";

/* CONSTANTS
========================================================= */

const LOGIN = "/login";
const SIGNUP = "/signup";
const HOME = "/home";

/* COMPONENT
========================================================= */

const NavBar = ({ location }) => {

  const authData = useRecoilValue(loggedUserCredentials);
  // eslint-disable-next-line no-unused-vars
  const [whereUserIs, setWhereUserIs] = useRecoilState(navigation);

  const refreshToken = queryClient.getQueryData('refreshToken')

  /* PRIVATE METHODS
========================================================= */

  // Query the API to retrieve logged user data
  useMe(authData);
  
  //Query the API to make user settings available across the app
  if (authData) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useGetSettings(refreshToken ? refreshToken.refreshToken.token : null)
  };

  if (!authData) {
    return (
      <>
        <div className="navbar--imagotype" data-testid="navbar">
          <Link
            to={
              location === AuthForm.LOGIN || location === AuthForm.SIGNUP
                ? LOGIN
                : HOME
            }
          >
            <img src={turinpayImagotype} alt="Turinpay logotype" />
          </Link>
        </div>
        <div className="navbar--link flex flex-row items-center">
          <Link to={location === AuthForm.LOGIN ? SIGNUP : LOGIN} data-testid='navbar-cta'>
            {location === AuthForm.LOGIN || location === AuthForm.CONFIRM_LOGIN ? "Signup" : "Login"}
          </Link>
        </div>
      </>
    );
  } else {
    return (
      <>
        {/* <div className="flex flex-row items-center">
          <div className="navbar--imagotype">
            <Link
              to={
                location === AuthForm.LOGIN || location === AuthForm.SIGNUP
                  ? LOGIN
                  : HOME
              }
            >
              <img src={logotype} alt="TurinPool logotype" className="w-24" />
            </Link>
          </div>
          <div className="flex items-center ml-10">
            <Link
              to="/home"
              className={
                whereUserIs.section === "home"
                  ? "font-bold mr-2 navbar-element"
                  : "font-normal mr-2 navbar-element"
              }
              onClick={() => handleNavigation("Home")}
            >
              Home
            </Link>
            <Link
              to="/settings"
              className={
                whereUserIs.section === "settings"
                  ? "font-bold navbar-element"
                  : "font-normal navbar-element"
              }
              onClick={() => handleNavigation("Settings")}
            >
              Settings
            </Link>
          </div>
        </div>
        <div className="navbar--link flex flex-row items-center">
          <div className="bg-white">
            <div className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-eucalyptus">
            {
              userData && userData.isSuccess 
                ? (
                    <img
                      className="h-8 w-8 rounded-full"
                      src={userIcon}
                      alt="Profile"
                    />
                )
                : <Skeleton circle={true} height={32} width={32} />            
            }
              <p className="ml-2 font-bold text-gray-700 text-base disable-cursor" data-testid='user-email'>
                {
                  userData && userData.isSuccess 
                    ? userData.data.me.email
                    : <Skeleton height={20} width={100} />
                }
              </p>
            </div>
          </div>
        </div> */}
      </>
    );
  }
};

export default NavBar;
