import gql from "graphql-tag";

/* TYPES
========================================================= */

export const GENERIC_ERROR = "GenericError";
export const REGISTER = "REGISTER";
export const LOGIN = "LOGIN";
export const EMAIL_MODE = "EMAIL";
export const PHONE_MODE = "PHONE";
export const DOMAIN = "TurinPay";

/* AUTH/USER QUERIES
========================================================= */
export const SIGNUP = gql`
  mutation SENDAUTHCODE_WITH_SECRET_MUTATION(
    $input: SendAuthCodeWithSecretInput!
  ) {
    sendAuthCodeWithSecret(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const CONFIRM_SIGNUP = gql`
  mutation SIGNUP_WITHSECRET_BYAUTHCODE_MUTATION(
    $input: SignupWithSecretByAuthCodeInput!
  ) {
    signupWithSecretByAuthCode(input: $input) {
      __typename
      ... on AuthPayload {
        token
        refreshToken
        tokenExpiry
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const SIGNIN = gql`
  mutation SENDAUTHCODE_WITH_SECRETMUTATION(
    $input: SendAuthCodeWithSecretInput!
  ) {
    sendAuthCodeWithSecret(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const CONFIRM_SIGNIN = gql`
  query SIGNIN_WITH_SECRET_BY_AUTHCODE_QUERY(
    $input: SigninWithSecretByAuthCodeInput!
  ) {
    signinWithSecretByAuthCode(input: $input) {
      __typename
      ... on AuthPayload {
        token
        refreshToken
        tokenExpiry
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const RESEND_AUTH_CODE = gql`
  query RESENDAUTHCODE_QUERY($input: ReSendAuthCodeInput!) {
    reSendAuthCode(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const ME = gql`
  query ME_QUERY {
    me {
      __typename
      ... on User {
        email
        phone
        seedPassPhrase
        apiKeys
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query REFRESHTOKEN_QUERY {
    refreshToken {
      __typename
      ... on AuthPayload {
        token
        refreshToken
        tokenExpiry
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const GET_30DAY_TOKEN = gql`
  query GETLONGTOKEN_QUERY {
    getLongToken {
      __typename
      ... on AuthPayload {
        token
        refreshToken
        tokenExpiry
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const INIT_ACCOUNT_DELETION = gql`
  mutation INIT_ACCOUNT_DELETION_MUTATION {
    initTPayAccountDeletion {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const CONFIRM_ACCOUNT_DELETION = gql`
  mutation DELETE_ACCOUNT_MUTATION($input: DeleteTTpayAccountInput!) {
    deleteTPayAccount(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation MODIFY_SECRET_MUTATION($input: ModifySecretInput!) {
    modifySecret(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const MODIFY_EMAIL = gql`
  mutation MODIFY_EMAIL_MUTATION($input: ModifyEmailInput!) {
    modifyEmail(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const INIT_PASSWORD_RECOVERY = gql`
  query INIT_SECRET_RECOVERY_QUERY($input: InitSecretRecoveryInput!) {
    initSecretRecovery(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const MODIFY_PASSWORD_RECOVERY = gql`
  mutation MODIFY_SECRET_IN_RECOVERY_MUTATION(
    $input: ModifySecretInRecoveryInput!
  ) {
    modifySecretInRecovery(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const MODIFY_PASSWORD_SETTINGS = gql`
  mutation MODIFY_SECRET_MUTATION($input: ModifySecretInput!) {
    modifySecret(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const GET_SETTINGS = gql`
  query GET_SETTINGS_QUERY {
    getTPaySettings {
      __typename
      ... on TPaySettings {
        companyName
        defaultCurrency
        kybPassed
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation UPDATE_SETTINGS_MUTATION($input: UpdateSettingsInput!) {
    updateSettings(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const MODIFY_SETTINGS = gql`
  mutation MODIFY_SETTIMGS_MUTATION($input: ModifyTPaySettingsInput!) {
    modifyTPaySettings(input: $input) {
      __typename
      ... on TPaySettings {
        companyName
        defaultCurrency
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const GET_KYC = gql`
  query GETMYKYC_QUERY {
    getMyKYC {
      __typename
      ... on KYC {
        _id
        type
        status
        lastRejectionReason
        personal {
          address
          birth
          city
          documentId
          email
          exposedPerson
          name
          nationality
          phoneNumber
          phonePrefix
          residenceCountry
          same
          sex
          surname
          usaCitizen
          zip
        }
        financial {
          annualIncome
          goals
          incomeSource
          invCapitalSource
          investedInCompanies
          investFluctuation
          investTime
          knowInvRisk
          knowSecToken
          patrimPercentage
          profession
          studies
        }
        professionalClient {
          annualIncome
          apply
          biOperations
          financialPosition
          ownResources
          societyAssets
          truthfulInfo
          understandsRisk
          valueMore500k
        }
        company {
          address
          city
          jurisdiction
          legalForm
          name
          registry
          zip
        }
        beneficialOwner {
          address
          birth
          city
          documentId
          email
          exposedPerson
          name
          nationality
          phoneNumber
          phonePrefix
          residenceCountry
          same
          sex
          surname
          usaCitizen
          zip
        }
        documents {
          front {
            id
            name
            type
            size
            keyS3
            url
          }
          back {
            id
            name
            type
            size
            keyS3
            url
          }
          selfie {
            id
            name
            type
            size
            keyS3
            url
          }
          comercialReg {
            id
            name
            type
            size
            keyS3
            url
          }
          statutes {
            id
            name
            type
            size
            keyS3
            url
          }
          powerDeputy {
            id
            name
            type
            size
            keyS3
            url
          }
        }
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const UPDATE_KYC = gql`
  mutation UPDATEKYC_MUTATION($input: UpdateKYCInput!) {
    updateKYC(input: $input) {
      __typename
      ... on KYC {
        _id
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const REQUEST_KYC = gql`
  mutation REQUEST_KYC_QUERY($input: RequestKYCInput!) {
    requestKYC(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on GenericError {
        code
        message
        description
      }
    }
  }
`;

export const GET_PRESIGNED_POST = gql`
  query GETPRESIGNEDPOST_QUERY($input: S3PolicyInput!) {
    getPresignedPost(input: $input) {
      __typename
      ... on S3Policy {
        fields {
          ContentType
          Policy
          XAmzAlgorithm
          XAmzCredential
          XAmzDate
          XAmzSignature
          bucket
          key
        }
        url
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const GET_TX_HISTORY = gql`
  query GET_TPAY_HISTORY_QUERY($input: GetTPayHistoryInput!) {
    getTPayHistory(input: $input) {
      __typename
      ... on TPayHistoryItems {
        items {
          type
          amount
          currency
          description
          date
          status
          transactionId
          metadata
          balanceAfterTx
          executionEnvironment
        }
        metadata {
          offset
          limit
          orderBy
          orderDirection
          numElements
        }
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const GET_EVOLUTION = gql`
  query GET_EVOLUTION_QUERY($input: GetTPayEvolutionInput) {
    getTPayEvolution(input: $input) {
      __typename
      ... on TPayEvolutionItems {
        items {
          amount
          accumulatedAmount
          sales
          accumulatedSales
          timestampFrom
          timestampTo
        }
        metadata {
          period
          executionEnvironment
          minutesGranularity
          currency
          numElements
        }
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const GET_WALLET_BALANCE = gql`
  query GET_TPAY_BALANCE_QUERY($input: GetTPayBalanceInput!) {
    getTPayBalance(input: $input) {
      __typename
      ... on TPayBalance {
        balance
        currency
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const VALIDATE_INVOICE = gql`
  query CHECK_TPAY_LN_INVOICE_QUERY($input: CheckTPayLnInvoiceInput!) {
    checkTPayLnInvoice(input: $input) {
      __typename
      ... on CheckTPayLnInvoice {
        description
        amount
        currency
        date
        expiry
        tpayInvoicePaymentData
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const CONFIRM_PAYOUT = gql`
  mutation PAY_TPAY_INVOICE_MUTATION($input: PayTPayInvoiceInput!) {
    payTPayInvoice(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const INIT_PAYOUT = gql`
  mutation INIT_TPAY_PAYMENT_LN_MUTATION(
    $input: InitTPayPaymentLNInvoiceInput!
  ) {
    initTPayPaymentLNInvoice(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const CREATE_INVOICE_DATA_UNAUTH = gql`
  mutation CREATE_TPAYLIGHTNING_INVOICE_MUTATION(
    $input: CreateTPayLightningInvoiceInput!
  ) {
    createTPayLightningInvoice(input: $input) {
      __typename
      ... on TPayInvoice {
        _id
        amount
        description
        status
        onMempool
        expiresOn
        chainAddress
        chainEstimatedFee
        chainRequest
        request
        domainApp
        executionEnvironment
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const GET_INVOICE_DATA = gql`
  query GET_TPAYLIGHTNING_INVOICE_MUTATION($input: GetTPayLnInvoiceInput!) {
    getTPayLnInvoice(input: $input) {
      __typename
      ... on TPayInvoice {
        _id
        amount
        description
        status
        onMempool
        expiresOn
        chainAddress
        chainEstimatedFee
        chainRequest
        request
        domainApp
        executionEnvironment
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const GET_PAIR_RATES = gql`
  query GET_TPAY_PAIR_RATES {
    getTPayPairRates {
      __typename
      ... on TPayPairRatesItems {
        items {
          pair
          rate
        }
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const GET_TPAY_INTENTS = gql`
  query GET_PAYMENT_INTENTS_QUERY($input: GetPaymentIntentsInput) {
    getTPayPaymentIntents(input: $input) {
      __typename
      ... on TPayPaymentIntentItems {
        items {
          id
          userId
          notificationUrl
          orderId
          currency
          price
          description
          createdAt
          executionEnvironment
          active
        }
        metadata {
          offset
          limit
          orderBy
          orderDirection
          numElements
          page
          pages
        }
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

// Public payments
export const CREATE_PI_BY_SLUG = gql`
  mutation CREATE_PAYMENT_INTENT_MUTATION_BY_SLUG(
    $input: CreatePaymentIntentBySlugInput!
  ) {
    createTPayPaymentIntentBySlug(input: $input) {
      __typename
      ... on TPayPaymentIntent {
        id
        userId
        notificationUrl
        orderId
        currency
        price
        description
        executionEnvironment
        initDate
        endDate
        active
        multipleInvoicesAllowed
        invoiceExpirationTimeMin
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const GET_PUBLIC_PAYMENT = gql`
  query GET_TPAY_PUBLIC_PAYMENT_PROFILE {
    getTPayPublicPaymentProfile {
      __typename
      ... on TPayPublicPaymentProfile {
        enabled
        name
        description
        slug
        profilePicture {
          id
          name
          type
          size
          keyS3
          url
        }
        coverPicture {
          id
          name
          type
          size
          keyS3
          url
        }
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const UPDATE_PUBLIC_PAYMENT = gql`
  mutation UPDATE_TPAY_PUBLIC_PAYMENT_PROFILE(
    $input: UpdateTPayPublicPaymentProfileInput!
  ) {
    updateTPayPublicPaymentProfile(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const GET_PAYMENT_PROFILE_BY_SLUG = gql`
  query GET_TPAY_PUBLIC_PAYMENT_PROFILE(
    $input: GetTPayPublicPaymentProfileBySlugInput!
  ) {
    getTPayPublicPaymentProfileBySlug(input: $input) {
      __typename
      ... on TPayPublicPaymentProfile {
        enabled
        name
        description
        slug
        profilePicture {
          id
          name
          type
          size
          keyS3
          url
        }
        coverPicture {
          id
          name
          type
          size
          keyS3
          url
        }
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;

export const GET_TPAY_KYC_IS_PASSED = gql`
  query GET_TPAY_KYC_IS_PASSED($input: GetTPayKYCIsPassedInput!) {
    getTPayKYCIsPassed(input: $input) {
      __typename
      ... on GenericResult {
        result
      }
      ... on Error {
        code
        message
        description
      }
    }
  }
`;
