import { useMutation, useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";
import { GENERIC_ERROR } from "../../../services/gqlQueries";
import { modifySettings } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";

/* CONSTANTS
========================================================= */

const useModifySettings = (data) => {
  /* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);

  const queryClient = useQueryClient();

  const handleModifySettings = async () => {
    try {
      const result = await modifySettings(data);

      return result;
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to modify your password. Please, try again. If the error persist, contact us.",
      });
    }
  };

  return useMutation(handleModifySettings, {
    onSuccess: (data) => {
      if (data.modifyTPaySettings.__typename === GENERIC_ERROR) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: data.modifyTPaySettings.message,
          body: data.modifyTPaySettings.description,
        });
      } else {
        setCalloutMessage({
          show: true,
          type: 1,
          title: "Update done properly.",
          body: "Your company name has been updated successfully.",
        });
        queryClient.invalidateQueries("userSettings");
      }
    },
    onError: () => {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong",
        body: "There was an error when trying to change your company name. Please, try again.",
      });
    },
  });
};

export default useModifySettings;
