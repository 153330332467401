import { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import LoadingSpinner from "../../../assets/LoadingSpinner";
import { PrimaryButton } from "../../../components/ui/PrimaryButton";
import { navigation } from "../../../store/global.atoms";
import PayoutsListEmpty from "../../wallet/components/PayoutsListEmpty";
import PayoutsWizard from "../../wallet/components/PayoutsWizard";
import { locations } from "../helpers/homeLayout.helpers";
import PayoutElement from "./PayoutElement";
import TxDetailModal from "./TxDetailModal";
import TxListEmpty from "./TxListEmpty";
import TxListLoading from "./TxListLoading";

const TxList = ({ txHistory, userData, currency, handleLoadMore }) => {
  const [selectedTx, setSelectedTx] = useState(null);
  const [showTxDetailModal, setShowTxDetailModal] = useState(false);
  const [showPayoutsWizard, setShowPayoutsWizard] = useState(false);

  const txListRef = useRef();

  // Get location to handle messages in TXList section
  const whereUserIs = useRecoilValue(navigation);

  const handleTxClick = (transaction) => {
    setSelectedTx(transaction);
    setShowTxDetailModal(true);
  };

  // If tx list is loading
  if (txHistory.isLoading) return <TxListLoading />;

  if (showPayoutsWizard)
    return (
      <PayoutsWizard
        setShowPayoutsWizard={setShowPayoutsWizard}
        currency={currency}
      />
    );

  if (
    txHistory.isError ||
    !txHistory.data.pages[0] ||
    (txHistory.data.pages[0].getTPayHistory?.items || []).length === 0
  ) {
    return (
      <section className="mt-10 lg:col-span-9 xl:col-span-10">
        {whereUserIs === locations.wallet ? (
          <div className="flex items-center justify-between w-full h-auto mb-8">
            <h3 className="text-2xl font-bold text-darkEucalyptus">Payouts</h3>
            <PrimaryButton
              copy="WITHDRAW FUNDS"
              onClick={() => setShowPayoutsWizard(true)}
            />
          </div>
        ) : (
          <div className="flex items-center justify-between w-full h-auto mb-4">
            <h3 className="text-2xl font-bold text-darkEucalyptus">Sales</h3>
          </div>
        )}
        <TxListEmpty userData={userData} />
      </section>
    );
  }

  // If user is seeing her wallet and have no payouts
  else if (
    whereUserIs === locations.wallet &&
    txHistory.isSuccess &&
    txHistory.data.pages[0].getTPayHistory.items.length === 0
  ) {
    return <PayoutsListEmpty />;
  } else {
    return (
      <>
        <section className="mt-10 lg:col-span-9 xl:col-span-10">
          {whereUserIs === locations.wallet ? (
            <div className="flex items-center justify-between w-full h-auto mb-8">
              <h3 className="text-2xl font-bold text-darkEucalyptus">
                Payouts
              </h3>
              <PrimaryButton
                copy="WITHDRAW FUNDS"
                onClick={() => setShowPayoutsWizard(!showPayoutsWizard)}
              />
            </div>
          ) : (
            <div className="flex items-center justify-between w-full h-auto mb-8">
              <h3 className="text-2xl font-bold text-darkEucalyptus">Sales</h3>
            </div>
          )}
          <div className="px-10 py-5 bg-white shadow rounded-xl">
            <ul ref={txListRef} className="divide-y divide-gray-200">
              {txHistory.data.pages.map((page) =>
                page.getTPayHistory.items.map((transaction, index) => (
                  <li key={index}>
                    <PayoutElement
                      transaction={transaction}
                      handleClick={handleTxClick}
                      currency={currency}
                    />
                  </li>
                ))
              )}
            </ul>
          </div>
          {txListRef.current &&
            txListRef.current.childElementCount <
              txHistory.data.pages[0].getTPayHistory.metadata.numElements && (
              <button
                className="w-full h-12 my-6 font-bold text-white rounded-lg bg-darkEucalyptus disabled:opacity-75"
                onClick={() => handleLoadMore()}
                disabled={txHistory.isLoading}
              >
                {txHistory.isLoading && <LoadingSpinner />}
                LOAD MORE
              </button>
            )}
        </section>
        <TxDetailModal
          selectedTx={selectedTx}
          showTxDetailModal={showTxDetailModal}
          setShowTxDetailModal={setShowTxDetailModal}
          currency={currency}
        />
      </>
    );
  }
};

export default TxList;
