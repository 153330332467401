import { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import LoadingSpinner from "../../../assets/LoadingSpinner";
import { navigation } from "../../../store/global.atoms";
import TxListEmpty from "../../HomeLayout/components/TxListEmpty";
import TxListLoading from "../../HomeLayout/components/TxListLoading";
import { locations } from "../../HomeLayout/helpers/homeLayout.helpers";
import PayoutsListEmpty from "../../wallet/components/PayoutsListEmpty";
import IntentDetailModal from "./IntentDetailModal";
import IntentElement from "./IntentElement";


const IntentsList = ({
  intentsHistory,
  userData,
  currency,
  handleLoadMore,
}) => {
  const [selectedIntent, setSelectedIntent] = useState(null);
  const [showIntentDetailModal, setShowIntentDetailModal] = useState(false);

  // Create ref for UL tx list
  const intentsUl = useRef();

  // Get location to handle messages in TXList section
  const whereUserIs = useRecoilValue(navigation);

  const handleTxClick = (intent) => {
    setSelectedIntent(intent);
    setShowIntentDetailModal(true);
  };

  // If tx list is loading
  if (intentsHistory.isLoading) return <TxListLoading />;

  if (
    intentsHistory.isError ||
    !intentsHistory.data.pages[0] ||
    intentsHistory.data.pages[0].getTPayPaymentIntents.items.length === 0
  ) {
    return (
      <section className="mt-10 lg:col-span-9 xl:col-span-10">
        <div className="flex items-center justify-between w-full h-auto mb-4">
          <h3 className="text-2xl font-bold text-darkEucalyptus">Sales</h3>
        </div>
        <TxListEmpty userData={userData} />
      </section>
    );
  }

  // If petition is loading...
  else if (intentsHistory.isLoading) {
    return <TxListLoading />;
  }

  // If user is seeing her wallet and have no payouts
  else if (
    whereUserIs === locations.wallet &&
    intentsHistory.isSuccess &&
    intentsHistory.data.pages[0].getTPayPaymentIntents.items.length === 0
  ) {
    return <PayoutsListEmpty />;
  } else {
    return (
      <>
        <section className="mt-10 lg:col-span-9 xl:col-span-10">
          {/* <div className="flex items-center justify-between w-full h-auto mb-4">
            <h3 className="text-2xl font-bold text-darkEucalyptus">Sales</h3>
          </div> */}
          <div className="px-10 py-5 bg-white shadow rounded-xl">
            <ul ref={intentsUl} className="divide-y divide-gray-200">
              {intentsHistory.data.pages.map((page) =>
                page.getTPayPaymentIntents.items.map((intent, index) => (
                  <li key={index}>
                    <IntentElement
                      intent={intent}
                      handleClick={handleTxClick}
                      currency={currency}
                    />
                  </li>
                ))
              )}
            </ul>
          </div>
          {intentsUl.current &&
            intentsUl.current.childElementCount <
              intentsHistory.data.pages[0].getTPayPaymentIntents.metadata.numElements && (
              <button
                className="w-full h-12 my-6 font-bold text-white rounded-lg bg-darkEucalyptus disabled:opacity-75"
                onClick={() => handleLoadMore()}
                disabled={intentsHistory.isLoading}
              >
                {intentsHistory.isLoading && <LoadingSpinner />}
                LOAD MORE
              </button>
            )}
        </section>
        <IntentDetailModal
          selectedIntent={selectedIntent}
          showIntentDetailModal={showIntentDetailModal}
          setShowIntentDetailModal={setShowIntentDetailModal}
        />
      </>
    );
  }
};

export default IntentsList;
