const FormInput = ({ type = "text", value, onChange = () => {}, ...props }) => {
  return (
    <input
      defaultValue={value}
      onChange={(response) => onChange(response.target.value)}
      type={type}
      className="block w-full px-1 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-darkEucalyptus focus:border-darkEucalyptus sm:text-sm"
      {...props}
    />
  );
};

export default FormInput;
