import { load, trackPageview } from "fathom-client";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const myFathomId = process.env.REACT_APP_FATHOM_SITE_ID;
const includedDomains = (process.env.REACT_APP_FATHOM_DOMAINS ?? "").split(",");

export default function Fathom() {
  const { pathname, searchParams } = useLocation();

  useEffect(() => {
    load(myFathomId, { includedDomains });
  }, []);

  useEffect(() => {
    trackPageview();
  }, [pathname, searchParams]);

  return null;
}
