import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";

import { getKycUC, updateKyc } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";
import { KYC_STATUS, KYC_TYPE } from "../KYCForm/config";

/* CONSTANTS
========================================================= */

const useGetMyKYC = (jwtToken) => {
  /* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);
  const navigate = useNavigate();

  const handleUseGetMyKYC = async () => {
    try {
      const result = await getKycUC(jwtToken);

      // If the API gives an error, redirect to login and show a message.
      if (
        result.getMyKYC.__typename === "GenericError" &&
        result.getMyKYC.code === "ERROR_USER_NOT_AUTHENTICATED"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Your session has expired.",
          body: "Please, login again",
        });
        navigate("/login", { replace: true });
      } else if (
        result.getMyKYC.__typename === "GenericError" &&
        result.getMyKYC.code === "ERROR_KYC_NOT_FOUND"
      ) {
        // If not exists, create new kyc
        const result = await updateKyc({
          token: jwtToken,
          variables: { input: { type: KYC_TYPE.PERSON } },
        });
        return {
          _id: result.updateKYC._id,
          status: KYC_STATUS.DRAFT,
        };
      } else {
        return result.getMyKYC;
      }
      // If it doesn't work, show an error message.
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to get your portfolio information. Please, refresh this page and login again.",
      });
    }
  };

  return useQuery(["myKyc", jwtToken], () => handleUseGetMyKYC(), {
    refetchOnWindowFocus: false,
  });
};

export default useGetMyKYC;
