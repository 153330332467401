const FormRadioGroup = ({ options = [], value, name, onChange }) => {
  const handleInputChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="flex items-center justify-start gap-6 flex-wrap">
      {options.map((option, index) => (
        <span key={index} className="flex items-center gap-2">
          <input
            type="radio"
            id={`option-${name}-${index}`}
            name={name}
            value={option.value}
            checked={option.value === value}
            onChange={handleInputChange}
            className="cursor-pointer"
          />
          <label htmlFor={`option-${name}-${index}`} className="cursor-pointer">
            {option.text}
          </label>
        </span>
      ))}
    </div>
  );
};

export default FormRadioGroup;
