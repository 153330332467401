import turinpayImagotype from "../../../assets/turinpay_imagotype_final.svg";

const CheckoutLoading = () => {
  return (
    <main className="flex items-center justify-center w-screen h-screen bg-white my-20">
      <section className="w-screen mx-2 sm:w-96 sm:mx-0">
        {/* Card */}

        <div className="h-auto pb-10 bg-white shadow-md w-96 rounded-xl">
          {/* Header */}

          <div className="flex flex-col items-center justify-center w-full">
            <img
              src={turinpayImagotype}
              alt="Turinpay logotype"
              className="my-4"
            />
            <div className="flex items-center justify-between w-full h-auto px-4 py-2 bg-black">
              <p className="text-white">Loading invoice data...</p>
            </div>
          </div>

          {/* Body */}

          <div className="flex flex-col items-center justify-center w-full h-auto">
            <div className="flex flex-col items-center justify-center w-full border border-b-gray-300">
              <div className="flex flex-col items-end justify-start w-full px-4 my-6">
                <div className="w-32 h-6 mb-2 bg-gray-200 rounded animate-pulse"></div>
                <div className="w-32 h-6 bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div>

            <div className="flex items-center justify-center mt-6 bg-gray-200 rounded w-72 h-72 animate-pulse"></div>

            <div className="flex flex-col items-center justify-start w-64 h-auto my-6">
              <label
                htmlFor="invoice"
                className="w-full mb-2 text-sm font-bold text-left"
              >
                INVOICE
              </label>
              <input
                className="w-full h-auto p-2 text-gray-400 border border-gray-200 rounded-xl"
                id="invoice"
                value="Loading invoice id..."
                readOnly
              ></input>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CheckoutLoading;
