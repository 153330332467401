import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

import { AuthForm } from "./routes/auth/auth.types";
import AuthProvider from "./routes/auth/components/AuthProvider";
import Checkout from "./routes/checkout/components/Checkout";
import Fathom from "./Fathom";
import Payment from "./routes/payment/Payment";
import TurinpayMeQrPrint from "./routes/settings/TurinpayMeQrPrint";

const UnauthenticatedApp = () => {
  return (
    <BrowserRouter>
      <Fathom />
      <Routes>
        <Route
          path="/login"
          element={<AuthProvider location={AuthForm.LOGIN} />}
        />
        <Route
          path="/confirm-login"
          element={<AuthProvider location={AuthForm.CONFIRM_LOGIN} />}
        />
        <Route
          path="/signup"
          element={<AuthProvider location={AuthForm.SIGNUP} />}
        />
        <Route
          path="/confirm-signup"
          element={<AuthProvider location={AuthForm.CONFIRM_SIGNUP} />}
        />
        <Route
          path="/forgot-password"
          element={<AuthProvider location={AuthForm.FORGOT_PASSWORD} />}
        />
        <Route
          path="/reset-password"
          element={<AuthProvider location={AuthForm.RESET_PASSWORD} />}
        />
        <Route path="/checkout/:id" element={<Checkout />} />
        <Route path="/me/:slug" element={<Payment />} />
        <Route path="/me/:slug/print" element={<TurinpayMeQrPrint />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default UnauthenticatedApp;
