import { KYC_TYPE, SELECT_OPTIONS } from "./config";
import { useState } from "react";

import { FormLabel, FormRadioGroup } from "../../../components/form";
import { KYCBusinessForm } from "./KYCBusinessForm";
import { KYCPersonalForm } from "./KYCPersonalForm";
import styles from "./KYCForm.module.css";

export const KYCForm = ({ kyc, onSubmit = () => {} }) => {
  const [formType, setFormType] = useState(kyc.type);

  if (!kyc) {
    return <p>Loading...</p>;
  }

  const serviceOptions = SELECT_OPTIONS.FORM_TYPE.map(({ key, label }) => ({
    value: key,
    text: label,
  }));

  return (
    <div>
      <div className={styles.container}>
        <h2 className={styles.title}>Complete your KYC to use our services</h2>
        <div className="flex flex-col gap-4">
          <FormLabel>Choose how you want to register</FormLabel>
          <FormRadioGroup
            name="formType"
            options={serviceOptions}
            value={formType}
            onChange={(value) => setFormType(value)}
          />
        </div>
      </div>

      {formType === KYC_TYPE.PERSON && (
        <KYCPersonalForm kyc={kyc} onSubmit={onSubmit} />
      )}
      {formType === KYC_TYPE.CORPORATE && (
        <KYCBusinessForm kyc={kyc} onSubmit={onSubmit} />
      )}
    </div>
  );
};
