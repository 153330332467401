// CONSTANTS
const PREVIEW_ENVIRONMENT = "preview";
const PRODUCTION_ENVIRONMENT = "production";
const DEVELOP_BRANCH = "develop";
const PREPRODUCTION_BRANCH = "main";

// Get backend endpoint according to the environment in which the app is deployed
export const getBackendEndpoint = () => {
  // Local development
  if (!process.env.REACT_APP_VERCEL_ENV) {
    return process.env.REACT_APP_API_HOST_INTEGRATION;
  }

  // If we are in a local environment, target integration endpoints
  if (
    process.env.REACT_APP_VERCEL_ENV === PREVIEW_ENVIRONMENT &&
    process.env.REACT_APP_VERCEL_GIT_COMMIT_REF !== DEVELOP_BRANCH &&
    process.env.REACT_APP_VERCEL_GIT_COMMIT_REF !== PREPRODUCTION_BRANCH
  )
    return process.env.REACT_APP_API_HOST_INTEGRATION;

  // If we are in develop branch, target integration endpoints
  if (
    process.env.REACT_APP_VERCEL_ENV === PREVIEW_ENVIRONMENT &&
    process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === DEVELOP_BRANCH
  )
    return process.env.REACT_APP_API_HOST_INTEGRATION;

  // If we are in pre-production, target staging endpoints
  if (
    process.env.REACT_APP_VERCEL_ENV === PREVIEW_ENVIRONMENT &&
    process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === PREPRODUCTION_BRANCH
  )
    return process.env.REACT_APP_API_HOST_STAGING;

  // If we are in production
  if (process.env.REACT_APP_VERCEL_ENV === PRODUCTION_ENVIRONMENT)
    return process.env.REACT_APP_API_HOST_PRODUCTION;
};

// Get SSE endpoint
export const getSSEEndpoint = () => {
  // Local development
  if (!process.env.REACT_APP_VERCEL_ENV) {
    return process.env.REACT_APP_SSE_HOST_INTEGRATION;
  }

  // If we are developing, target integration endpoints
  if (
    process.env.REACT_APP_VERCEL_ENV === PREVIEW_ENVIRONMENT &&
    process.env.REACT_APP_VERCEL_GIT_COMMIT_REF !== DEVELOP_BRANCH &&
    process.env.REACT_APP_VERCEL_GIT_COMMIT_REF !== PREPRODUCTION_BRANCH
  )
    return process.env.REACT_APP_SSE_HOST_INTEGRATION;

  // If we are in develop branch, target integration endpoints
  if (
    process.env.REACT_APP_VERCEL_ENV === PREVIEW_ENVIRONMENT &&
    process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === DEVELOP_BRANCH
  )
    return process.env.REACT_APP_SSE_HOST_INTEGRATION;  

  // If we are in pre-production, target staging endpoints
  if (
    process.env.REACT_APP_VERCEL_ENV === PREVIEW_ENVIRONMENT &&
    process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === PREPRODUCTION_BRANCH
  )
    return process.env.REACT_APP_SSE_HOST_STAGING;

  // If we are in production
  if (process.env.REACT_APP_VERCEL_ENV === PRODUCTION_ENVIRONMENT)
    return process.env.REACT_APP_SSE_HOST_PRODUCTION;
};
