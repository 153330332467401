import LoadingSpinner from "../../../../assets/LoadingSpinner";

const WizardStep2 = ({ wizardData }) => {
  const {
    invoiceData,
    step,
    setStep,
    setShowPayoutsWizard,
    confirmationCode,
    handleChange,
    invoiceString,
    confirmPayout,
  } = wizardData;

  const handleConfirmPayout = async () => {
    await confirmPayout.mutate();
    setStep(step + 1);
  };

  return (
    <section className="flex flex-col items-center justify-start my-10 lg:col-span-9 xl:col-span-10">
      <div className="flex flex-col items-center justify-start w-full max-w-3xl">
        <div className="flex flex-col items-center justify-start w-full mb-10">
          <h3 className="w-full text-3xl font-bold text-center text-darkEucalyptus">
            Withdrawing{" "}
            {invoiceData &&
              invoiceData.isSuccess &&
              invoiceData.data.checkTPayLnInvoice.amount}{" "}
            {invoiceData &&
              invoiceData.isSuccess &&
              invoiceData.data.checkTPayLnInvoice.currency}
          </h3>
          <p className="text-gray-600">
            Check payout data and type the confirmation code sent to your email
            to confirm the payout.
          </p>
          {/* <ExpiryCounter hours={hours} minutes={minutes} seconds={seconds} /> */}
        </div>

        {/* Card */}

        <div className="p-6 border-2 rounded-lg shadow-lg bg-white w-96">
          <div className="flex flex-col items-center justify-start w-full mb-6 sm:max-w-xl">
            <label
              htmlFor="confirmation-code"
              className="w-full mb-2 text-xs font-bold text-left"
            >
              INVOICE
            </label>
            <p className="block w-full sm:text-sm">{`${invoiceString.slice(
              0,
              6
            )}...${invoiceString.slice(
              invoiceString.length - 6,
              invoiceString.length
            )}`}</p>
          </div>
          <div className="flex flex-col items-center justify-start w-full mb-6 sm:max-w-xl">
            <label
              htmlFor="confirmation-code"
              className="w-full mb-2 text-xs font-bold text-left"
            >
              FEE
            </label>
            <p className="block w-full sm:text-sm">
              {invoiceData &&
                invoiceData.isSuccess &&
                invoiceData.data.checkTPayLnInvoice.tpayInvoicePaymentData
                  .fixedFeeAmount}
            </p>
          </div>
          <div className="flex flex-col items-center justify-start w-full mb-6 sm:max-w-xl">
            <label
              htmlFor="confirmation-code"
              className="w-full mb-2 text-xs font-bold text-left"
            >
              AMOUNT
            </label>
            <p className="block w-full sm:text-sm">
              {invoiceData &&
                invoiceData.isSuccess &&
                invoiceData.data.checkTPayLnInvoice.amount}{" "}
              {invoiceData &&
                invoiceData.isSuccess &&
                invoiceData.data.checkTPayLnInvoice.currency}
            </p>
          </div>
          <div className="flex flex-col items-center justify-start w-full mb-6 sm:max-w-xl">
            <label
              htmlFor="confirmation-code"
              className="w-full mb-2 text-xs font-bold text-left"
            >
              DESCRIPTION
            </label>
            <p className="block w-full sm:text-sm">
              {invoiceData &&
                invoiceData.isSuccess &&
                invoiceData.data.checkTPayLnInvoice.description}
            </p>
          </div>
          <div className="flex flex-col items-center justify-start w-full mb-10 sm:max-w-xl">
            <label
              htmlFor="confirmation-code"
              className="w-full mb-2 text-xs font-bold text-left"
            >
              CONFIRMATION CODE
            </label>
            <input
              type="number"
              placeholder="I.E: 123456"
              id="confirmation-code"
              name="confirmation-code"
              value={confirmationCode}
              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm sm:text-sm focus:outline-none focus:ring-2 focus:ring-darkEucalyptus focus:border-darkEucalyptus focus:ring-offset-2"
              onChange={handleChange}
            />
          </div>
          <div className="flex items-center justify-center sm:max-w-xl">
            <button
              className="inline-flex justify-center w-full px-4 py-2 text-base font-bold text-white border border-transparent rounded-md shadow-sm bg-lightEucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
              // Update to reset form state
              onClick={() => setShowPayoutsWizard(false)}
            >
              CANCEL
            </button>
            <button
              className="inline-flex justify-center w-full px-4 py-2 ml-4 text-base font-bold text-white border border-transparent rounded-md shadow-sm bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
              onClick={() => handleConfirmPayout()}
              disabled={handleConfirmPayout.isLoading}
            >
              {handleConfirmPayout.isLoading && <LoadingSpinner />}
              CONFIRM
            </button>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default WizardStep2;
