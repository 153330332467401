import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getUserWalletBalance } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";

/* CONSTANTS
========================================================= */

const useGetUserBalance = (data) => {
  
/* DEPENDENCIES
========================================================= */

  const setCalloutMessage = useSetRecoilState(showCallout);
  const navigate = useNavigate();

  const handleGetWalletBalance = async () => {
    try {
      const result = await getUserWalletBalance(data);

      if (
        result.getTPayBalance.__typename === "GenericError" &&
        result.getTPayBalance.code === "ERROR_USER_NOT_AUTHENTICATED"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Your session has expired.",
          body: "Please, login again.",
        });

        navigate("/login", { replace: true });
      } else {
        return result;
      }
    } catch (error) {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "An error ocurred when trying to retrieve your data. Please, try again. If the error persist, contact us.",
      });
    }
  };

  return useQuery(
    [
      "userWalletBalance",
      data.variables.input.currency,
      data.variables.input.executionEnvironment,
    ],
    () => handleGetWalletBalance(),
    {
      enabled: true,
      refetchOnWindowFocus: true,
    }
  );
};

export default useGetUserBalance;
