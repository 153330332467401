import { atom } from 'recoil';

// Display a banner with a message relevant to the user.
// Types: 1. Success, 2. Warning, 3. Danger.
const showCallout = atom({
	key: 'showCallout',
	default: {
		show: false,
		type: 1,
		autoClose: false,
		title: 'Callout Title',
		body: 'Callout Body'
	}
});

export { showCallout };