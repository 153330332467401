import { TrashIcon } from "@heroicons/react/solid";
import { useEffect, useRef, useState } from "react";
import { getCloudfrontURL } from "../../utils/propertyMedia";

const FormInputFile = ({
  accept = "image/*",
  disabled = false,
  label,
  onChange = () => {},
  placeholderImage = "/images/upload-image.svg",
  value = null,
}) => {
  const [file, setFile] = useState(null);
  const inputFileRef = useRef(null);

  useEffect(() => {
    if (!!value) {
      setFile(value);
    }
  }, [value]);

  const handleFileChange = (event) => {
    const files = event.target.files || [];
    if (files.length > 0) {
      if (onChange) {
        onChange(files[0]);
      }
    }
  };

  const handleDeleteFile = () => {
    if (onChange) {
      onChange(null);
    }
    setFile(null);
  };

  const handleOpenFileDirectory = () => {
    inputFileRef.current.click();
  };

  const isImageFile = () => {
    return file && file.type && file.type.indexOf("image/") >= 0;
  };

  const isVideoFile = () => {
    return file && file.type && file.type.indexOf("video/") >= 0;
  };

  return (
    <div className="my-2">
      {file ? (
        // Preview file
        <div className="w-28 h-28 bg-gray-200 border border-gray-300 relative flex items-center justify-center text-center rounded">
          {isImageFile() ? (
            <img
              alt=""
              className="w-full h-full object-cover"
              src={getCloudfrontURL(file.url) ?? URL.createObjectURL(file)}
            />
          ) : isVideoFile() ? (
            <video
              className="w-full h-full object-cover"
              src={getCloudfrontURL(file.url) ?? URL.createObjectURL(file)}
            ></video>
          ) : (
            <div>{label}</div>
          )}

          {!disabled && (
            <button
              type="button"
              onClick={handleDeleteFile}
              className="bg-red-500 text-white w-6 h-6 flex items-center justify-center rounded-full absolute top-0 right-0 -mr-2 -mt-2 opacity-80 hover:opacity-100"
            >
              <TrashIcon width={12} />
            </button>
          )}
        </div>
      ) : (
        <div
          className="w-28 h-28 bg-gray-200 border border-gray-300 flex items-center justify-center cursor-pointer rounded"
          onClick={handleOpenFileDirectory}
        >
          <img
            alt={label}
            className="w-10 opacity-75 grayscale"
            src={placeholderImage}
          />
        </div>
      )}

      <input
        className="hidden"
        type="file"
        ref={inputFileRef}
        onChange={handleFileChange}
        accept={accept}
      />
    </div>
  );
};

export default FormInputFile;
