import { BadgeCheckIcon } from "@heroicons/react/outline";
import { useState } from "react";
import LoadingSpinner from "../../../assets/LoadingSpinner";
import { queryClient } from "../../../config/react-query.config";

const PayoutSuccess = ({ wizardData }) => {

  const [isClosingWizard, setIsClosingWizard] = useState(false);

  const {
    setShowPayoutsWizard,
    userWalletBalance,
    walletBalance,
    payoutsHistory,
    invoiceData
  } = wizardData;

  const handleCloseWizard = async () => {
    setIsClosingWizard(true);

    // Refetch payouts
    queryClient.invalidateQueries("payouts");
    queryClient.invalidateQueries("walletBalance");
    queryClient.invalidateQueries("userWalletBalance");

    await userWalletBalance.refetch();
    await walletBalance.refetch();
    await payoutsHistory.refetch();

    // Close wizard
    setShowPayoutsWizard(false);
    setIsClosingWizard(false);
  };

  return (
    <section className="lg:col-span-9 xl:col-span-10 my-10 flex flex-col justify-start items-center">
      <div className="flex flex-col justify-start items-center w-full max-w-3xl">
        <div className="w-full flex flex-col justify-start items-center mb-10">
          <h3 className="w-full text-center font-bold text-darkEucalyptus text-3xl mb-5">
            Funds withdrawn successfully
          </h3>
          <p className="text-gray-600">Invoice successfully paid.</p>
        </div>
        <div className="w-96 border-2 p-6 rounded-lg bg-white flex flex-col justify-start items-center">
          <BadgeCheckIcon className="w-32 h-auto text-lightEucalyptus" />
          <h4 className="w-full text-center font-bold text-darkEucalyptus text-xl mt-10 mb-5">
            {invoiceData.data.checkTPayLnInvoice.amount} BTC withdrawn
          </h4>
          <p className="text-gray-600 mb-10">Check the wallet from which you created the invoice. Your funds are already there.</p>
          <button
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-eucalyptus text-base font-bold text-white hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm ml-4"
            onClick={() => handleCloseWizard()}
          >
            {
              isClosingWizard && <LoadingSpinner />
            }
            {
              isClosingWizard ? "UPDATING WALLET..." : "CLOSE"
            }
          </button>
        </div>
      </div>
    </section>
  );
};

export default PayoutSuccess;
