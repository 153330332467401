import {
  ResponsiveContainer,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  ComposedChart,
} from "recharts";
import { DateTime } from "luxon";
import { currencyConstants, locations } from "../../routes/HomeLayout/helpers/homeLayout.helpers";
import { useRecoilValue } from "recoil";
import { navigation } from "../../store/global.atoms";

/* CONSTANTS
========================================================= */

const lineColor = "#F0BF33";
const barColor = "#0C6C75";

/* PRIVATE METHODS
========================================================= */

const formatDate = (timestamp) => {
  try {
    return DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_FULL);
  } catch (error) {
    return timestamp;
  }
};

// Custom component for chart's tooltip
const CustomTooltip = ({ active, payload, currency }) => {
  const handleCurrencyLetter = () => {
    if (currency === currencyConstants.usd) return "$";
    if (currency === currencyConstants.eur) return "€";
    if (currency === currencyConstants.btc) return "B";
    if (currency === currencyConstants.sats) return "S";
  };

  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col justify-center items-start bg-white p-4 shadow rounded-xl">
        <div>
          {formatDate(payload[0].payload.timestampTo)}
          <p className="font-bold my-2">
            {payload[0].payload.amount}
            {" "}{handleCurrencyLetter()}
          </p>
          <p className="font-semibold text-darkEucalyptus">
            {payload[0].payload.sales} sales
          </p>
        </div>
      </div>
    );
  }
  return null;
};

const Chart = ({ revenueData, elementsInChart, currency }) => {

  // Get location to handle messages in TXList section
  const whereUserIs = useRecoilValue(navigation);

  if (elementsInChart === 0) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <h3 className="font-bold text-3xl text-darkEucalyptus mb-12">
          {
            whereUserIs === locations.home 
              ? "No sales for the selected period."
              : "No payouts for the selected period."
          }
        </h3>
      </div>
    );
  } else {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={revenueData} className="mt-4 z-10">
          <Bar dataKey="amount" barSize={20} fill={barColor} />
          <Line
            type="monotone"
            dataKey="amount"
            stroke={lineColor}
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
            activeDot={true}
          />
          <XAxis />
          <YAxis />
          <Tooltip content={<CustomTooltip currency={currency} />} />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
};

export default Chart;
