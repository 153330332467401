import { ExclamationCircleIcon } from "@heroicons/react/outline";

const PayoutError = ({ wizardData }) => {
  const { setStep, setInvoiceString, setConfirmationCode } = wizardData;

  const handleGoToStart = () => {
    setStep(1);
    setInvoiceString("");
    setConfirmationCode(undefined);
  };

  return (
    <div className="flex items-start justify-center w-full my-10">
      <div className="p-6 border-2 rounded-lg shadow-lg w-96">
        <div className="flex flex-col items-center justify-start w-full mb-6 sm:max-w-xl">
          {/* Icono */}
          <div className="flex items-center justify-center w-64 h-auto mt-6">
            <ExclamationCircleIcon className="w-32 h-auto text-red-700" />
          </div>

          {/* Mensaje */}
          <div className="flex flex-col items-center justify-center w-full px-10">
            <h3 className="text-xl font-bold">Something went wrong</h3>
            <div className="flex flex-col items-start justify-start w-full h-auto mt-6">
              <p className="text-xs font-bold">WHAT HAPPENED?</p>
              <p className="mb-6">
                Oops! Something went wrong. Please, try again.
              </p>
            </div>
            <div className="flex flex-col items-start justify-start w-full h-auto">
              <p className="text-xs font-bold">DETAILS</p>
              <p className="mb-6">
                There was an error when trying to withdraw your funds but don't
                worry, they are safe. Try again.
              </p>
            </div>
            <div className="flex flex-col items-start justify-start w-full h-auto">
              <p className="text-xs font-bold">WHAT CAN I DO NOW?</p>
              <p className="mb-6">
                You can close this window and try again to perform the payment.
                If the error persist, contact the e-commerce support.
              </p>
            </div>
          </div>

          {/* Actions */}

          <div className="flex items-center justify-center sm:max-w-xl">
            <button
              className="inline-flex justify-center w-full px-4 py-2 text-base font-bold text-white border border-transparent rounded-md shadow-sm bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
              onClick={() => handleGoToStart()}
            >
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoutError;
