import { CalendarIcon } from "@heroicons/react/outline";
import { handleCurrencyLetter, parseDate } from "../../../helpers/common.helpers";

const IntentElement = ({ intent, handleClick, currency }) => {

  return (
    <div
      key={intent.id}
      className="cursor-pointer hover:bg-gray-100"
      onClick={() => handleClick(intent)}
      role="button"
    >
      <div className="flex items-center py-4">
        <div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
          <div className="truncate">
            <div className="flex text-sm">
              <p className="text-lg font-medium truncate text-darkEucalyptus">
                {intent.description}
              </p>
            </div>
            <div className="flex mt-2">
              <div className="flex items-center text-sm text-gray-500">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <p>
                  <time dateTime={intent.createdAt}>
                    {parseDate(intent.createdAt)}
                  </time>
                </p>
              </div>
              <div className="flex justify-start items-center">
                {
                  intent.active 
                    ? <p className="ml-4 text-green-600 text-xs font-bold">ACTIVE</p> 
                    : <p className="ml-4 text-red-600 text-xs font-bold">INACTIVE</p>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 ml-5">
          <p className="text-lg font-bold text-black">
            {handleCurrencyLetter(intent.currency)}{" "}
            {intent.price}
          </p>
        </div>
      </div>
    </div>
  );
};

export default IntentElement;