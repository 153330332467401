import * as yup from "yup";
import {
  isAdult,
  isPropertyMedia,
  MAX_FILE_SIZE,
  REG_EXP,
  SUPPORTED_FORMATS,
} from "../../../config/validations";

import { KEYS } from "./config";

export const SCHEMA_PERSONAL = yup.object().shape({
  // Personal info
  [KEYS.NAME]: yup.string().required("Required name"),
  [KEYS.SURNAME]: yup.string().required("Required surname"),
  [KEYS.DOCUMENT_ID]: yup.string().required("Required NIF / CURP / Passport"),
  [KEYS.BIRTH]: yup
    .string()
    .nullable()
    .required("Required Birth date")
    .test("eighteenYears", "You must be of legal age", (value) =>
      isAdult(value)
    ),
  [KEYS.GENDER]: yup.string().required("Required gender"),
  [KEYS.EMAIL]: yup.string().email("Invalid email").required("Required email"),
  [KEYS.CONFIRM_EMAIL]: yup
    .string()
    .oneOf(
      [yup.ref(KEYS.EMAIL), null],
      "The confirmation email does not match"
    ),
  [KEYS.PHONE_PREFIX]: yup
    .string()
    .required("Required prefix")
    .matches(REG_EXP.phonePrefix, "Invalid phone prefix"),
  [KEYS.PHONE_NUMBER]: yup
    .string()
    .required("Required phone number")
    .matches(REG_EXP.phoneNumber, "Invalid phone number"),
  [KEYS.RESIDENCE_COUNTRY]: yup.string().required("Required residence country"),
  [KEYS.CITY]: yup.string().required("Required city"),
  [KEYS.ZIP]: yup
    .string()
    .required("Required zipcode")
    .matches(REG_EXP.zipcode, "Invalid zipcode"),
  [KEYS.ADDRESS]: yup.string().required("Required address"),
  [KEYS.NATIONALITY]: yup.string().required("Required nationality"),
  [KEYS.USA_CITIZEN]: yup.string().required("Required usa-citizen"),
  [KEYS.EXPOSED_PERSON]: yup.string().required("Required exposed-person"),
  // Documents
  [KEYS.DOC_FRONT]: yup
    .mixed()
    .required("Required file")
    .test(
      "fileSize",
      "The file is too big",
      (value) =>
        isPropertyMedia(value) || (value && value.size <= MAX_FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Invalid file format",
      (value) =>
        isPropertyMedia(value) ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  [KEYS.DOC_BACK]: yup
    .mixed()
    .required("Required file")
    .test(
      "fileSize",
      "The file is too big",
      (value) =>
        isPropertyMedia(value) || (value && value.size <= MAX_FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Invalid file format",
      (value) =>
        isPropertyMedia(value) ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  [KEYS.DOC_SELFIE]: yup
    .mixed()
    .required("Required file")
    .test(
      "fileSize",
      "The file is too big",
      (value) =>
        isPropertyMedia(value) || (value && value.size <= MAX_FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Invalid file format",
      (value) =>
        isPropertyMedia(value) ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    ),
});

export const SCHEMA_BUSINESS = yup.object().shape({
  // Business
  [KEYS.COMPANY_NAME]: yup.string().required("Required company name"),
  [KEYS.COMPANY_REGISTRY]: yup
    .string()
    .required("Required field")
    .matches(REG_EXP.cif, "Invalid field"),
  [KEYS.COMPANY_LEGAL_FORM]: yup.string().required("Required field"),
  [KEYS.COMPANY_ADDRESS]: yup.string().required("Required address"),
  [KEYS.COMPANY_CITY]: yup.string().required("Required city"),
  [KEYS.COMPANY_ZIP]: yup
    .string()
    .required("Required zipcode")
    .matches(REG_EXP.zipcode, "Invalid zipcode"),
  [KEYS.COMPANY_JURISDICTION]: yup
    .string()
    .required("Required company jurisdiction"),
  // Representative
  [KEYS.NAME]: yup.string().required("Required name"),
  [KEYS.SURNAME]: yup.string().required("Required surname"),
  [KEYS.DOCUMENT_ID]: yup.string().required("Required NIF / CURP / Passport"),
  [KEYS.BIRTH]: yup
    .string()
    .nullable()
    .required("Required birth date")
    .test("eighteenYears", "You must be of legal age", (value) =>
      isAdult(value)
    ),
  [KEYS.GENDER]: yup.string().required("Required gender"),
  [KEYS.EMAIL]: yup.string().email("Invalid email").required("Required email"),
  [KEYS.CONFIRM_EMAIL]: yup
    .string()
    .oneOf(
      [yup.ref(KEYS.EMAIL), null],
      "The confirmation email does not match"
    ),
  [KEYS.PHONE_PREFIX]: yup
    .string()
    .required("Required prefix")
    .matches(REG_EXP.phonePrefix, "Invalid prefix"),
  [KEYS.PHONE_NUMBER]: yup
    .string()
    .required("Required phone number")
    .matches(REG_EXP.phoneNumber, "Invalid phone number"),
  [KEYS.RESIDENCE_COUNTRY]: yup.string().required("Required residence country"),
  [KEYS.CITY]: yup.string().required("Required city"),
  [KEYS.ZIP]: yup
    .string()
    .required("Required zipcode")
    .matches(REG_EXP.zipcode, "Invalid zipcode"),
  [KEYS.ADDRESS]: yup.string().required("Required address"),
  [KEYS.NATIONALITY]: yup.string().required("Required nationality"),
  [KEYS.USA_CITIZEN]: yup.string().required("Required field"),
  [KEYS.EXPOSED_PERSON]: yup.string().required("Required field"),
  // Documents
  [KEYS.DOC_FRONT]: yup
    .mixed()
    .required("Required file")
    .test(
      "fileSize",
      "The file is too big",
      (value) =>
        isPropertyMedia(value) || (value && value.size <= MAX_FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Invalid file format",
      (value) =>
        isPropertyMedia(value) ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  [KEYS.DOC_BACK]: yup
    .mixed()
    .required("Required file")
    .test(
      "fileSize",
      "The file is too big",
      (value) =>
        isPropertyMedia(value) || (value && value.size <= MAX_FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Invalid file format",
      (value) =>
        isPropertyMedia(value) ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  [KEYS.DOC_COMERCIAL_REG]: yup
    .mixed()
    .required("Required file")
    .test(
      "fileSize",
      "The file is too big",
      (value) =>
        isPropertyMedia(value) || (value && value.size <= MAX_FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Invalid file format",
      (value) =>
        isPropertyMedia(value) ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  [KEYS.DOC_STATUTES]: yup
    .mixed()
    .required("Required file")
    .test(
      "fileSize",
      "The file is too big",
      (value) =>
        isPropertyMedia(value) || (value && value.size <= MAX_FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Invalid file format",
      (value) =>
        isPropertyMedia(value) ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    ),
});
