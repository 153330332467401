import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import LoadingSpinner from "../../../assets/LoadingSpinner";
import { DOMAIN, GENERIC_ERROR } from "../../../services/gqlQueries";
import { modifySecretRecovery } from "../../../services/useCases";
import { showCallout } from "../../../store/callout.atoms";

const PASSWORD_REGEX = /^(?=.\d)(?=.[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;

const ResetPassword = () => {
  const setCalloutMessage = useSetRecoilState(showCallout);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const handleSubmitMutation = useMutation(modifySecretRecovery, {
    onSuccess: (data) => {
      if (data.__typename === GENERIC_ERROR) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: data.message,
          body: data.description,
        });
      } else {
        setCalloutMessage({
          show: true,
          type: 1,
          title: "Password successfully changed!",
          body: "You can now login with your new password.",
        });
        navigate("/login");
      }
    },
    onError: () => {
      setCalloutMessage({
        show: true,
        type: 3,
        title: "Something went wrong.",
        body: "There was an error when trying to update your password. Please, try again and if the error persists, contact us.",
      });
    },
  });

  const onSubmit = () => {
    // Build mutation object
    const data = {
      input: {
        email: getValues("email"),
        authCodeEmail: getValues("code"),
        newSecret: getValues("newPassword"),
        domainApp: DOMAIN,
      },
    };

    handleSubmitMutation.mutate(data);
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="py-8 bg-white shadow rounded-xl">
        <div className="max-w-md mb-6">
          <h3 className="px-10 pb-6 font-bold text-left text-gray-900 text-3x1">
            Reset password
          </h3>
          <p className="px-10 mb-6 text-gray-700">
            If an email associated with an account exists, your password will be
            updated.
          </p>
          <hr />
        </div>
        <form className="px-10 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                inputMode="email"
                autoComplete="email"
                className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm appearance-none focus:outline-none focus:border-eucalyptus"
                placeholder="satoshi@nakamoto.com"
                {...register("email", { required: true })}
              />
              {errors.email?.type === "required" && (
                <p className="mt-2 text-sm font-bold text-red-700">
                  Please, type your email.
                </p>
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor="code"
              className="block text-sm font-medium text-gray-700"
            >
              Code
            </label>
            <div className="mt-1">
              <input
                id="code"
                name="code"
                type="number"
                inputMode="number"
                className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm appearance-none focus:outline-none focus:border-eucalyptus"
                placeholder="satoshi@nakamoto.com"
                {...register("code", { required: true })}
              />
              {errors.email?.type === "required" && (
                <p className="mt-2 text-sm font-bold text-red-700">
                  Please, type the code received in the indicated email.
                </p>
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor="newPassword"
              className="block text-sm font-medium text-gray-700"
            >
              New password
            </label>
            <div className="mt-1">
              <input
                id="newPassword"
                name="newPassword"
                type="password"
                inputMode="password"
                className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm appearance-none focus:outline-none focus:border-eucalyptus"
                placeholder="********"
                {...register("newPassword", {
                  required: true,
                  // pattern: PASSWORD_REGEX
                })}
              />
              {errors.email?.type === "required" && (
                <p className="mt-2 text-sm font-bold text-red-700">
                  Please, type a new password.
                </p>
              )}
              <p className="text-sm font-medium text-gray-700">
                8 characters minimum, 1 symbol, 1 lowercase and 1 uppercase
              </p>
            </div>
          </div>

          <div>
            <label
              htmlFor="confirmNewPassword"
              className="block text-sm font-medium text-gray-700"
            >
              Confirm new password
            </label>
            <div className="mt-1">
              <input
                id="confirmNewPassword"
                name="confirmNewPassword"
                type="password"
                inputMode="password"
                className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm appearance-none focus:outline-none focus:border-eucalyptus"
                placeholder="********"
                {...register("confirmNewPassword", {
                  required: true,
                  // pattern: PASSWORD_REGEX,
                  validate: (value) => {
                    const { newPassword } = getValues();
                    return newPassword === value || "Passwords do not match.";
                  },
                })}
              />
              {errors.email?.type === "required" && (
                <p className="mt-2 text-sm font-bold text-red-700">
                  Please, type a new password.
                </p>
              )}
              <p className="text-sm font-medium text-gray-700">
                8 characters minimum, 1 symbol, 1 lowercase and 1 uppercase
              </p>
            </div>
          </div>

          <div>
            <button
              type="submit"
              id="submit-button"
              className="flex justify-center px-4 py-2 mx-auto font-medium text-white border border-transparent shadow-sm rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus"
              disabled={handleSubmitMutation.isLoading}
            >
              {handleSubmitMutation.isLoading && <LoadingSpinner />}
              Confirm
            </button>
          </div>
        </form>
      </div>

      <div className="flex flex-row justify-center mt-6">
        <p className="text-sm font-medium">
          Do you remember it already?
          <Link
            to="/login"
            className="text-eucalyptus"
            data-testid="login-link"
          >
            {" "}
            Back to login
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
