import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { refreshTokenQuery } from "../services/useCases";
import { showCallout } from "../store/callout.atoms";

/* CONSTANTS
========================================================= */

const REFETCH_INTERVAL = 14 * 60 * 1000;

const useRefreshToken = () => {
  const queryClient = useQueryClient();

  const setCalloutMessage = useSetRecoilState(showCallout);
  const navigate = useNavigate();

  // Get JWT from cache
  const JWT = queryClient.getQueryData("JWT");

  // Get refresh token from cache
  const RT = queryClient.getQueryData("RT");

  const data = { token: JWT, refreshToken: RT };

  /* DEPENDENCIES
========================================================= */

  const handleRefreshToken = async (data) => {
    try {
      if (!data) return;

      const result = await refreshTokenQuery(data);

      // If the API gives an error, redirect to login and show a message.
      if (
        result.refreshToken.__typename &&
        result.refreshToken.__typename === "GenericError" &&
        result.refreshToken.code === "ERROR_USER_NOT_AUTHENTICATED"
      ) {
        setCalloutMessage({
          show: true,
          type: 3,
          title: "Your session has expired.",
          body: "Please, login again.",
        });

        navigate("/login", { replace: true });
      }

      queryClient.setQueryData("JWT", result.refreshToken.token);
      queryClient.setQueryData("RT", result.refreshToken.refreshToken);

      return result;

      // If it doesn't work, send to login and show an error message.
    } catch (error) {
      return;
    }
  };

  return useQuery(["refreshToken"], () => handleRefreshToken(data), {
    enabled: true,
    refetchOnWindowFocus: true,
    retry: false,
    staleTime: REFETCH_INTERVAL,
    refetchInterval: REFETCH_INTERVAL,
  });
};

export default useRefreshToken;
