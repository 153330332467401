import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "../../../components/button";
import { getBucketForKYC, KEYS, KYC_TYPE, SELECT_OPTIONS } from "./config";
import { EVENT_CODE } from "../../../config/analytics.config";
import {
  FormControl,
  FormError,
  FormInput,
  FormInputDate,
  FormInputFile,
  FormLabel,
  FormRadioGroup,
} from "../../../components/form";
import { isPropertyMedia } from "../../../config/validations";
import { SCHEMA_PERSONAL } from "./schema";
import { trackEvent } from "../../../utils/analytics";
import { uploadFile } from "../../../utils/propertyMedia";
import styles from "./KYCForm.module.css";

export const KYCPersonalForm = ({ kyc, onSubmit: saveKYC = () => {} }) => {
  const [uploadFilesError, setUploadFilesError] = useState(null);

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(SCHEMA_PERSONAL),
    defaultValues: {
      // Personal
      [KEYS.NAME]: kyc.personal?.name,
      [KEYS.SURNAME]: kyc.personal?.surname,
      [KEYS.DOCUMENT_ID]: kyc.personal?.documentId,
      [KEYS.BIRTH]: kyc.personal?.birth
        ? new Date(kyc.personal?.birth).toJSON().substring(0, 10)
        : null,
      [KEYS.GENDER]: kyc.personal?.sex,
      [KEYS.EMAIL]: kyc.personal?.email,
      [KEYS.PHONE_PREFIX]: kyc.personal?.phonePrefix,
      [KEYS.PHONE_NUMBER]: kyc.personal?.phoneNumber,
      [KEYS.ADDRESS]: kyc.personal?.address,
      [KEYS.ZIP]: kyc.personal?.zip,
      [KEYS.CITY]: kyc.personal?.city,
      [KEYS.RESIDENCE_COUNTRY]: kyc.personal?.residenceCountry,
      [KEYS.NATIONALITY]: kyc.personal?.nationality,
      [KEYS.EXPOSED_PERSON]: kyc.personal?.exposedPerson ? "yes" : "no",
      [KEYS.USA_CITIZEN]: kyc.personal?.usaCitizen ? "yes" : "no",
      // Documents
      [KEYS.DOC_FRONT]: isPropertyMedia(kyc.documents?.front)
        ? kyc.documents.front[0]
        : null,
      [KEYS.DOC_BACK]: isPropertyMedia(kyc.documents?.back)
        ? kyc.documents.back[0]
        : null,
      [KEYS.DOC_SELFIE]: isPropertyMedia(kyc.documents?.selfie)
        ? kyc.documents.selfie[0]
        : null,
    },
  });

  const serviceOptions = SELECT_OPTIONS.SERVICES.map(({ key, label }) => ({
    value: key,
    text: label,
  }));

  const genderOptions = SELECT_OPTIONS.GENDERS.map(({ key, label }) => ({
    value: key,
    text: label,
  }));

  const onSubmit = async (data) => {
    trackEvent(EVENT_CODE.KYC_PERSONAL_INFO);
    const folder = getBucketForKYC(kyc._id);

    try {
      setUploadFilesError(null);
      const front = isPropertyMedia(data[KEYS.DOC_FRONT])
        ? data[KEYS.DOC_FRONT]
        : await uploadFile(data[KEYS.DOC_FRONT], folder);
      const back = isPropertyMedia(data[KEYS.DOC_BACK])
        ? data[KEYS.DOC_BACK]
        : await uploadFile(data[KEYS.DOC_BACK], folder);
      const selfie = isPropertyMedia(data[KEYS.DOC_SELFIE])
        ? data[KEYS.DOC_SELFIE]
        : await uploadFile(data[KEYS.DOC_SELFIE], folder);

      const kycUpdated = {
        _id: kyc._id,
        type: KYC_TYPE.PERSON,
        personal: {
          name: data[KEYS.NAME],
          surname: data[KEYS.SURNAME],
          birth: new Date(data[KEYS.BIRTH]).toJSON(),
          residenceCountry: data[KEYS.RESIDENCE_COUNTRY],
          city: data[KEYS.CITY],
          zip: data[KEYS.ZIP],
          address: data[KEYS.ADDRESS],
          nationality: data[KEYS.NATIONALITY],
          usaCitizen: data[KEYS.USA_CITIZEN] === "yes",
          exposedPerson: data[KEYS.EXPOSED_PERSON] === "yes",
          sex: data[KEYS.GENDER],
          email: data[KEYS.EMAIL],
          phonePrefix: data[KEYS.PHONE_PREFIX],
          phoneNumber: data[KEYS.PHONE_NUMBER],
          documentId: data[KEYS.DOCUMENT_ID],
        },
        documents: { front: [front], back: [back], selfie: [selfie] },
      };

      saveKYC(kycUpdated);
    } catch (error) {
      setUploadFilesError(error);
    }
  };

  const refreshValue = (name, value) => {
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.step}>
          <h2 className={styles.title}>Personal information</h2>
          <p className={styles.subtitle}>
            Fill in your basic personal information, including the address where
            you reside.
          </p>
          <FormControl>
            <FormLabel htmlFor={KEYS.NAME}>Name</FormLabel>
            <FormInput
              value={getValues(KEYS.NAME)}
              onChange={(value) => refreshValue(KEYS.NAME, value)}
              placeholder="ex: Satoshi"
              id={KEYS.NAME}
            />
            {errors[KEYS.NAME] && (
              <FormError error={errors[KEYS.NAME].message} />
            )}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={KEYS.SURNAME}>Surname</FormLabel>
            <FormInput
              value={getValues(KEYS.SURNAME)}
              onChange={(value) => refreshValue(KEYS.SURNAME, value)}
              placeholder="ex: Nakamoto"
              id={KEYS.SURNAME}
            />
            {errors[KEYS.SURNAME] && (
              <FormError error={errors[KEYS.SURNAME].message} />
            )}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={KEYS.GENDER}>Gender</FormLabel>
            <FormRadioGroup
              id={KEYS.GENDER}
              name={KEYS.GENDER}
              options={genderOptions}
              value={getValues(KEYS.GENDER)}
              onChange={(value) => refreshValue(KEYS.GENDER, value)}
            />
            {errors[KEYS.GENDER] && (
              <FormError error={errors[KEYS.GENDER].message} />
            )}
          </FormControl>
          <div className={styles.gridTwoColumns}>
            <FormControl>
              <FormLabel htmlFor={KEYS.DOCUMENT_ID}>
                NIF / CURP / Passport
              </FormLabel>
              <FormInput
                value={getValues(KEYS.DOCUMENT_ID)}
                onChange={(value) => refreshValue(KEYS.DOCUMENT_ID, value)}
                placeholder="ex: 08724041A"
                id={KEYS.DOCUMENT_ID}
              />
              {errors[KEYS.DOCUMENT_ID] && (
                <FormError error={errors[KEYS.DOCUMENT_ID].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.BIRTH}>Birth date</FormLabel>
              <FormInputDate
                value={getValues(KEYS.BIRTH)}
                onChange={(value) => refreshValue(KEYS.BIRTH, value)}
                id={KEYS.BIRTH}
              />
              {errors[KEYS.BIRTH] && (
                <FormError error={errors[KEYS.BIRTH].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.EMAIL}>Email</FormLabel>
              <FormInput
                value={getValues(KEYS.EMAIL)}
                onChange={(value) => refreshValue(KEYS.EMAIL, value)}
                placeholder="mail@example.com"
                id={KEYS.EMAIL}
              />
              {errors[KEYS.EMAIL] && (
                <FormError error={errors[KEYS.EMAIL].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.CONFIRM_EMAIL}>Confirm email</FormLabel>
              <FormInput
                value={getValues(KEYS.CONFIRM_EMAIL)}
                onChange={(value) => refreshValue(KEYS.CONFIRM_EMAIL, value)}
                placeholder="mail@example.com"
                id={KEYS.CONFIRM_EMAIL}
                onPaste={(event) => {
                  event.preventDefault();
                  return false;
                }}
              />
              {errors[KEYS.CONFIRM_EMAIL] && (
                <FormError error={errors[KEYS.CONFIRM_EMAIL].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.PHONE_PREFIX}>Prefix</FormLabel>
              <FormInput
                value={getValues(KEYS.PHONE_PREFIX)}
                onChange={(value) => refreshValue(KEYS.PHONE_PREFIX, value)}
                placeholder="ex: +34"
                id={KEYS.PHONE_PREFIX}
              />
              {errors[KEYS.PHONE_PREFIX] && (
                <FormError error={errors[KEYS.PHONE_PREFIX].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.PHONE_NUMBER}>Phone number</FormLabel>
              <FormInput
                value={getValues(KEYS.PHONE_NUMBER)}
                onChange={(value) => refreshValue(KEYS.PHONE_NUMBER, value)}
                placeholder="ex: 692555555"
                id={KEYS.PHONE_NUMBER}
                type="number"
              />
              {errors[KEYS.PHONE_NUMBER] && (
                <FormError error={errors[KEYS.PHONE_NUMBER].message} />
              )}
            </FormControl>
          </div>
          <FormControl>
            <FormLabel htmlFor={KEYS.ADDRESS}>Address</FormLabel>
            <FormInput
              value={getValues(KEYS.ADDRESS)}
              onChange={(value) => refreshValue(KEYS.ADDRESS, value)}
              placeholder="ex: Gran Vía 184"
              id={KEYS.ADDRESS}
            />
            {errors[KEYS.ADDRESS] && (
              <FormError error={errors[KEYS.ADDRESS].message} />
            )}
          </FormControl>
          <div className={styles.gridTwoColumns}>
            <FormControl>
              <FormLabel htmlFor={KEYS.ZIP}>Zipcode</FormLabel>
              <FormInput
                value={getValues(KEYS.ZIP)}
                onChange={(value) => refreshValue(KEYS.ZIP, value)}
                placeholder="ex: 08005"
                id={KEYS.ZIP}
              />
              {errors[KEYS.ZIP] && (
                <FormError error={errors[KEYS.ZIP].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.CITY}>City</FormLabel>
              <FormInput
                value={getValues(KEYS.CITY)}
                onChange={(value) => refreshValue(KEYS.CITY, value)}
                placeholder="ex: Madrid"
                id={KEYS.CITY}
              />
              {errors[KEYS.CITY] && (
                <FormError error={errors[KEYS.CITY].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.RESIDENCE_COUNTRY}>Country</FormLabel>
              <FormInput
                value={getValues(KEYS.RESIDENCE_COUNTRY)}
                onChange={(value) =>
                  refreshValue(KEYS.RESIDENCE_COUNTRY, value)
                }
                placeholder="ex: Spain"
                id={KEYS.RESIDENCE_COUNTRY}
              />
              {errors[KEYS.RESIDENCE_COUNTRY] && (
                <FormError error={errors[KEYS.RESIDENCE_COUNTRY].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.NATIONALITY}>Nationality</FormLabel>
              <FormInput
                value={getValues(KEYS.NATIONALITY)}
                onChange={(value) => refreshValue(KEYS.NATIONALITY, value)}
                placeholder="ex: German"
                id={KEYS.NATIONALITY}
              />
              {errors[KEYS.NATIONALITY] && (
                <FormError error={errors[KEYS.NATIONALITY].message} />
              )}
            </FormControl>
          </div>
          <FormControl>
            <FormLabel htmlFor={KEYS.USA_CITIZEN}>
              Are you a US citizen?
            </FormLabel>
            <FormRadioGroup
              id={KEYS.USA_CITIZEN}
              name={KEYS.USA_CITIZEN}
              options={serviceOptions}
              value={getValues(KEYS.USA_CITIZEN)}
              onChange={(value) => refreshValue(KEYS.USA_CITIZEN, value)}
            />
            {errors[KEYS.USA_CITIZEN] && (
              <FormError error={errors[KEYS.USA_CITIZEN].message} />
            )}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={KEYS.EXPOSED_PERSON}>
              Are you a politically exposed person?
            </FormLabel>
            <FormRadioGroup
              id={KEYS.EXPOSED_PERSON}
              name={KEYS.EXPOSED_PERSON}
              options={serviceOptions}
              value={getValues(KEYS.EXPOSED_PERSON)}
              onChange={(value) => refreshValue(KEYS.EXPOSED_PERSON, value)}
            />
            {errors[KEYS.EXPOSED_PERSON] && (
              <FormError error={errors[KEYS.EXPOSED_PERSON].message} />
            )}
          </FormControl>
        </div>

        <div className={styles.step}>
          <h2 className={styles.title}>Documents</h2>
          <p className={styles.subtitle}>
            Upload your ID or Passport (front and back) and selfie, so we can
            verify your identity.
          </p>
          <div className={styles.gridThreeColumns}>
            <FormControl>
              <FormLabel htmlFor={KEYS.DOC_FRONT}>Front ID/Passport</FormLabel>
              <FormInputFile
                value={getValues(KEYS.DOC_FRONT)}
                onChange={(file) => refreshValue(KEYS.DOC_FRONT, file ?? null)}
                placeholderImage="/images/id-card-front.svg"
                id={KEYS.DOC_FRONT}
              />
              {errors[KEYS.DOC_FRONT] && (
                <FormError error={errors[KEYS.DOC_FRONT].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.DOC_BACK}>Back ID/Passport</FormLabel>
              <FormInputFile
                value={getValues(KEYS.DOC_BACK)}
                onChange={(file) => refreshValue(KEYS.DOC_BACK, file ?? null)}
                placeholderImage="/images/id-card-back.svg"
                id={KEYS.DOC_FRONT}
              />
              {errors[KEYS.DOC_BACK] && (
                <FormError error={errors[KEYS.DOC_BACK].message} />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={KEYS.DOC_SELFIE}>Selfie</FormLabel>
              <FormInputFile
                value={getValues(KEYS.DOC_SELFIE)}
                onChange={(file) => refreshValue(KEYS.DOC_SELFIE, file ?? null)}
                placeholderImage="/images/face-detection.svg"
                id={KEYS.DOC_SELFIE}
              />
              {errors[KEYS.DOC_SELFIE] && (
                <FormError error={errors[KEYS.DOC_SELFIE].message} />
              )}
            </FormControl>
          </div>
          {uploadFilesError && (
            <FormError error="An error has occurred uploading the documents" />
          )}
        </div>
        <div className={styles.containerButton}>
          <Button buttonType="submit">Save</Button>
        </div>
      </form>
    </div>
  );
};
