/* This example requires Tailwind CSS v2.0+ */
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Toggle = ({ enabled, setEnabled }) => {

  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className="relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 rounded-full cursor-pointer group focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-eucalyptus"
    >
      <span className="sr-only">Toggle developer mode</span>
      <span aria-hidden="true" className="absolute w-full h-full bg-white rounded-md pointer-events-none" />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'bg-eucalyptus' : 'bg-gray-400',
          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-400 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
        )}
      />
    </Switch>
  )
}
