import { ClockIcon, RefreshIcon } from "@heroicons/react/outline";

import { Button } from "../../../components/button";
import { Card } from "../../../components/card";

const KYCRequested = ({ onRefresh = () => {} }) => {
  return (
    <Card>
      <div className="flex flex-col lg:flex-row items-center gap-8 py-8">
        <div className="flex gap-8 items-center grow">
          <div className="flex-none shrink-0 hidden lg:block">
            <ClockIcon className="w-12 h-auto text-orange-500" />
          </div>
          <div className="grow">
            <h1 className="text-3xl text-darkEucalyptus font-bold">
              Thanks for registering your KYC!
            </h1>
            <p className="text-lg mt-1">
              We're reviewing and validating the provided information
            </p>
          </div>
        </div>
        <div className="flex flex-row items-start justify-center pb-6">
          <Button onClick={onRefresh} className="flex items-center gap-2">
            <RefreshIcon className="w-5" />
            <span>Refresh</span>
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default KYCRequested;
