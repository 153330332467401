const Button = ({ children, buttonType = "button", className, ...props }) => (
  <button
    type={buttonType}
    className={`flex justify-center px-4 py-2 mt-6 font-medium text-white border border-transparent shadow-sm rounded-xl bg-eucalyptus hover:bg-darkEucalyptus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightEucalyptus ${className}`}
    {...props}
  >
    {children}
  </button>
);

export default Button;
