import { Dialog, Transition } from "@headlessui/react";
import {
  CashIcon,
  ChartBarIcon,
  CogIcon,
  MenuAlt1Icon,
  XIcon,
  EyeIcon,
  BeakerIcon,
  BookOpenIcon,
  CodeIcon,
  QrcodeIcon,
} from "@heroicons/react/outline";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useRecoilState, useSetRecoilState } from "recoil";

import { EVENT_CODE } from "../../config/analytics.config";
import { isDeveloperMode, navigation } from "../../store/global.atoms";
import { KybPrompt } from "./components/KybPrompt";
import { locations } from "./helpers/homeLayout.helpers";
import { loggedUserCredentials } from "../auth/store/auth.store";
import { showCallout } from "../../store/callout.atoms";
import { Toggle } from "./components/Toggle";
import { Tooltip } from "../../components/tooltip";
import { trackEvent } from "../../utils/analytics";
import ApiKeysModal from "./components/ApiKeysModal";
import DashboardPanel from "./components/DashboardPanel";
import Intents from "../intents/Intents";
import KybModal from "./components/KybModal";
import KYCSettings from "../settings/KYCSettings";
import Settings from "../settings/Settings";
import turinPayLogo from "../../assets/turinpay_imagotype_final.svg";
import TurinpayMeSettings from "../settings/TurinpayMeSettings";
import useGetSettings from "../settings/hooks/useGetSettings";
import useMe from "../../hooks/useMe";
import useRefreshToken from "../../hooks/useRefreshToken";
import Wallet from "../wallet/Wallet";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HomeLayout = () => {
  // Manage app navigation
  const [whereUserIs, setWhereUserIs] = useRecoilState(navigation);
  const [showApisModal, setShowApisModal] = useState(false);
  const [showKybModal, setShowKybModal] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const setCalloutMessage = useSetRecoilState(showCallout);
  const setUserCredentials = useSetRecoilState(loggedUserCredentials);
  const navigate = useNavigate();

  // Developer mode toggle
  const [developerMode, setDeveloperMode] = useRecoilState(isDeveloperMode);

  // Get query client
  const queryClient = useQueryClient();

  // Get user credentials from cache
  const data = queryClient.getQueryData("loggedUserCredentials");

  // Get user data
  const userData = useMe(data.signinWithSecretByAuthCode);

  // Get credentials from cache
  const userCredentials = queryClient.getQueryData("refreshToken");

  // Get token
  const token = userCredentials?.refreshToken.token;

  // Get user settings
  const userSettings = useGetSettings(token);

  // Manage ME
  useMe(data.signinWithSecretByAuthCode);

  // Refresh token before it expires
  const sessionRefresh = useRefreshToken();

  if (
    sessionRefresh.isSuccess &&
    sessionRefresh.data &&
    sessionRefresh.data.refreshToken.__typename === "GenericError"
  ) {
    // Set callout message inviting to login again
    setCalloutMessage({
      show: true,
      type: 3,
      title: "Current session has expired",
      body: "Please, login again to access Turinpay.",
    });

    // Log out user
    setUserCredentials(null);

    // Redirect to login;
    navigate("/login", { replace: true });
  }

  // Load section according to where user is
  const renderSection = () => {
    if (whereUserIs === locations.home) return <DashboardPanel />;
    if (whereUserIs === locations.wallet) return <Wallet />;
    if (whereUserIs === locations.intents) return <Intents />;
    if (whereUserIs === locations.settings) return <Settings />;
    if (whereUserIs === locations.turinpayMe) return <TurinpayMeSettings />;
    if (whereUserIs === locations.kyc) return <KYCSettings />;
  };

  const isKYBPending =
    (userSettings?.isSuccess ?? false) &&
    (userSettings?.data ?? false) &&
    userSettings.data?.getTPaySettings?.kybPassed === false;

  const changeEnvironment = async () => {
    if (!userSettings.isSuccess && !userSettings.data) return;

    // Check if user has passed kyb
    if (
      userSettings.isSuccess &&
      userSettings.data &&
      !userSettings.data.getTPaySettings.kybPassed
    ) {
      setShowKybModal(true);
      return;
    }

    setDeveloperMode(!developerMode);
    queryClient.invalidateQueries(
      "payouts",
      "getRevenue",
      "getWalletBalance",
      "getUserBalance"
    );

    if (whereUserIs === locations.home) {
      await queryClient.refetchQueries("getRevenue");
      await queryClient.refetchQueries("transactionsHistory");

      return;
    } else if (whereUserIs === locations.wallet) {
      await queryClient.refetchQueries("payouts");
      await queryClient.refetchQueries("getWalletBalance");
      await queryClient.refetchQueries("getUserBalance");

      return;
    } else {
      return;
    }
  };

  const navigationMenu = [
    {
      name: "Dashboard",
      onClick: () => setWhereUserIs(locations.home),
      icon: ChartBarIcon,
      current: whereUserIs === locations.home,
      disabled: false,
    },
    {
      name: "Wallet",
      onClick: () => setWhereUserIs(locations.wallet),
      icon: CashIcon,
      current: whereUserIs === locations.wallet,
      disabled: isKYBPending,
    },
    {
      name: "Intents",
      onClick: () => setWhereUserIs(locations.intents),
      icon: CodeIcon,
      current: whereUserIs === locations.intents,
      disabled: isKYBPending,
    },
    {
      name: "Turinpay.me",
      onClick: () => setWhereUserIs(locations.turinpayMe),
      icon: QrcodeIcon,
      current: whereUserIs === locations.turinpayMe,
      disabled: isKYBPending,
    },
    {
      name: "Settings",
      onClick: () => setWhereUserIs(locations.settings),
      icon: CogIcon,
      current: whereUserIs === locations.settings,
      disabled: false,
    },
  ];

  const secondaryNavigation = [
    {
      name: "API Keys",
      onClick: () => setShowApisModal(!showApisModal),
      icon: EyeIcon,
    },
    {
      name: "API Docs",
      onClick: () =>
        window.open(process.env.REACT_APP_DOCUMENTATION_URL, "_blank").focus(),
      icon: BookOpenIcon,
    },
  ];

  return (
    <>
      <div className="relative min-h-full bg-gray-50 h-screen">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-gray-100">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 pt-2 -mr-12">
                      <button
                        type="button"
                        className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="w-6 h-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex items-center flex-shrink-0 px-4 ">
                    <img
                      className="w-auto h-8"
                      src={turinPayLogo}
                      alt="Turinpay logo"
                    />
                  </div>
                  <nav
                    className="flex-shrink-0 h-full mt-5 overflow-y-auto bg-gray-100 divide-y divide-gray-300"
                    aria-label="Sidebar"
                  >
                    <div className="px-2 space-y-1">
                      {navigationMenu.map((item, index) => (
                        <button
                          key={index}
                          onClick={item.onClick}
                          disabled={item.disabled}
                          className={classNames(
                            item.current
                              ? "bg-gray-300 text-gray-800"
                              : "text-gray-500",
                            item.disabled
                              ? "opacity-50"
                              : "opacity-100 hover:text-gray-800 hover:bg-gray-300",
                            "group flex items-center justify-between px-2 py-2 text-base font-medium rounded-md w-full"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <div className="flex items-center gap-4">
                            <item.icon
                              className="flex-shrink-0 w-6 h-6 text-eucalyptus"
                              aria-hidden="true"
                            />
                            {item.name}
                          </div>
                          {item.disabled && (
                            <Tooltip>
                              Fill the KYC form to unlock your production API
                              key.
                            </Tooltip>
                          )}
                        </button>
                      ))}
                    </div>
                    <div className="pt-6 mt-6">
                      <div className="px-2 space-y-1">
                        {secondaryNavigation.map((item) => (
                          <button
                            key={item.name}
                            onClick={item.onClick}
                            className={classNames(
                              item.current
                                ? "bg-gray-300 text-gray-800"
                                : "text-gray-500 hover:text-gray-800 hover:bg-gray-300",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md w-full"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            <item.icon
                              className="flex-shrink-0 w-6 h-6 mr-4 text-eucalyptus"
                              aria-hidden="true"
                            />
                            {item.name}
                          </button>
                        ))}
                        <div className="flex items-center w-full px-2 py-2 text-base font-medium text-gray-500 rounded-md group">
                          <BeakerIcon
                            className="flex-shrink-0 w-6 h-6 mr-4 text-eucalyptus"
                            aria-hidden="true"
                          />
                          Dev. Mode
                          <span className="flex items-center justify-start ml-12">
                            {" "}
                            <Toggle
                              enabled={developerMode}
                              setEnabled={setDeveloperMode}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto bg-gray-100">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="w-auto h-8"
                src={turinPayLogo}
                alt="Turinpay logo"
              />
            </div>
            <nav
              className="flex flex-col flex-1 mt-16 overflow-y-auto divide-y divide-gray-300"
              aria-label="Sidebar"
            >
              <div className="px-4 space-y-1">
                {navigationMenu.map((item, index) => (
                  <button
                    key={index}
                    onClick={item.onClick}
                    disabled={item.disabled}
                    className={classNames(
                      item.current
                        ? "bg-gray-300 text-gray-800"
                        : "text-gray-500",
                      item.disabled
                        ? "opacity-50"
                        : "opacity-100 hover:text-gray-800 hover:bg-gray-300",
                      "group flex items-center justify-between px-2 py-2 text-base font-medium rounded-md w-full"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    <div className="flex items-center gap-4">
                      <item.icon
                        className="flex-shrink-0 w-6 h-6 text-eucalyptus"
                        aria-hidden="true"
                      />
                      {item.name}
                    </div>
                    {item.disabled && (
                      <Tooltip>
                        Fill the KYC form to unlock your production API key.
                      </Tooltip>
                    )}
                  </button>
                ))}
              </div>
              <div className="pt-6 mt-6">
                <div className="px-2 space-y-1">
                  {secondaryNavigation.map((item) => (
                    <button
                      key={item.name}
                      onClick={item.onClick}
                      className={classNames(
                        item.current
                          ? "bg-gray-300 text-gray-800"
                          : "text-gray-500 hover:text-gray-800 hover:bg-gray-300",
                        "group flex items-center px-2 py-2 text-base font-medium rounded-md w-full"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <item.icon
                        className="flex-shrink-0 w-6 h-6 mr-4 text-eucalyptus"
                        aria-hidden="true"
                      />
                      {item.name}
                    </button>
                  ))}
                  <div className="flex items-center w-full px-2 py-2 text-base font-medium text-gray-500 rounded-md group">
                    <BeakerIcon
                      className="flex-shrink-0 w-6 h-6 mr-4 text-eucalyptus"
                      aria-hidden="true"
                    />
                    Dev. Mode
                    <span className="flex items-center justify-start ml-12">
                      {" "}
                      <Toggle
                        enabled={developerMode}
                        setEnabled={changeEnvironment}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="flex flex-col flex-1 lg:pl-64">
          {userSettings.isSuccess &&
            userSettings.data &&
            !userSettings.data.getTPaySettings.kybPassed &&
            whereUserIs !== locations.kyc && (
              <KybPrompt
                onComplete={() => {
                  trackEvent(EVENT_CODE.KYC_BANNER);
                  setWhereUserIs(locations.kyc);
                }}
              />
            )}
          <div className="relative z-10 flex flex-shrink-0 h-16 border-b border-gray-200 bg-gray-50 lg:h-auto lg:mb-6 lg:py-6">
            <h1 className="hidden w-full px-10 text-2xl font-bold text-eucalyptus lg:flex lg:justify-start lg:items-center">
              {whereUserIs === locations.home
                ? "Dashboard"
                : whereUserIs === locations.wallet
                ? "Wallet"
                : whereUserIs === locations.intents
                ? "Intents"
                : whereUserIs === locations.settings
                ? "Settings"
                : whereUserIs === locations.turinpayMe
                ? "Turinpay.me"
                : "KYC"}
            </h1>
            <button
              type="button"
              className="px-4 text-gray-400 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-eucalyptus lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt1Icon className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1 pb-8">{renderSection()}</main>
        </div>
      </div>
      <ApiKeysModal
        userData={userData}
        showApisModal={showApisModal}
        setShowApisModal={setShowApisModal}
      />
      <KybModal
        showKybModal={showKybModal}
        setShowKybModal={setShowKybModal}
        onComplete={() => {
          setWhereUserIs(locations.kyc);
        }}
      />
    </>
  );
};

export default HomeLayout;
